import store from '@/store'
import FieldCalculation from '@/store/base/entities/fieldCalculation'
import typedStore from '@/store/typedStore'
import { getModule, Module } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'primaryFieldCalculation', namespaced: true })
export default class PrimaryFieldCalculation extends FieldCalculation {
  get nodes() {
    return typedStore.primary.entities.nodes
  }
}

export const PrimaryFieldCalculationModule = getModule(PrimaryFieldCalculation)
