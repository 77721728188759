import typedStore from '@/store/typedStore'
import { NodeTypes, Tab } from '@/types'

export function getTabLabel(tab: Tab) {
  let label = tab.name
  if (tab.nodeType === NodeTypes.APP) {
    const secondaryAppReferenceNode =
      typedStore.primary.entities.nodes.secondaryAppReferenceNodes.find(
        (nodes) => nodes.path === tab.path,
      )
    const secondaryApp = typedStore.primary.app.allOrganisationSecondaryApps.find(
      (app) => app.id === secondaryAppReferenceNode?.config.secondaryAppId,
    )
    if (secondaryApp) {
      label = typedStore.primary.app.secondaryAppLabel(secondaryApp)
    } else if (secondaryAppReferenceNode && secondaryAppReferenceNode?.config.secondaryAppId) {
      label = 'Access denied'
    } else {
      label = tab.name
    }
  }
  return label
}
