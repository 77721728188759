import { MentionExtension } from '@orbica/vue-modules'

export default MentionExtension.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-mention-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-mention-id': attributes.id,
          }
        },
      },
      link: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-mention-link') === 'true',
        renderHTML: (attributes) => {
          if (!attributes.link) {
            return {}
          }

          return {
            'data-mention-link': attributes.link,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      { tag: 'span[data-mention-id]' },
      { tag: 'span[data-mention-id][data-mention-link="true"]' },
    ]
  },
})
