import { postImage } from '@/api'
import typedStore from '@/store/typedStore'
import { DragDropImageExtension } from '@orbica/vue-modules'

export const DATA_PK_ID = 'data-pk-id'

export default DragDropImageExtension.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      inline: true,
      getImageAttrs: async (image: File) => {
        typedStore.primary.entities.images.setImageUploading(true)
        const { img, url } = await postImage(image)
        typedStore.primary.entities.images.setNewImage({
          key: img,
          value: url,
        })
        typedStore.primary.entities.images.setImageUploading(false)
        return {
          src: url,
          [DATA_PK_ID]: img,
        }
      },
    }
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      [DATA_PK_ID]: {},
    }
  },

  parseHTML() {
    return [
      ...(this.parent?.() || []),
      {
        tag: `img[${DATA_PK_ID}]`,
      },
    ]
  },
})
