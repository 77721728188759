import { NAME_ID_SEPARATOR, NODE_CONFIG_KEY, TAB_COLORS } from '@/constants'
import { isRootOrSubroot } from '@/helpers'
import store from '@/store'
import App from '@/store/base/app'
import Nodes from '@/store/base/entities/nodes'
import { calculateNextIdFromNames } from '@/store/helpers'
import { buildTab, calculateRootTabPaths, filterTabNodes } from '@/store/tabsHelpers'
import typedStore from '@/store/typedStore'
import { NodeData, NodeTypes, SecondaryAppReferenceNode, Tab } from '@/types'
import { v4 as uuidv4 } from 'uuid'
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'

const _uniqBy = require('lodash.uniqby')
const cloneDeep = require('lodash.clonedeep')

export interface PathItem {
  path: string
  text: string
  name?: string
}

export interface SecondaryAppTabData {
  secondaryAppPath: string
  secondaryAppId: number
  isSecondaryInitialTab: boolean
  barColour: string | null
}

@Module({ dynamic: true, store, name: 'primaryTabs', namespaced: true })
export default class PrimaryTabs extends VuexModule {
  get app(): App {
    return typedStore.primary.app
  }

  get nodes(): Nodes {
    return typedStore.primary.entities.nodes
  }

  get primaryRootTabPaths(): PathItem[] {
    if (this.app.isVisualisationApp) {
      return calculateRootTabPaths(this.app.config)
    }

    return [] as PathItem[]
  }

  get primaryRootNodes(): NodeData[] {
    return this.primaryRootTabPaths.map((rootPath) => {
      const rootNode = this.nodes.allNodes.find((node) => node.path === rootPath.path)
      rootNode!.config!.nodeType = NodeTypes.ROOT
      return rootNode!
    })
  }

  get primaryTabNodes(): NodeData[] {
    // Filter out any nodes that are not direct children of the root, are root nodes or are default story level nodes
    const nonRootNodes = filterTabNodes(this.nodes.allNodes)

    const tabNodes = [...this.primaryRootNodes, ...nonRootNodes]
      .filter((x) => !this.nodes.removedNodes.includes(x.path))
      .sort((a, b) => (a.config?.order ?? 0) - (b.config?.order ?? 0))

    return _uniqBy(tabNodes, 'path') as NodeData[]
  }

  get allPrimaryTabs(): Tab[] {
    return typedStore.primary.entities.tabs.primaryTabNodes.map((n) =>
      buildTab(n, typedStore.primary.app.config),
    )
  }

  get secondaryRootTabPaths(): (appId: number) => PathItem[] {
    return (appId: number) => {
      const config = typedStore.primary.app.secondaryAppConfig(appId)
      return calculateRootTabPaths(config)
    }
  }

  get secondaryRootNodes(): (appId: number) => NodeData[] {
    return (appId: number) => {
      const secondaryAppNodes = typedStore.primary.entities.nodes.secondaryAppTabNodes(appId)
      if (secondaryAppNodes) {
        return this.secondaryRootTabPaths(appId).reduce((filtered: NodeData[], rootPath) => {
          const rootNode = secondaryAppNodes.find((node) => node.path === rootPath.path)
          if (rootNode) {
            rootNode!.config!.nodeType = NodeTypes.ROOT
            filtered.push(rootNode!)
          }
          return filtered
        }, [])
      }

      return []
    }
  }

  get secondaryTabNodes(): (appId: number) => NodeData[] {
    return (appId: number) => {
      let nonRootNodes: NodeData[] = []
      const secondaryAppNodes = typedStore.primary.entities.nodes.secondaryAppTabNodes(appId)
      if (secondaryAppNodes) {
        // Filter out any nodes that are not direct children of the root, are root nodes or are default story level nodes
        nonRootNodes = filterTabNodes(secondaryAppNodes)
      }

      const tabNodes = [...this.secondaryRootNodes(appId), ...nonRootNodes].sort(
        (a, b) => (a.config?.order ?? 0) - (b.config?.order ?? 0),
      )

      return _uniqBy(tabNodes, 'path') as NodeData[]
    }
  }

  get secondaryTabs(): (secondaryAppReferenceNode: SecondaryAppReferenceNode) => Tab[] {
    return (secondaryAppReferenceNode: SecondaryAppReferenceNode) => {
      const isVisible = secondaryAppReferenceNode.config?.visible ?? true
      const secondaryAppId = secondaryAppReferenceNode.config?.secondaryAppId
      if (isVisible && secondaryAppId) {
        const secondaryAppConfig = typedStore.primary.app.secondaryAppConfig(secondaryAppId)
        const secondaryTabNodes = cloneDeep(this.secondaryTabNodes(secondaryAppId)) as NodeData[]
        if (secondaryAppConfig) {
          const secondaryTabs: Tab[] = []
          let initialTab = true

          secondaryTabNodes.forEach((secondaryTabNode) => {
            const secondaryAppTabData: SecondaryAppTabData = {
              secondaryAppPath: secondaryAppReferenceNode.path,
              secondaryAppId,
              isSecondaryInitialTab: initialTab,
              barColour: secondaryTabNode.config!.colour,
            }

            secondaryTabNode.config!.colour = secondaryAppReferenceNode.config!.colour
            const tab = buildTab(secondaryTabNode, secondaryAppConfig, secondaryAppTabData)

            // Only include visible tabs for secondary apps
            if (tab.visible) {
              secondaryTabs.push(tab)
              initialTab = false
            }
          })

          return secondaryTabs
        }
      }

      return []
    }
  }

  get allTabs() {
    const slaTabFilter = (app: App, { path }: { path: string }) =>
      !app.appLoaded ||
      app.levels.maxLevel > 1 ||
      isRootOrSubroot(path) ||
      path.startsWith('custom-')
    return this.primaryTabNodes.flatMap((primaryTabNode) => {
      if (primaryTabNode.config?.nodeType === NodeTypes.APP) {
        return this.secondaryTabs(primaryTabNode as SecondaryAppReferenceNode).filter((t) =>
          slaTabFilter(typedStore.secondary.app, t),
        )
      } else {
        return slaTabFilter(typedStore.primary.app, primaryTabNode)
          ? [buildTab(primaryTabNode, this.app.config)]
          : []
      }
    })
  }

  get visibleTabs() {
    return this.allTabs
      .filter((tab) => tab.visible)
      .filter(
        (tab) =>
          !tab.secondaryAppPath ||
          tab.isSecondaryInitialTab ||
          tab.secondaryAppPath === typedStore.public.display.activeTab?.secondaryAppPath,
      )
  }

  get builderTabs() {
    return this.primaryTabNodes.map((node) => buildTab(node, this.app.config))
  }

  get consolidatedAppStoryPickerTabs() {
    const tabs: Tab[] = []
    this.primaryTabNodes.forEach((primaryTabNode) => {
      if (primaryTabNode.config?.nodeType === NodeTypes.APP) {
        const secondaryAppReferenceNode = primaryTabNode as SecondaryAppReferenceNode
        if (!secondaryAppReferenceNode.config.secondaryAppId) {
          return
        }
      }

      const tab = buildTab(primaryTabNode, this.app.config)
      if (tab.visible) {
        tabs.push(tab)
      }
    })

    return tabs
  }

  get secondaryAppTabs(): (secondaryAppPath: string) => Tab[] {
    return (secondaryAppPath: string) => {
      return this.allTabs
        .filter((tab) => tab.visible)
        .filter((tab) => tab.secondaryAppPath === secondaryAppPath)
    }
  }

  get searchTabsByPath() {
    return (path: string, secondaryAppPath: string | undefined) => {
      return (
        this.allTabs.find((x) => x.path === path && x.secondaryAppPath === secondaryAppPath) ??
        false
      )
    }
  }

  @Action({ rawError: true })
  async setTabVisibility(payload: { tab: Tab; value: boolean }) {
    await typedStore.primary.entities.nodes.updateNodeProp({
      path: payload.tab.path,
      configPath: [NODE_CONFIG_KEY, 'visible'],
      value: payload.value,
    })

    if (payload.tab.nodeType === NodeTypes.DATA) {
      await typedStore.visualisationData.getTreeData()
    }
  }

  @Action({ rawError: true })
  addCustomTab() {
    const customTabNames = this.nodes.customTabNodes.map((node) => node.config?.name)
    const nextId = calculateNextIdFromNames(customTabNames)

    const newTab: NodeData = {
      path: `custom-${uuidv4().replace(/-/g, '')}`,
      parent: null,
      config: {
        colour: TAB_COLORS[this.allTabs.length % TAB_COLORS.length],
        label: null,
        icon: null,
        name: `Custom${NAME_ID_SEPARATOR}${nextId}`,
        nodeType: NodeTypes.CUSTOM,
        order: this.allTabs.length + 1,
        bubblePosition: [-1, -1],
        headerPosition: [0, 0],
      },
    }
    typedStore.primary.entities.nodes.addToCreate(newTab)

    // If this is the only visible tab then activate it
    if (this.visibleTabs.length === 1) {
      typedStore.public.display.activateInitialTab({ dataTabsOnly: false, primaryAppOnly: false })
    }
  }

  @Action({ rawError: true })
  removeCustomTab(path: string) {
    if (this.nodes.customTabNodes.find((x) => x.path === path)) {
      typedStore.primary.entities.nodes.addToDelete(path)
    } else {
      throw new Error(`Cannot find node with nodeType=[${NodeTypes.CUSTOM}] and path=[${path}]`)
    }
  }
}

export const PrimaryTabsModule = getModule(PrimaryTabs)
