<template>
  <div>
    <ButtonTemplate
      :on-click="
        () => {
          showModal = true
        }
      "
      icon="link-45deg"
      title="Insert internal link"
    />

    <b-modal v-model="showModal" hide-footer hide-header size="lg" @ok="setLink" @show="getLink">
      <b-form @submit="submit">
        <b-form-group :invalid-input="state">
          <b-input-group prepend="Link">
            <ConsolidatedAppStoryPicker v-if="isConsolidatedApp" :selected-story-path.sync="link" />
            <StoryPicker v-else :selected-story-path.sync="link" data-test="story-picker" />
            <b-input v-model="title" debounce="500" placeholder="Text" />
            <b-input-group-append>
              <b-button type="submit"> Insert </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import ConsolidatedAppStoryPicker from '@/components/builder/CustomVisualisationSettings/ConsolidatedAppStoryPicker.vue'
import StoryPicker from '@/components/builder/StoryEditor/StoryPicker.vue'

import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'
import { Path } from '@/types'

const components = {
  ButtonTemplate,
  ConsolidatedAppStoryPicker,
  StoryPicker,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

// eslint-disable-next-line no-use-before-define
@Component<InsertInternalLinkButton>({
  components,
  watch: {
    link(path?: string) {
      if (path) {
        this.title = new Path({ path }).name
      }
    },
  },
})
export default class InsertInternalLinkButton extends Vue.extend({ props }) {
  link = ''
  title = ''
  showModal = false

  get isConsolidatedApp() {
    return this.$typedStore.primary.app.isConsolidatedApp
  }

  onClick() {
    this.showModal = true
  }

  setLink() {
    if (this.editor) {
      this.editor.chain().focus().setInternalLink({ link: this.link, title: this.title }).run()
    }
  }

  getLink() {
    const link = this.editor?.getAttributes('internal-link')
    this.link = link?.href || null
    this.title = link?.title || null
  }

  get state() {
    return null
  }

  submit(e: Event) {
    e.preventDefault()
    if (this.link) {
      this.setLink()
      this.showModal = false
    }
  }
}
</script>
