import { loggerPrefixFormat } from '@/plugins/logging/loggerOptions'
import { useLoggingStore } from '@/store/common/logging'
import _isObject from 'lodash/isObject'
import { CallerInfo, LogEvent, LoggerHook, LogLevel } from 'vue-logger-plugin'

function createLogMessage(level: LogLevel, caller: CallerInfo | undefined, ...args: any[]) {
  const prefix = loggerPrefixFormat({ level, caller })
  const message = args.map((arg) => (_isObject(arg) ? JSON.stringify(arg) : arg)).join(' ')
  return `${prefix} ${message}`
}

export const StoreLoggerHook: LoggerHook = {
  run(event: LogEvent) {
    const { level, caller, argumentArray: args } = event
    const msg = createLogMessage(level, caller, args)
    useLoggingStore().addLogEvent(level, msg)
  },
}
