<template>
  <node-view-wrapper>
    <BubblesLegends
      style="
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
      "
    />
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import BubblesLegends from '@/components/public/BubblesLegends.vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

const components = {
  BubblesLegends,
  NodeViewWrapper,
}

@Component({ components })
export default class BubblesLegendsNodeView extends Vue.extend({ props: nodeViewProps }) {}
</script>
