// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
  prefix: 'custom',
  iconName: 'map',
  icon: [
    1024,
    1024,
    [],
    'f0000',
    'm551.98 958.54 164.99-138.5-496.18-90.882v229.38zm-378.58-238.07-103.37-18.936-61.025 213.87c-2.9103 10.208-0.86936 21.197 5.5286 29.674 6.3945 8.4762 16.396 13.458 27.015 13.458h131.85zm841.59 194.94-65.303-228.87-324.01 272.01h356.78c10.619 0 20.62-4.9818 27.015-13.458 6.3945-8.4796 8.4355-19.469 5.5251-29.677zm-78.989-276.83-25.561-89.574c-4.1498-14.538-17.431-24.559-32.543-24.559h-182.43c-6.5949 9.8041-13.553 19.645-20.912 29.541-51.132 68.7-103.11 115.17-108.88 120.25-14.84 13.068-33.901 20.263-53.683 20.263-19.768 0-38.829-7.1892-53.69-20.27-5.7561-5.0735-57.744-51.55-108.87-120.24-7.359-9.8957-14.317-19.74-20.912-29.541h-182.43c-15.112 0-28.393 10.021-32.543 24.559l-30.465 106.75 680.98 124.73zm-446.37 0.0985c6.3945 5.6236 14.378 8.4422 22.369 8.4422s15.978-2.8186 22.369-8.4422c8.0993-7.1348 198.29-176.66 198.29-352.47 0-121.72-98.984-220.74-220.66-220.74-121.68 0-220.66 99.025-220.66 220.74-7e-3 175.81 190.19 345.34 198.29 352.47zm22.369-410.15c31.589 0 57.204 25.816 57.204 57.676 0 31.857-25.615 57.676-57.204 57.676-31.599 0-57.211-25.823-57.211-57.676 0-31.861 25.612-57.676 57.211-57.676z',
  ],
}
