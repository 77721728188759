import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { convertYoutubeUrl } from '../IframeExtension/helpers'
import IframeDialogNodeView from './IframeDialogNodeView.vue'

export interface IframeDialogOptions {
  src: string
  text: string
}

export const iFrameDialogTag = 'iframe-dialog'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframeDialog: {
      /**
       * Set an iframe node
       */
      setIframeDialog: (options: IframeDialogOptions) => ReturnType
    }
  }
}

export default Node.create({
  name: 'iframeDialog',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      text: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: iFrameDialogTag,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [iFrameDialogTag, HTMLAttributes]
  },

  addCommands() {
    return {
      setIframeDialog:
        ({ src, text }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create({ src: convertYoutubeUrl(src), text })
          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }
          return true
        },
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(IframeDialogNodeView)
  },
})
