import typedStore from '@/store/typedStore'
import { Node } from '@tiptap/core'
import { EditorState, Plugin, PluginKey, Transaction } from '@tiptap/pm/state'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import UserDetailsQuestionTipTapView from './UserDetailsQuestionTipTapView.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    UserDetailsQuestion: {
      setUserDetailsQuestion: (attrs: any) => ReturnType
    }
  }
}

const userDetailsQuestionsRemovedPluginKey = new PluginKey('UserDetailsQuestionsRemovedPlugin')

export default Node.create({
  name: 'UserDetailsQuestionNode',
  group: 'block',
  atom: true,
  allowGapCursor: false,

  addAttributes() {
    return {
      questionId: {
        parseHTML: (element) => element.getAttribute('question-id'),
        renderHTML: (attributes) => ({ 'question-id': attributes.questionId }),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['UserDetailsQuestion', HTMLAttributes]
  },

  parseHTML() {
    return [{ tag: 'UserDetailsQuestion' }, { tag: 'userdetailsquestion' }]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: userDetailsQuestionsRemovedPluginKey,
        appendTransaction: (
          _: readonly Transaction[],
          oldState: EditorState,
          newState: EditorState,
        ) => {
          // Detect if a user details questions are removed from the editor
          // and remove them from the store
          const oldStateQuestionIds = getQuestionIdsFromState(oldState, this.name)
          const newStateQuestionIds = getQuestionIdsFromState(newState, this.name)

          const removedQuestionIds = oldStateQuestionIds.filter(
            (questionId) => !newStateQuestionIds.includes(questionId),
          )

          removedQuestionIds.forEach((questionId) =>
            typedStore.primary.app.removeUserDetailsQuestion({ id: questionId }),
          )

          return null
        },
      }),
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(UserDetailsQuestionTipTapView)
  },

  addCommands() {
    return {
      setUserDetailsQuestion:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs,
            })
            .command(({ tr, commands }) => {
              const { doc, selection } = tr
              const position = doc.resolve(selection.to).end()
              return commands.setTextSelection(position)
            })
            .run()
        },
    }
  },
})

function getQuestionIdsFromState(state: EditorState, nodeName: string): string[] {
  const questionIds: string[] = []
  state.doc.descendants((node) => {
    if (node.type.name === nodeName) {
      questionIds.push(node.attrs.questionId)
    }
  })
  return questionIds
}
