import {
  CustomParams,
  EventNames,
  EventParams,
  GtmOptions,
  OrbVizAnalytics,
} from '@/plugins/analytics'
import router from '@/router'
import VueGtm, { useGtm } from '@gtm-support/vue2-gtm'
import _Vue, { PluginObject } from 'vue'
import VueGtag from 'vue-gtag'

export const OrbVizAnalyticsPlugin: PluginObject<GtmOptions> = {
  install(Vue: typeof _Vue, options?: GtmOptions) {
    if (options) {
      Vue.use(VueGtm, {
        id: options.id,
        queryParams: {
          gtm_auth: options.environment.auth,
          gtm_preview: options.environment.preview,
          gtm_cookies_win: 'x',
        },
        vueRouter: router,
      })
    }

    // VueGtag is used to record analytics for per-customer
    // GA4 Properties so we can't configure it until the application
    // is loaded and we have the customer's GA4 Measurement ID
    Vue.use(
      VueGtag,
      {
        bootstrap: false,
      },
      router,
    )

    Vue.prototype.$analytics = {
      gtm: useGtm(),
      event(action: EventNames | string, eventParams: EventParams | CustomParams) {
        // Send custom events to Google Tag Manager (GTM)
        if (this.gtm) {
          this.gtm.trackEvent({
            event: action,
            ...eventParams,
          })
        }
      },
    } as OrbVizAnalytics
  },
}
