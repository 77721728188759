// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
  prefix: 'custom',
  iconName: 'bubbles',
  icon: [
    1024,
    1024,
    [],
    'f0000',
    'm590.71 12.293a80.916 80.916 0 0 0-80.918 80.915 80.916 80.916 0 0 0 80.918 80.917 80.916 80.916 0 0 0 80.917-80.917 80.916 80.916 0 0 0-80.917-80.915zm-409.58 93.274a82.388 82.388 0 0 0-82.388 82.388 82.388 82.388 0 0 0 82.388 82.388 82.388 82.388 0 0 0 82.388-82.388 82.388 82.388 0 0 0-82.388-82.388zm240.4 27.365a20.597 20.597 0 0 0-20.597 20.597 20.597 20.597 0 0 0 20.597 20.597 20.597 20.597 0 0 0 20.597-20.597 20.597 20.597 0 0 0-20.597-20.597zm328.08 52.963a75.032 75.032 0 0 0-75.032 75.032 75.032 75.032 0 0 0 75.032 75.032 75.032 75.032 0 0 0 75.032-75.032 75.032 75.032 0 0 0-75.032-75.032zm-351.62 50.022a270.71 270.71 0 0 0-270.71 270.71 270.71 270.71 0 0 0 270.71 270.71 270.71 270.71 0 0 0 270.71-270.71 270.71 270.71 0 0 0-270.71-270.71zm378.1 170.66a51.493 51.493 0 0 0-51.493 51.493 51.493 51.493 0 0 0 51.493 51.493 51.493 51.493 0 0 0 51.493-51.493 51.493 51.493 0 0 0-51.493-51.493zm144.18 41.194a83.86 83.86 0 0 0-83.859 83.859 83.86 83.86 0 0 0 83.859 83.861 83.86 83.86 0 0 0 83.859-83.861 83.86 83.86 0 0 0-83.859-83.859zm-874.64 4.4131a25.746 25.746 0 0 0-25.746 25.746 25.746 25.746 0 0 0 25.746 25.746 25.746 25.746 0 0 0 25.746-25.746 25.746 25.746 0 0 0-25.746-25.746zm705.45 172.13a129.46 129.46 0 0 0-129.46 129.46 129.46 129.46 0 0 0 129.46 129.46 129.46 129.46 0 0 0 129.46-129.46 129.46 129.46 0 0 0-129.46-129.46zm-627.48 101.51a52.228 52.228 0 0 0-52.227 52.227 52.228 52.228 0 0 0 52.227 52.229 52.228 52.228 0 0 0 52.229-52.229 52.228 52.228 0 0 0-52.229-52.227zm164.05 107.4a54.435 54.435 0 0 0-54.435 54.435 54.435 54.435 0 0 0 54.435 54.435 54.435 54.435 0 0 0 54.435-54.435 54.435 54.435 0 0 0-54.435-54.435zm222.15 2.9427a52.964 52.964 0 0 0-52.963 52.963 52.964 52.964 0 0 0 52.963 52.965 52.964 52.964 0 0 0 52.965-52.965 52.964 52.964 0 0 0-52.965-52.963zm-111.78 122.57a26.482 26.482 0 0 0-26.483 26.483 26.482 26.482 0 0 0 26.483 26.481 26.482 26.482 0 0 0 26.481-26.481 26.482 26.482 0 0 0-26.481-26.483z',
  ],
}
