import { CSVDataModule } from '@/store/admin/csvData'
import { NavigationModule } from '@/store/admin/navigation'
import { UserPermissionModule } from '@/store/admin/userPermission'
import { ActiveVisualisationModule } from '@/store/app/activeVisualisation'
import { ConsolidatedAppModule } from '@/store/app/consolidatedApp'
import { VisualisationAppModule } from '@/store/app/visualisationApp'
import { VisualisationDataModule } from '@/store/app/visualisationData'
import { VersionsModule } from '@/store/common/versions'
import { CurrentRouteModule } from '@/store/currentRoute'
import { PrimaryAppModule } from '@/store/primary'
import {
  PrimaryFieldCalculationModule,
  PrimaryImagesModule,
  PrimaryNodesModule,
  PrimaryTabsModule,
} from '@/store/primary/entities'
import { DisplayModule } from '@/store/public/display'
import { GeometriesModule } from '@/store/public/geometries'
import { SearchModule } from '@/store/public/search'
import { SizeModule } from '@/store/public/size'
import { SecondaryAppModule } from '@/store/secondary'
import {
  SecondaryFieldCalculationModule,
  SecondaryImagesModule,
  SecondaryNodesModule,
} from '@/store/secondary/entities'

export default {
  admin: {
    navigation: NavigationModule,
    userPermission: UserPermissionModule,
    versions: VersionsModule,
    csvData: CSVDataModule,
  },
  primary: {
    app: PrimaryAppModule,
    entities: {
      images: PrimaryImagesModule,
      nodes: PrimaryNodesModule,
      tabs: PrimaryTabsModule,
      fieldCalculation: PrimaryFieldCalculationModule,
    },
  },
  secondary: {
    app: SecondaryAppModule,
    entities: {
      images: SecondaryImagesModule,
      nodes: SecondaryNodesModule,
      fieldCalculation: SecondaryFieldCalculationModule,
    },
  },
  activeVisualisation: ActiveVisualisationModule,
  visualisationApp: VisualisationAppModule,
  consolidatedApp: ConsolidatedAppModule,
  visualisationData: VisualisationDataModule,
  public: {
    display: DisplayModule,
    geometries: GeometriesModule,
    search: SearchModule,
    size: SizeModule,
  },
  currentRoute: CurrentRouteModule,
}
