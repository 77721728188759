<template>
  <b-list-group>
    <b-list-group-item
      v-for="({ column, name }, index) in items"
      :key="index"
      class="d-flex align-items-center p-2"
      :class="{ 'justify-content-between': showLink(column) }"
      :active="index === selectedIndex"
      button
      @click="selectItem(index)"
    >
      <small>
        {{ name || column }}
      </small>
      <b-button v-if="showLink(column)" size="sm" @click="linkInstead($event, index)">
        <small><b-icon icon="link" /></small>
      </b-button>
    </b-list-group-item>
  </b-list-group>
</template>

<script lang="ts">
import { PropType } from 'vue'
import Component from 'vue-class-component'

import { DefaultMentionList } from '@orbica/vue-modules'
import { FieldTypes, ListItem } from '@/types'

const props = {
  items: {
    type: Array as PropType<ListItem[]>,
    required: true,
  },
}

@Component
export default class MentionList extends DefaultMentionList.extend({ props }) {
  selectedIndex!: number

  get field() {
    return this.$typedStore.visualisationApp.field
  }

  get uniqueStrings() {
    return this.$typedStore.visualisationApp.uniqueStrings
  }

  showLink(column: string) {
    return this.field(column)?.type === FieldTypes.STRING && this.uniqueStrings(column).length === 1
  }

  selectItem(index: number) {
    const item = this.items[index]
    if (item) {
      this.command({ id: item.column })
    }
  }

  linkInstead(event: Event, index: number) {
    event.stopPropagation()
    const item = this.items[index]
    if (item) {
      this.command({ id: item.column, link: true })
    }
  }
}
</script>
