import { IBuilderState } from '@/store/admin/builder'
import { ICSVDataState } from '@/store/admin/csvData'
import { INavigationState } from '@/store/admin/navigation'
import { IUploadState } from '@/store/admin/upload'
import { IVersionsState } from '@/store/common/versions'
import { IPrimaryAppModuleState } from '@/store/primary/app'
import { IPrimaryImagesState, IPrimaryNodesState } from '@/store/primary/entities'
import { IDisplayState } from '@/store/public/display'
import { IGeometriesState } from '@/store/public/geometries'
import { ISearchState } from '@/store/public/search'
import { ISizeState } from '@/store/public/size'
import { ISecondaryAppModuleState } from '@/store/secondary/app'
import { ISecondaryImagesState, ISecondaryNodesState } from '@/store/secondary/entities'
import Vue from 'vue'
import Vuex from 'vuex'
import { IFilterState } from './app/filter'

export interface IRootState {
  navigation: INavigationState
  versions: IVersionsState
  upload: IUploadState
  filter: IFilterState
  builder: IBuilderState
  primaryApp: IPrimaryAppModuleState
  primaryNodes: IPrimaryNodesState
  primaryImages: IPrimaryImagesState
  secondaryApp: ISecondaryAppModuleState
  secondaryNodes: ISecondaryNodesState
  secondaryImages: ISecondaryImagesState
  display: IDisplayState
  geometries: IGeometriesState
  search: ISearchState
  size: ISizeState
  csvData: ICSVDataState
}

Vue.use(Vuex)

export default new Vuex.Store<IRootState>({
  strict: process.env.NODE_ENV !== 'production',
})
