import { FIELD_CALC_FUNCTIONS } from '@/constants'
import { compile } from '@/store/app/math'
import Nodes from '@/store/base/entities/nodes'
import typedStore from '@/store/typedStore'
import {
  FieldCalculationDefinition,
  FieldCalculationFunction,
  FieldCalculationFunctionTypes,
  FieldCalculationTypes,
  LeafNode,
  VisNode,
} from '@/types'
import { VuexModule } from 'vuex-module-decorators'

export default abstract class FieldCalculation extends VuexModule {
  abstract get nodes(): Nodes

  get fieldCalculation() {
    return (column: string, leafData: (LeafNode | VisNode)[]) => {
      // Perform the field calculation evaluation
      const field = this.calculationFieldsCompiled.find((field) => field.column === column)
      const scope = this.combinedScope(leafData)
      return field && field.compiled.evaluate(scope)
    }
  }

  get combinedScope() {
    return (
      leafData: (LeafNode | VisNode)[] = typedStore.visualisationData.filteredLeafDataByCurrentPath,
    ) => {
      // returns scope object suitable for use with Math-js
      return {
        ...this.leafDataScope(leafData),
        ...this.sumOfAllScope,
      }
    }
  }

  get leafDataScope() {
    return (
      leafData: (LeafNode | VisNode)[] = typedStore.visualisationData.filteredLeafDataByCurrentPath,
    ) => {
      // keys are columns, values are array of numbers for that column (for given leaf data)
      const entries = typedStore.visualisationApp.numericMonetaryFields.map(({ column }) => [
        removeOperators(column),
        leafData.map((leaf) => leaf.data![column]),
      ])
      return Object.fromEntries(entries)
    }
  }

  get sumOfAllScope() {
    // keys are columns prefixed with sum_of_all, values are array of number for that column for root leaf data
    const entries = typedStore.visualisationApp.numericMonetaryFields.map(({ column }) => [
      `${FieldCalculationFunctionTypes.SUM_OF_ALL}${removeOperators(column)}`,
      typedStore.visualisationData.filteredLeafDataForRoot.map((leaf) => leaf.data![column]),
    ])
    return Object.fromEntries(entries)
  }

  get stringifyDefinitions() {
    return (definitions: FieldCalculationDefinition[]) => {
      // Turn all the definitions into a string suitable for math-js
      return definitions
        .map((def) => {
          switch (def.type) {
            case FieldCalculationTypes.FUNCTION:
              return `${def.function}(${removeOperators(def.column)})`
            case FieldCalculationTypes.NUMBER:
              return def.number
            case FieldCalculationTypes.OPERATOR:
              return def.operator
            default:
              return ''
          }
        })
        .join('')
    }
  }

  get stringifyDefinitionLabel() {
    return (definition: FieldCalculationDefinition) => {
      // Make a pretty looking definition suitable for display
      const { type } = definition

      if (
        type === FieldCalculationTypes.FUNCTION &&
        definition.function === FieldCalculationFunctionTypes.COUNT
      ) {
        // Count does not display the column, because the count is the same for all columns
        return getFunctionText(FieldCalculationFunctionTypes.COUNT)
      }

      if (type === FieldCalculationTypes.FUNCTION) {
        const name = this.nodes.name(definition.column)
        const functionText = getFunctionText(definition.function)
        return `${functionText}(${name || definition.column})`
      }

      if (type === FieldCalculationTypes.OPERATOR) return definition.operator

      if (type === FieldCalculationTypes.NUMBER) return definition.number

      return 'error'
    }
  }

  get calculationFieldsCompiled() {
    // Run the compliation step for all calculated fields once and cache it in Vuex
    return typedStore.visualisationApp.calculationFields.map((field) => {
      return {
        ...field,
        compiled: compile(this.stringifyDefinitions(field.definitions)),
      }
    })
  }
}

// fieldCalculation.js
function getFunctionText(functionValue: FieldCalculationFunction['function']) {
  const functionObj = FIELD_CALC_FUNCTIONS.find(({ value }) => value === functionValue)
  return functionObj?.text || functionValue
}

function removeOperators(column: string) {
  // Add _ at the start to avoid issues with columns that are numbers
  // Replace any non-word characters with _ to avoid issues with operators
  return '_' + column.replace(/[^\w]/g, '_')
}
