import logger from '@/plugins/logging/logger'
import { growthBookService } from '@/services'
import { Feature, FeatureKeys } from '@/types'
import { defineStore } from 'pinia'

const disabledByDefault: Feature[] = [
  FeatureKeys.USE_N_LEVELS,
  FeatureKeys.FEEDBACK,
  FeatureKeys.NESTED_BUBBLES,
  FeatureKeys.USE_BUBBLES_V2,
  FeatureKeys.XY_BUBBLES,
]

function defaultValue(feature: Feature): boolean {
  return !disabledByDefault.includes(feature)
}

const DEFAULT_FEEDBACK_KIOSK_USER_WAIT_DURATION = 10000

export const useFeaturesStore = defineStore('features', {
  state: () => ({
    features: {} as Record<string, any>,
  }),
  getters: {
    feedback(state) {
      return state.features[FeatureKeys.FEEDBACK] ?? defaultValue(FeatureKeys.FEEDBACK)
    },
    feedbackKioskUserWaitDuration(state): number {
      return (
        state.features[FeatureKeys.FEEDBACK_KIOSK_USER_WAIT_DURATION] ??
        DEFAULT_FEEDBACK_KIOSK_USER_WAIT_DURATION
      )
    },
    nestedBubbles(state) {
      return state.features[FeatureKeys.NESTED_BUBBLES] ?? defaultValue(FeatureKeys.NESTED_BUBBLES)
    },
    useNLevels(state) {
      return state.features[FeatureKeys.USE_N_LEVELS] ?? defaultValue(FeatureKeys.USE_N_LEVELS)
    },
    useBubblesV2(state) {
      return state.features[FeatureKeys.USE_BUBBLES_V2] ?? defaultValue(FeatureKeys.USE_BUBBLES_V2)
    },
    xyBubbles(state) {
      return state.features[FeatureKeys.XY_BUBBLES] ?? defaultValue(FeatureKeys.XY_BUBBLES)
    },
  },
  actions: {
    updateFeatures(featureKeys: string[]) {
      featureKeys.forEach((key: string) => {
        const value = growthBookService.getFeatureValue(key, defaultValue(key as Feature))
        this.setFeature({ key, value })
      })
    },
    refreshFeatureValues() {
      try {
        Object.keys(this.features).forEach((key: string) => {
          const value = growthBookService.getFeatureValue(key, defaultValue(key as Feature))
          this.setFeature({ key, value })
        })
      } catch (e) {
        logger.error(`failed to refresh feature values: ${e}`)
      }
    },
    setFeature(payload: { key: string; value: boolean }) {
      this.features[payload.key] = payload.value
    },
  },
})
