<template>
  <div :class="{ 'editable-container': editable }" @click="handleEditableContainerClick">
    <div class="m-2" :class="{ 'question-container': editable }">
      <div class="font-weight-bold">{{ question ? question.title : '' }}</div>
      <template v-if="isTextInput">
        <b-textarea v-model="answer" />
      </template>
      <template v-if="isCheckboxInput">
        <b-form-checkbox-group v-model="answer" stacked :options="options" />
      </template>
      <template v-if="isRadioInput">
        <b-form-radio-group v-model="answer" stacked :options="options" />
      </template>
    </div>

    <EditUserDetailsQuestionDialog
      v-if="editable && showEditDialog"
      v-model="showEditDialog"
      :question="question"
      @ok="updateQuestion"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { QuestionInputTypes, UserDetailsQuestionConfig } from '@/types'
import { useUserDetailsStore } from '@/store/public/userDetails'

const props = {
  question: {
    type: Object as PropType<UserDetailsQuestionConfig>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
}

const components = {
  EditUserDetailsQuestionDialog: () =>
    import('@/components/TipTap/extensions/UserDetails/EditUserDetailsQuestionDialog.vue'),
}

// eslint-disable-next-line no-use-before-define
@Component<UserDetailsQuestion>({ components, name: 'UserDetailsQuestion' })
export default class UserDetailsQuestion extends Vue.extend({ props }) {
  showEditDialog = false
  updatedQuestion: UserDetailsQuestionConfig = this.question
  userDetailsStore = useUserDetailsStore()

  get isTextInput() {
    return this.question.inputType === QuestionInputTypes.TEXT
  }

  get isCheckboxInput() {
    return this.question.inputType === QuestionInputTypes.CHECKBOX
  }

  get isRadioInput() {
    return this.question.inputType === QuestionInputTypes.RADIO
  }

  get options(): string[] {
    return this.question.options
  }

  get answer() {
    return this.userDetailsStore.answers[this.question.id]
  }

  set answer(value: string|string[]) {
    if (value) {
      this.userDetailsStore.setAnswer(this.question.id, value)
    } else {
      this.userDetailsStore.removeAnswer(this.question.id)
    }
  }

  handleEditableContainerClick() {
    if (this.editable) this.showEditDialog = true
  }

  updateQuestion(value: UserDetailsQuestionConfig) {
    if (this.editable) this.$typedStore.primary.app.updateUserDetailsQuestion(value)
  }
}
</script>

<style scoped>
.editable-container {
  cursor: pointer;
  border: dashed;
  border-width: 1px;
  border-color: grey;
  padding: 2px;
}

.question-container {
  pointer-events: none;
}
</style>
