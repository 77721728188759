<template>
  <div class="d-flex justify-content-center align-items-center main-spinner-container">
    <b-spinner
      label="Loading data"
      type="grow"
      variant="info"
      style="height: 200px; width: 200px"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Spinner extends Vue {}
</script>

<style scoped>
.main-spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>
