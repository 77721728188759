var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-table-lite',{staticClass:"d-flex flex-nowrap",attrs:{"fields":_vm.tableFields,"items":_vm.tableItems,"thead-class":"d-none","borderless":"","small":""},scopedSlots:_vm._u([{key:"cell(bubble)",fn:function(data){return [_c('b-row',{attrs:{"no-gutters":""}},[(_vm.haloExist || _vm.displayHollowBubbles)?_c('b-col',{class:{ 'pr-2': data.item.showHalo && data.item.showPulse }},[(data.item.showHalo || data.item.showSolid || data.item.showHollow)?_c('b-iconstack',[(data.item.showHalo)?_c('b-icon',{staticClass:"halo-bubble",style:({
              color: data.item.strokeColor,
              '--bubble-halo-stroke-color': data.item.strokeColor,
            }),attrs:{"stacked":"","scale":"1.5","icon":"circle"}}):_vm._e(),(data.item.showSolid || data.item.showHalo)?_c('b-icon',{staticClass:"solid-bubble",style:({ color: _vm.legendBubbleColor, '--legend-bubble-color': _vm.legendBubbleColor }),attrs:{"stacked":"","icon":"circle-fill"}}):_vm._e(),(data.item.showHollow)?_c('b-icon',{staticClass:"hollow-bubble",style:({ color: _vm.legendBubbleColor, '--legend-bubble-color': _vm.legendBubbleColor }),attrs:{"stacked":"","icon":"circle"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.pulseExist)?_c('b-col',{class:{ 'pl-2': data.item.showHalo && data.item.showPulse }},[(data.item.showPulse)?_c('b-iconstack',[_c('b-icon',{staticClass:"pulsing-bubble",style:({
              color: data.item.pulsingStrokeColor,
              '--bubble-pulsing-stroke-color': data.item.pulsingStrokeColor,
            }),attrs:{"stacked":"","scale":"1.5","icon":"circle-fill","animation":"throb"}}),_c('b-icon',{staticClass:"solid-bubble",style:({ color: _vm.legendBubbleColor, '--legend-bubble-color': _vm.legendBubbleColor }),attrs:{"stacked":"","icon":"circle-fill"}})],1):_vm._e()],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }