import { DATA_PK_ID } from '@/components/TipTap/extensions/ImageExtension'
import { DefaultImageView } from '@orbica/vue-modules'
import Component from 'vue-class-component'

@Component
export default class ImageTipTapView extends DefaultImageView {
  get id() {
    return this.node.attrs[DATA_PK_ID]
  }

  get appSrc() {
    return this.$typedStore.activeVisualisation.visualisationStore.entities.images.image(this.id)
  }

  get src() {
    return this.appSrc || this.node.attrs.src
  }
}
