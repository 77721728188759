// Route names

// Studio admin
export const goToStudio = (appId: number | undefined = undefined) => {
  const path = appId ? `app/${appId}` : 'apps'
  return `${process.env.VUE_APP_STUDIO_URL}/${path}`
}

// Bubble's upload/update/edit
export const ROUTE_DATATABLES = 'datatables'
export const ROUTE_UPLOAD = 'upload'
export const ROUTE_UPDATE = 'update'
export const ROUTE_FROM_TEMPLATE = 'from-template'

export const SECONDARY_SUFFIX = '-secondary'
export const ROUTE_READONLY_PRIMARY = 'readOnly'
export const ROUTE_READONLY_SECONDARY = `${ROUTE_READONLY_PRIMARY}${SECONDARY_SUFFIX}`
export const ROUTE_BUILDER_PRIMARY = 'builder'
export const ROUTE_BUILDER_SECONDARY = `${ROUTE_BUILDER_PRIMARY}${SECONDARY_SUFFIX}`
export const ROUTE_CALLBACK = 'callback'
export const ROUTE_MAP = 'map'
export const ROUTE_PUBLIC = 'public'
export const ROUTE_INITIAL = goToStudio()
export const ROUTE_INTERNAL_PRIMARY = 'route-internal'
export const ROUTE_INTERNAL_SECONDARY = `${ROUTE_INTERNAL_PRIMARY}${SECONDARY_SUFFIX}`
export const ROUTE_MAINTENANCE = 'maintenance'
export const ROUTE_PUBLISHED_PRIMARY = 'published'
export const ROUTE_PUBLISHED_SECONDARY = `${ROUTE_PUBLISHED_PRIMARY}${SECONDARY_SUFFIX}`

// Builder children route names
export const ROUTE_BUBBLE_SETTINGS = 'bubble-settings' // deprecated?
export const ROUTE_COMPARISON_GRAPH_SETTINGS = 'comparison-graph-settings'
export const ROUTE_DOWNLOAD_SETTINGS = 'download-settings'
export const ROUTE_FAQ_SETTINGS = 'info-panel-settings'
export const ROUTE_FIELD_MAP = 'field-map'
export const ROUTE_GENERAL_SETTINGS = 'general-settings'
export const ROUTE_GEOM_ALIAS = 'geom-alias'
export const ROUTE_GEOM_STYLE = 'geom-style'
export const ROUTE_NAV_BTNS_SETTINGS = 'nav-btns-settings'
export const ROUTE_NUMERIC_FORMATTING = 'numeric-formatting'
export const ROUTE_PIE_CHART = 'pie-chart'
export const ROUTE_POPUP_SETTINGS = 'popup-settings'
export const ROUTE_SEARCH_SETTINGS = 'search-settings'
export const ROUTE_SPLASH_SCREEN_SETTINGS = 'splash-screen-settings'
export const ROUTE_STORY_EDITOR = 'story-editor'
export const ROUTE_STORY_SETTINGS = 'story-settings'
export const ROUTE_SUMMARY_TABLE = 'summary-table'
export const ROUTE_TAB_ORDER = 'tab-order'
export const ROUTE_TAB_SETTINGS = 'tab-settings' // deprecated?
export const ROUTE_TITLE_DESCRIPTION = 'title-description'

export const ROUTE_SIDEBAR_SETTINGS = 'sidebar-settings'
export const ROUTE_VISUALISATION_SETTINGS = 'visualisation-settings'
export const ROUTE_OTHER_SETTINGS = 'other-settings'
