import { authGet, authPost, authPut, awaitRequestJson } from '@/api/helpers'
import {
  AppRank,
  AppRanks,
  GeomDataResponse,
  PostCsvPayload,
  RecordResponse,
  SuccessResponse,
  TreeNode,
  TreeNodeResponse,
} from '@/types'

export async function getData() {
  return awaitRequestJson<RecordResponse[]>(authGet('records', {}, AppRanks.PRIMARY, false))
}

export type UpdateDataPayloadItem = {
  dataid: number
  key: string
  recordid: number
  value: string
}

export async function updateData(payload: UpdateDataPayloadItem[]) {
  return awaitRequestJson<SuccessResponse>(authPut('records', payload))
}

type GetTreeParams = {
  excludedNodeNames: string[]
}

export async function getTree(appRank: AppRank, excludedNodeNames: string[] = []) {
  const params: GetTreeParams = {
    excludedNodeNames,
  }
  const response = await awaitRequestJson<TreeNodeResponse>(
    authGet('records/as-tree', params, appRank, false),
  )
  return new TreeNode(response)
}

type GetGeomDataParams = {
  // eslint-disable-next-line camelcase
  level_num: number
  lat?: number
  lon?: number
}

export function getGeomData(recordpath: string, params: GetGeomDataParams, appRank: AppRank) {
  return awaitRequestJson<GeomDataResponse>(
    authGet(`records/by-geom/${recordpath}`, params, appRank, false),
  )
}

export interface LatLonParams {
  lat: number
  lon: number
  displayName: string
}

export function getProjectsByLatLon(params: LatLonParams, appRank: AppRank) {
  return awaitRequestJson<number[]>(authGet('records/by-coords', params, appRank, false))
}

export function postCSV(payload: PostCsvPayload) {
  return awaitRequestJson<SuccessResponse>(authPost({ path: 'records', body: payload }))
}
