import store from '@/store'
import typedStore from '@/store/typedStore'
import { NodeStoryKeys, Path } from '@/types'
import { getModule, Module, VuexModule } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'consolidatedApp', namespaced: true })
export default class ConsolidatedApp extends VuexModule {
  get story() {
    return (path: Path, panel: NodeStoryKeys): string => {
      const nodes = typedStore.primary.entities.nodes.allNodes
      return nodes.find((n) => n.path === path.toString())?.[panel] || ''
    }
  }
}

export const ConsolidatedAppModule = getModule(ConsolidatedApp)
