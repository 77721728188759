<template>
  <div>
    <b-modal id="UserDetailsQuestionModal" v-model="showModal" size="md" hide-header @ok="handleOk">
      <div class="d-flex justify-content-center mb-3">
        <UserDetailsQuestion :question="editingQuestion" />
      </div>

      <hr />

      <div class="d-flex justify-content-center mb-3">
        <b-radio-group
          v-model="inputType"
          buttons
          button-variant="outline-primary"
          :options="inputTypes"
        />
      </div>

      <b-form-group label="Question:" label-cols="2">
        <b-input v-model="title" placeholder="Enter your question" />
      </b-form-group>
      <b-form-group v-if="showOptions" label="Options:" label-cols="2">
        <draggable v-model="options" handle=".drag-handle">
          <b-row v-for="(_, index) in options" :key="index">
            <b-col cols="12" class="drag-handle">
              <div class="d-flex align-items-center mb-2">
                <font-awesome-icon icon="grip-vertical" size="sm" />
                <b-input v-model="options[index]" placeholder="Enter your option" class="ml-3" />
                <b-button variant="outline-danger" class="ml-3" @click="removeOption(index)">
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </draggable>
        <b-button variant="outline-primary" class="mt-2" @click="addOption">
          Add Option <font-awesome-icon icon="plus" />
        </b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { ButtonTemplate } from '@orbica/vue-modules'
import UserDetailsQuestion from './UserDetailsQuestion.vue'
import { QuestionInputType, QuestionInputTypes, UserDetailsQuestionConfig } from '@/types'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

const components = {
  ButtonTemplate,
  draggable,
  UserDetailsQuestion,
}

const props = {
  value: {
    type: Boolean,
    default: false,
  },
  question: {
    type: Object as PropType<UserDetailsQuestionConfig | undefined>,
    default: undefined,
  },
}

@Component({ components })
export default class EditUserDetailsQuestionDialog extends Vue.extend({ props }) {
  inputTypes = [
    { value: QuestionInputTypes.CHECKBOX, text: 'Multi Select' },
    { value: QuestionInputTypes.RADIO, text: 'Single Select' },
    { value: QuestionInputTypes.TEXT, text: 'Text' },
  ]

  id: string = uuidv4()
  title: string = ''
  inputType: QuestionInputType = QuestionInputTypes.CHECKBOX
  options: string[] = []

  created() {
    if (this.question) {
      this.id = this.question.id
      this.title = this.question.title
      this.inputType = this.question.inputType
      this.options = [...this.question.options]
    }
  }

  get showModal() {
    return this.value
  }

  set showModal(newValue: boolean) {
    this.$emit('input', newValue)
  }

  get showOptions() {
    return (
      this.inputType === QuestionInputTypes.RADIO || this.inputType === QuestionInputTypes.CHECKBOX
    )
  }

  addOption() {
    this.options.push('')
  }

  removeOption(index: number) {
    this.options.splice(index, 1)
  }

  get editingQuestion(): UserDetailsQuestionConfig {
    return {
      id: this.id,
      title: this.title,
      inputType: this.inputType,
      options: this.options.filter((o) => o),
    }
  }

  handleOk() {
    this.$emit('ok', this.editingQuestion)
  }
}
</script>
