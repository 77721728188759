import { deleteNode, postConfigItem, postNodes, postStoryItem } from '@/api'
import { NODE_CONFIG_KEY } from '@/constants'
import { NodeUpdate } from '@/services/types'
import { isStoryKeys, NodeData } from '@/types'

class NodeService {
  createNodes(nodes: NodeData[]) {
    return nodes.map(async (node) => {
      await postNodes([node.path])
      return postConfigItem(node.path, NODE_CONFIG_KEY, node.config!)
    })
  }

  updateNodes(nodeUpdates: NodeUpdate[]) {
    const result: Promise<'Success'>[] = []
    nodeUpdates.forEach((nodeUpdate) => {
      nodeUpdate.modifiedPropKeys.forEach((key) => {
        if (isStoryKeys(key)) {
          // @ts-ignore TODO node issues
          result.push(postStoryItem(nodeUpdate.path, key, nodeUpdate.node[key]))
        } else {
          // @ts-ignore TODO node issues
          result.push(postConfigItem(nodeUpdate.path, key, nodeUpdate.node[key]))
        }
      })
    })
    return result
  }

  deleteNodes(paths: string[]) {
    return paths.map((path) => {
      return deleteNode(path)
    })
  }
}

export const nodeService = new NodeService()
