import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import FeedbackTipTapView from './FeedbackTipTapView.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    FeedbackQuestion: {
      setFeedbackQuestion: (attrs: any) => ReturnType
    }
  }
}

export default Node.create({
  name: 'FeedbackQuestionNode',
  group: 'block',
  atom: true,
  allowGapCursor: false,

  addAttributes() {
    return {
      questionId: {
        parseHTML: (element) => element.getAttribute('question-id'),
        renderHTML: (attributes) => ({ 'question-id': attributes.questionId }),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['FeedbackQuestion', HTMLAttributes]
  },

  parseHTML() {
    return [{ tag: 'FeedbackQuestion' }, { tag: 'feedbackquestion' }]
  },

  addNodeView() {
    return VueNodeViewRenderer(FeedbackTipTapView)
  },

  addCommands() {
    return {
      setFeedbackQuestion:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs,
            })
            .command(({ tr, commands }) => {
              const { doc, selection } = tr
              const position = doc.resolve(selection.to).end()
              return commands.setTextSelection(position)
            })
            .run()
        },
    }
  },
})
