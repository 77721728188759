<template>
  <ButtonTemplate :on-click="onClick" title="Insert Bubbles Legends">
    <b-icon icon="card-list" />
  </ButtonTemplate>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'

const components = {
  ButtonTemplate,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

@Component({ components })
export default class InsertBubblesLegends extends Vue.extend({ props }) {
  onClick() {
    if (this.editor) {
      this.editor.chain().focus().setLegends().run()
    }
  }
}
</script>
