<template>
  <div>
    <ButtonTemplate
      :on-click="onClick"
      icon="question-square"
      title="Insert User Details Question"
    />

    <EditUserDetailsQuestionDialog
      v-if="showDialog"
      v-model="showDialog"
      @ok="setQuestion"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'
import EditUserDetailsQuestionDialog from './EditUserDetailsQuestionDialog.vue'
import { UserDetailsQuestionConfig } from '@/types'

const components = {
  ButtonTemplate,
  EditUserDetailsQuestionDialog,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

@Component({ components })
export default class InsertUserDetailsQuestionButton extends Vue.extend({ props }) {
  showDialog = false

  onClick() {
    this.showDialog = true
  }

  setQuestion(value: UserDetailsQuestionConfig) {
    if (this.editor && value) {
      this.$typedStore.primary.app.addUserDetailsQuestion(value)

      this.editor.chain().focus().setUserDetailsQuestion({ questionId: value.id }).run()
    }
  }
}
</script>
