import typedStore from '@/store/typedStore'

const HIDDEN_CONTAINER_STYLING = {
  display: 'inline-block',
  position: 'absolute',
  visibility: 'hidden',
  zIndex: '-9999',
  top: '0',
  left: '0',
}

// If the styling modifies the header's width and height
// The constants below needs to be updated
export const HEADER_BUTTON_ELEMENT_STYLE = {
  fontSize: '2.41em',
  borderWidth: '2.41px',
  borderRadius: '0.5rem',
  padding: '14px 28px',
  whiteSpace: 'nowrap',
}

export class Header {
  path: string
  centerX?: number
  centerY?: number
  private calculatedSize: { height: number; width: number } | null = null

  constructor(path: string) {
    this.path = path
  }

  get name() {
    return typedStore.primary.entities.nodes.name(this.path)
  }

  get height() {
    return this.size.height
  }

  get width() {
    return this.size.width
  }

  private get size() {
    if (!this.calculatedSize) {
      // Creates the hidden div appended to the document body
      const parent = document.querySelector('div.bubbles-container')
      const container = document.createElement('div')
      container.style.display = HIDDEN_CONTAINER_STYLING.display
      container.style.position = HIDDEN_CONTAINER_STYLING.position
      container.style.visibility = HIDDEN_CONTAINER_STYLING.visibility
      container.style.zIndex = HIDDEN_CONTAINER_STYLING.zIndex
      container.style.top = HIDDEN_CONTAINER_STYLING.top
      container.style.left = HIDDEN_CONTAINER_STYLING.left

      const headerButton = document.createElement('button')
      // The bootstrap's class applied to Level 2 Header (SubTierHeader)
      headerButton.className = 'btn btn-secondary'
      // The Level 2 Header (SubTierHeader) scoped CSS and style
      headerButton.style.fontSize = HEADER_BUTTON_ELEMENT_STYLE.fontSize
      headerButton.style.borderWidth = HEADER_BUTTON_ELEMENT_STYLE.borderWidth
      headerButton.style.borderRadius = HEADER_BUTTON_ELEMENT_STYLE.borderRadius
      headerButton.style.padding = HEADER_BUTTON_ELEMENT_STYLE.padding
      headerButton.style.whiteSpace = HEADER_BUTTON_ELEMENT_STYLE.whiteSpace
      headerButton.textContent = this.name
      container.appendChild(headerButton)

      if (parent) parent.appendChild(container)
      const height = container.clientHeight
      const width = container.clientWidth

      if (container.parentNode) container.parentNode.removeChild(container)
      this.calculatedSize = { height, width }
    }
    return this.calculatedSize
  }
}
