<template>
  <div>
    <ButtonTemplate
      :on-click="
        () => {
          showModal = true
        }
      "
      icon="chat-left-dots"
      title="Insert Feedback Question"
    />
    <b-modal
      id="ChooseFeedbackModal"
      v-model="showModal"
      title="Choose a Feedback Question"
      size="lg"
    >
      <div v-if="availableQuestions.length === 0">
        To insert a feedback question, first go to "Optional Features > Feedback" and create a
        feedback question for this level
      </div>
      <div
        v-for="question in availableQuestions"
        :key="question.id"
        class="feedback-option"
        @click="setQuestion(question.id)"
      >
        {{ question.title }}
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'
import { DEFAULT_STORY_KEY, CUSTOM_STORY_LEVEL } from '@/constants'

const components = {
  ButtonTemplate,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

@Component({ components })
export default class InsertFeedbackQuestionButton extends Vue.extend({ props }) {
  showModal = false

  get storyLevel() {
    return this.$typedStore.public.display.defaultStoryPathToEdit
      ? Number(
          this.$typedStore.public.display.defaultStoryPathToEdit.replace(DEFAULT_STORY_KEY, ''),
        )
      : this.$typedStore.activeVisualisation.isCustom
      ? CUSTOM_STORY_LEVEL
      : this.$typedStore.public.display.storyPath.level
  }

  get availableQuestions() {
    return this.$typedStore.primary.app.config.feedbackQuestions.filter(
      (q) => q.level === this.storyLevel,
    )
  }

  setQuestion(questionId: string) {
    if (this.editor) {
      this.editor.chain().focus().setFeedbackQuestion({ questionId }).run()
    }
    this.showModal = false
    this.$bvModal.hide('ChooseFeedbackModal')
  }
}
</script>

<style lang="scss" scoped>
.feedback-option {
  cursor: pointer;
  padding: 0.5em;
  font-weight: 400;
  font-size: 20px;
  &:hover {
    background: #f2fbff;
  }
}
</style>
