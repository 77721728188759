import { getConfigByAppRank, getTemplate, postConfigItem, postNodes } from '@/api'
import { AppRanks, AppTypes, ConfigItem, Template, TemplateTypes, VersionConfig } from '@/types'
import mergeOptions from 'merge-options'
import { configService } from './configService'

class TemplateService {
  async applyBaseTemplate(templateName: string) {
    const template = await getTemplate(templateName, AppRanks.PRIMARY)
    if (
      template?.value &&
      template.appType === AppTypes.BUBBLES &&
      template.templateType === TemplateTypes.BASE
    ) {
      await this.applyConfig(template)
      await this.applyNodes(template)
      await this.applyConfigItems(template)
    }
  }

  private async applyConfig(template: Template) {
    const templateVersionConfig = (template.value as any).config

    if (templateVersionConfig) {
      const existingVersionConfig = await getConfigByAppRank(AppRanks.PRIMARY)
      const config = mergeOptions(existingVersionConfig, templateVersionConfig) as VersionConfig

      const responses = await configService.updateVersionConfig(config)
      this.handleResponses(responses)
    }
  }

  private async applyNodes(template: Template) {
    const nodePaths = (template.value as any).node

    const response = await postNodes(nodePaths)
    this.handleResponses([response])
  }

  private async applyConfigItems(template: Template) {
    const nodeConfigs: ConfigItem[] = (template.value as any).configitem

    const responses = await Promise.all(
      nodeConfigs.map((configItem) => {
        return postConfigItem(configItem.path, configItem.key, configItem.json)
      }),
    )
    this.handleResponses(responses)
  }

  private handleResponses(responses: string[]) {
    if (!responses.every((response) => response && response.includes('Success'))) {
      throw new Error('Applying base template failed') // TODO: Overhaul our whole app's error generation and handling!
    }
  }
}

export const templateService = new TemplateService()
