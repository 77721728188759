import { RenderedBubble, RenderedBubblePulse } from '@/components/public/Visualisations/Bubbles'
import { HaloBubblesConfig } from '@/types'

const DEFAULT_STROKE_COLOR = 'black'
const DEFAULT_STROKE_OPACITY = '0.85'

export class BubblePulse implements RenderedBubblePulse {
  private _bubble: RenderedBubble
  private _activeConfig: HaloBubblesConfig | undefined

  constructor(bubble: RenderedBubble) {
    this._bubble = bubble
  }

  get show(): boolean {
    return this._activeConfig?.showPulse ?? false
  }

  get strokeWidth() {
    if (this.show) {
      return Number(this._activeConfig?.pulsingStrokeWidth) ?? 0
    }
    return 0
  }

  get strokeColor() {
    return this._activeConfig?.pulsingStrokeColor ?? DEFAULT_STROKE_COLOR
  }

  get strokeOpacity() {
    return Number(this._activeConfig?.pulsingStrokeOpacity ?? DEFAULT_STROKE_OPACITY)
  }

  get radius() {
    if (this.show) {
      return this._bubble.radius + this.radiusGap
    }
    return 0
  }

  get duration() {
    return Number(this._activeConfig?.pulsingDuration ?? 0)
  }

  private get radiusGap(): number {
    return Number(this._activeConfig?.pulsingRadiusGap) ?? 0
  }

  setActiveConfig(config: HaloBubblesConfig | undefined) {
    this._activeConfig = config
  }
}
