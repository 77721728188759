import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'CtrlEnter',

  addOptions() {
    return {
      onCtrlEnter: () => {},
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Enter': () => {
        this.options.onCtrlEnter()

        return true
      },
    }
  },
})
