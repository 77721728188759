import { StoryKeys } from '@/types'

// REMEMBER to update NODE_STORY_KEYS when we have new node story keys/or new story panels
export const NODE_STORY_KEYS = [
  StoryKeys.ABOVE_BUBBLES,
  StoryKeys.ABOVE_MAP,
  StoryKeys.SIDEBAR_TOP,
  StoryKeys.SIDEBAR_BOT,
  StoryKeys.CUSTOM_TITLE,
  StoryKeys.CUSTOM_DESCRIPTION,
]

export const NODE_CONFIG_KEY = 'config'
