import { HttpResponse } from '@/api/helpers'

export class HttpError<T> extends Error {
  res: HttpResponse<T>
  headers: HttpResponse<T>['headers']
  ok: HttpResponse<T>['ok']
  redirected: HttpResponse<T>['redirected']
  status: HttpResponse<T>['status']
  statusText: HttpResponse<T>['statusText']
  type: HttpResponse<T>['type']
  url: HttpResponse<T>['url']

  constructor(res: HttpResponse<T>) {
    super(res.statusText)
    this.res = res
    this.name = 'HttpError'
    this.headers = res.headers
    this.ok = res.ok
    this.redirected = res.redirected
    this.status = res.status
    this.statusText = res.statusText
    this.type = res.type
    this.url = res.url
  }

  getJson = () => {
    return this.res.json()
  }
}

export class TooManyRowsError extends Error {
  date: Date

  constructor(length: number) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(`Too many rows! Your CSV has ${length} rows which exceeds the 1000 row limit.`)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TooManyRowsError)
    }

    this.name = 'TooManyRowsError'
    // Custom debugging information
    this.date = new Date()
  }
}
