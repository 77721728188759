import { authPost, awaitRequest, awaitRequestJson } from '@/api/helpers'
import { recaptchaService } from '@/services'
import {
  AppRank,
  Feedback,
  PostAnswerRequest,
  PostCommentRequest,
  PostRatingRequest,
  RatingResponse,
} from '@/types'

export async function postAnswer(appRank: AppRank, payload: PostAnswerRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return awaitRequest(
    authPost({
      path: 'feedback/answer',
      body: {
        feedback_question_id: payload.questionId,
        user_session_id: payload.sessionId,
        path: payload.path,
        friendly_path: payload.friendlyPath,
        answer: payload.answer,
        recaptcha_token: recaptchaToken,
      },
      appRank,
    }),
  )
}

export async function postRating(appRank: AppRank, payload: PostRatingRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return awaitRequestJson<RatingResponse[]>(
    authPost({
      path: 'feedback/rating',
      body: {
        feedback_question_id: payload.questionId,
        user_session_id: payload.sessionId,
        path: payload.path,
        friendly_path: payload.friendlyPath,
        rating: payload.rating,
        recaptcha_token: recaptchaToken,
      },
      appRank,
    }),
  )
}

export async function postComment(appRank: AppRank, payload: PostCommentRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return authPost({
    path: 'feedback/comment',
    body: {
      feedback_question_id: payload.questionId,
      user_session_id: payload.sessionId,
      path: payload.path,
      friendly_path: payload.friendlyPath,
      comment: payload.comment,
      recaptcha_token: recaptchaToken,
    },
    appRank,
  })
}

export async function getFeedback(appRank: AppRank, sessionId: string) {
  return awaitRequestJson<Feedback[]>(
    authPost({
      path: 'feedback/by-session-id',
      body: {
        user_session_id: sessionId,
      },
      appRank,
    }),
  )
}
