<template>
  <node-view-wrapper as="span">
    <b-link class="internal-link" :to="link">{{ node.attrs.title }}</b-link>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

const components = {
  NodeViewWrapper,
}

@Component({ components })
export default class InternalLink extends Vue.extend({ props: nodeViewProps }) {
  get link() {
    const { link } = this.node.attrs
    const currentDepth = this.$typedStore.activeVisualisation.storyPath.parts.length - 1
    return '../'.repeat(currentDepth) + link
  }
}
</script>

<style>
.internal-link {
  cursor: pointer;
  font-weight: 600;
}
</style>
