import { authGet, authPost, awaitRequestJson } from '@/api/helpers'
import {
  AppRank,
  ConfigItem,
  Node,
  NominatumResponse,
  RatesImpactsResponse,
  StoryItem,
  SuccessResponse,
} from '@/types'

export * from '@/api/common'
export * from '@/api/configs'
export * from '@/api/feedback'
export * from '@/api/geometries'
export * from '@/api/images'
export * from '@/api/nodes'
export * from '@/api/organisation'
export * from '@/api/records'
export * from '@/api/template'

export function getLocationSuggestions(address: string) {
  return awaitRequestJson<NominatumResponse>(authGet(`geocode/${address}`))
}

export function searchProjects(search: string, appRank: AppRank) {
  return awaitRequestJson<Node['path'][]>(authGet(`search/${search}`, {}, appRank))
}

export function postStoryItem(
  path: StoryItem['path'],
  key: StoryItem['key'],
  story: StoryItem['story'],
) {
  return awaitRequestJson<SuccessResponse>(
    authPost({ path: 'story-item', body: { path, key, story } }),
  )
}

export function postConfigItem(
  path: ConfigItem['path'],
  key: ConfigItem['key'],
  json: ConfigItem['json'],
) {
  return awaitRequestJson<SuccessResponse>(
    authPost({ path: 'config-item', body: { path, key, json } }),
  )
}

export function duplicateModel<T = unknown>(
  modelName: string,
  from: number,
  to: number,
  body: object,
) {
  // TODO: Handle response
  return awaitRequestJson<T>(authPost({ path: `duplicate-${modelName}/${from}/${to}`, body }))
}

export function getValidationResult(from: string, to: string): Promise<Record<string, any>> {
  return awaitRequestJson(authGet(`validate-data/${from}/${to}`))
}

export function getRatesImpactTable() {
  return awaitRequestJson<RatesImpactsResponse>(authGet('rates-impact'))
}
