import { render, staticRenderFns } from "./FeedbackTipTapView.vue?vue&type=template&id=bd22c808&scoped=true&"
import script from "./FeedbackTipTapView.vue?vue&type=script&lang=ts&"
export * from "./FeedbackTipTapView.vue?vue&type=script&lang=ts&"
import style0 from "./FeedbackTipTapView.vue?vue&type=style&index=0&id=bd22c808&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd22c808",
  null
  
)

export default component.exports