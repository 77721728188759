import { postUserDetailsAnswer } from '@/api/userDetails'
import { useFeedbackStore } from '@/store/public/feedback'
import typedStore from '@/store/typedStore'
import { defineStore } from 'pinia'
import Vue from 'vue'

export interface IUserDetailsState {
  modalVisible: boolean
  answers: Record<string, string|string[]>
}

export const useUserDetailsStore = defineStore({
  id: 'userDetails',
  state: (): IUserDetailsState => ({
    modalVisible: false,
    answers: {},
  }),
  getters: {
    questionIds(): string[] {
      return typedStore.primary.app.config.userDetails.questions.map((question) => question.id)
    },
    allQuestionsAnswered(state: IUserDetailsState): boolean {
      if (!typedStore.primary.app.config.userDetails.collectUserDetails) return true

      const answeredIds = Object.keys(state.answers)
      return this.questionIds.every((id) => answeredIds.includes(id))
    },
  },
  actions: {
    reset() {
      this.showModal()
      this.answers = {}
    },
    showModal(value: boolean = true) {
      if (value && typedStore.primary.app.config.userDetails.collectUserDetails) {
        this.modalVisible = true
      } else {
        this.modalVisible = false
      }
    },
    setAnswer(questionId: string, answer: string|string[]) {
      Vue.set(this.answers, questionId, answer)
    },
    removeAnswer(questionId: string) {
      Vue.delete(this.answers, questionId)
    },
    async saveAnswers() {
      if (this.allQuestionsAnswered) {
        const sessionId = useFeedbackStore().getSessionId()
        const promises = Object.entries(this.answers).map(async ([questionId, answer]) => {
          if (this.questionIds.includes(questionId)) {
            await postUserDetailsAnswer({
              sessionId,
              questionId,
              answer: Array.isArray(answer) ? answer.join(';') : answer,
            })
          }
        })

        await Promise.all(promises)
      }
    },
  },
})
