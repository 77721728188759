import { NODE_CONFIG_KEY } from '@/constants'
import {
  CustomTabNodeConfig,
  Node,
  NodeConfig,
  NodeType,
  SecondaryAppReferenceNodeConfig,
  StoryKeys,
  VisNode,
} from '.'

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export interface ImagePreviewItem {
  configPath: string[]
  file: File
  url: string
}

export interface ImagePreview {
  [key: string]: ImagePreviewItem
}

export interface NodeData {
  path: Node['path']
  parent: Node['path'] | null
  [StoryKeys.SIDEBAR_BOT]?: string
  [StoryKeys.SIDEBAR_TOP]?: string
  [StoryKeys.ABOVE_MAP]?: string
  [StoryKeys.ABOVE_BUBBLES]?: string
  [StoryKeys.CUSTOM_DESCRIPTION]?: string
  [StoryKeys.CUSTOM_TITLE]?: string
  [NODE_CONFIG_KEY]?: NodeConfig
}

export interface CustomTabNode extends NodeData {
  [NODE_CONFIG_KEY]: CustomTabNodeConfig
}

export interface SecondaryAppReferenceNode extends NodeData {
  [NODE_CONFIG_KEY]: SecondaryAppReferenceNodeConfig
}

export interface Tab {
  name: string
  path: string
  nodeType: NodeType
  secondaryAppPath?: string
  secondaryAppId?: number
  isSecondaryInitialTab?: boolean
  showMobileIcon?: boolean
  showMobileLabel?: boolean
  label?: string
  showLabel: boolean
  visible: boolean
  colour: string
  barColour?: string
  tabFontColor: string
  customiseTabFontColour: boolean
  showIcon: boolean
  iconId?: number
}

export interface SelectOption {
  value: string | undefined
  text: string | undefined
}

export interface Coordinate2D {
  x: number
  y: number
}

export interface Dimensions {
  width: number
  height: number
}

export class Rectangle {
  top: number
  left: number
  bottom: number
  right: number

  constructor({
    top,
    left,
    bottom,
    right,
  }: {
    top: number
    left: number
    bottom: number
    right: number
  }) {
    this.top = top
    this.left = left
    this.bottom = bottom
    this.right = right
  }

  get width(): number {
    return this.right - this.left
  }

  get height(): number {
    return this.bottom - this.top
  }

  get center(): { x: number; y: number } {
    return {
      x: this.left + this.width / 2,
      y: this.top + this.height / 2,
    }
  }
}

export interface Field {
  key: string
  label: string
}

export interface PopupDetails {
  node: VisNode
  leftBuffer?: number
}

export interface BubblesPopupDetails extends PopupDetails {
  position: Coordinate2D
  radius: number
}

export interface MapPopupDetails extends PopupDetails {
  projectCount: number
}

export interface VisualisationPopupDetails extends PopupDetails {
  entityCenter: Coordinate2D
  entityTopLeft: Coordinate2D
}

export interface ListItem {
  column: string
  name: string
}
