<template>
  <div data-cy="story-select">
    <div v-for="(_, index) in levelCount" :key="index">
      <b-select
        v-if="display(index)"
        v-model="selected[index]"
        :disabled="imageUploading"
        :options="getChildren(index)"
        @change="cleanSelected(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { DEFAULT_STORY_KEY, PATH_ROOT, PATH_SUBROOT } from '@/constants'
import { Tab, TreeNode } from '@/types'

const props = {
  selectedStoryPath: String as PropType<String | undefined>,
}

@Component({})
export default class StoryPicker extends Vue.extend({ props }) {
  get imageUploading() {
    return this.$typedStore.primary.entities.images.imageUploading
  }

  get allPrimaryTabs() {
    return this.$typedStore.primary.entities.tabs.allPrimaryTabs
  }

  get levelCount() {
    return this.$typedStore.primary.app.isConsolidatedApp
      ? 1
      : this.$typedStore.visualisationData.levelCount
  }

  get defaultStoryNumbers() {
    return this.$typedStore.visualisationData.allLevels
  }

  get defaultStoryKeys() {
    return this.defaultStoryNumbers.map((i) => `${DEFAULT_STORY_KEY}${i}`)
  }

  get defaultStoryOptions() {
    return this.defaultStoryNumbers.map((i) => ({
      value: `${DEFAULT_STORY_KEY}${i}`,
      text: `'${this.levelName(i)}' default story (level ${i})`,
    }))
  }

  get rootOptions() {
    let options: any[] = mapTabs(this.allPrimaryTabs)

    if (this.$typedStore.primary.app.isVisualisationApp) {
      options = [
        ...options,
        { text: '----------------------------', disabled: true },
        ...this.defaultStoryOptions,
      ]
    }

    return options
  }

  get selected() {
    if (this.selectedStoryPath) {
      const pathItems = this.selectedStoryPath.split('/')
      const result: string[] = []
      pathItems.forEach((_, index) => {
        if (pathItems[index - 1]) {
          const str = result[index - 1] + '/' + pathItems[index]
          result.push(str)
        } else {
          result.push(pathItems[index])
        }
      })
      return result
    }
    return []
  }

  set selected(newValue) {
    // Emits the last item in the selected array as the storyPath
    let path = newValue.slice(-1)[0]
    // unless the last item is null, in which case use the second to last
    if (path === null || path === undefined) {
      path = newValue.slice(-2)[0]
    }

    this.$emit('update:selectedStoryPath', path)
  }

  get hideChildren() {
    const hide = [
      PATH_ROOT,
      PATH_SUBROOT,
      ...this.defaultStoryKeys,
      ...this.$typedStore.primary.entities.nodes.customTabNodes.map((x) => x.path),
    ]
    return hide.includes(this.selected[0])
  }

  getChildren(index: number) {
    if (index === 0) {
      return this.rootOptions
    } else {
      const path = this.selected[index - 1]
      if (path === PATH_ROOT || path === PATH_SUBROOT) {
        // root and subroot have no children
        return []
      }
      const tree = this.$typedStore.visualisationData.treeRoot.search(path)
      if (tree) {
        return [{ value: null, text: '' }, ...mapTabs(tree.children || [])]
      }
      return []
    }
  }

  display(index: number) {
    if (index === 0) {
      return true
    } else if (this.hideChildren) {
      return false
    } else {
      return Boolean(this.selected[index - 1])
    }
  }

  cleanSelected(index: number) {
    this.selected = this.selected.slice(0, index + 1)
  }

  levelName(level: number) {
    const field = this.$typedStore.primary.app.levels.fieldForLevel(level)
    return field?.name || field?.column || ''
  }
}

function mapTabs(tabs: Tab[] | TreeNode[]) {
  return tabs.map((tab) => ({ value: tab.path.toString(), text: tab.name }))
}
</script>
