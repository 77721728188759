import { FieldCalculationFunctionTypes } from '@/types'
import { all, create } from 'mathjs'

// // Count function is included in Math-js v9.2.0
// const name = FieldCalculationFunctionTypes.COUNT
// const dependencies = ['typed' as keyof MathJsStatic, 'size' as keyof MathJsStatic, 'prod' as keyof MathJsStatic]

// const createCount = /* #__PURE__ */ factory(name, dependencies, ({ typed, size, prod }) => {
//   /**
//    * Count the number of elements of a matrix, array or string.
//    *
//    * Syntax:
//    *
//    *     math.count(x)
//    *
//    * Examples:
//    *
//    *     math.count('hello world')        // returns 11
//    *     const A = [[1, 2, 3], [4, 5, 6]]
//    *     math.count(A)                    // returns 6
//    *     math.count(math.range(1,6))      // returns 5
//    *
//    * See also:
//    *
//    *     size
//    *
//    * @param {string | Array | Matrix} x  A matrix or string
//    * @return {number} An integer with the elements in `x`.
//    */
//   return typed(name, {
//     string: function (x) {
//       return x.length
//     },

//     'Matrix | Array': function (x) {
//       return prod(size(x))
//     },
//   })
// })

// const math = create({
//   ...all,
//   createCount,
// })

const math = create(all)

// // Use custom argument parsing to
// // https://mathjs.org/examples/advanced/custom_argument_parsing.js.html
export function sumOfAll(value: number) {
  // This function simply returns the value calculated in the transform
  return value
}
sumOfAll.transform = function (args: string[], math: any, scope: any) {
  const column = args[0]
  const value = math.sum(scope[FieldCalculationFunctionTypes.SUM_OF_ALL + column])
  return sumOfAll(value)
}
// @ts-ignore
sumOfAll.transform.rawArgs = true

math.import({ sum_of_all: sumOfAll })

export const { parse, compile } = math
