import { authGet, awaitRequestJson } from '@/api/helpers'
import { AppResponse, OrganisationAppResponse, OrganisationResponse } from '@/types'

export function getOrganisation() {
  return awaitRequestJson<OrganisationResponse>(authGet('organisation'))
}

export function getOrgApps() {
  return awaitRequestJson<OrganisationAppResponse[]>(authGet('organisation/apps'))
}

export function getOrgAppsByOrgId(orgId: number) {
  return awaitRequestJson<AppResponse[]>(authGet(`organisation/${orgId}/apps`))
}
