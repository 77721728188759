import { NAV_BUTTONS_CONFIG_KEY, NAV_BUTTON_DETAILS } from '@/constants'
import { nameof } from '@/store/helpers'
import typedStore from '@/store/typedStore'
import {
  LegendPositions,
  NavButtonConfig,
  NavButtonDisplayType,
  NavButtonDisplayTypes,
  NavButtonKey,
  NavButtonKeys,
  NavButtonsConfig,
  VersionConfig,
  VisualisationType,
  VisualisationTypes,
} from '@/types'
import _cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'

export interface NavButtonDetails extends NavButtonConfig {
  /*
   * The following properties are defined in @/constants/NAV_BUTTON_DETAILS
   */
  name: string
  defaultLabel: string
  defaultIcon: string | string[]
  visualisationType?: VisualisationType
  /*
   * The following properties are calculated at runtime
   */
  enabled: boolean
  iconConfigPath: string[]
  labelConfigPath: string[]
  iconImageSrc?: string
}
type NavButtonsDetails = Record<NavButtonKey, NavButtonDetails>

export interface INavButtonsState {
  menuExpanded: Boolean
  visualisationsExpanded: Boolean
}

export const useNavButtonsStore = defineStore('navButtons', {
  state: (): INavButtonsState => ({
    menuExpanded: false,
    visualisationsExpanded: false,
  }),
  getters: {
    activeAppConfig: (): VersionConfig =>
      typedStore.activeVisualisation.visualisationStore.app.config,
    buttonsConfig(): NavButtonsConfig {
      return this.activeAppConfig?.navButtons ? _cloneDeep(this.activeAppConfig.navButtons) : {}
    },
    allBtns(): Record<NavButtonKey, NavButtonDetails> {
      return Object.entries(this.buttonsConfig).reduce(
        (acc, [key, btn]) => {
          const { name, defaultLabel, defaultIcon, visualisationType } = NAV_BUTTON_DETAILS[key]
          const iconConfigPath = [NAV_BUTTONS_CONFIG_KEY, key, nameof<NavButtonConfig>('icon')]
          const iconImageSrc =
            typedStore.activeVisualisation.visualisationStore.entities.images.imageOrPreview([
              'config',
              ...iconConfigPath,
            ])

          acc[key as NavButtonKey] = {
            ...btn,
            name,
            defaultLabel,
            defaultIcon,
            visualisationType,
            enabled: this.buttonEnabled(key),
            iconConfigPath,
            labelConfigPath: [NAV_BUTTONS_CONFIG_KEY, key, nameof<NavButtonConfig>('label')],
            iconImageSrc,
          }
          return acc
        },
        {} as Record<NavButtonKey, NavButtonDetails>,
      )
    },
    staticBtns(): NavButtonDetails[] {
      return this.filterAndSortButtons(this.allBtns, NavButtonDisplayTypes.STATIC).filter(
        (btn) => btn.enabled,
      )
    },
    standaloneBtns(): NavButtonDetails[] {
      return this.filterAndSortButtons(this.allBtns, NavButtonDisplayTypes.STANDALONE).filter(
        (btn) => btn.enabled,
      )
    },
    menuItemBtns(): NavButtonDetails[] {
      return this.filterAndSortButtons(this.allBtns, NavButtonDisplayTypes.MENU_ITEM).filter(
        (btn) => btn.enabled,
      )
    },
    visualisationBtns(): NavButtonDetails[] {
      return Object.values(this.allBtns)
        .filter(
          ({ displayType, enabled }) =>
            displayType === NavButtonDisplayTypes.VISUALISATION && enabled,
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    activeVisualisationTypes(): VisualisationType[] {
      return typedStore.activeVisualisation.activeVisualisationTypes
    },
    filterAndSortButtons: (): ((
      buttons: NavButtonsDetails,
      displayType: NavButtonDisplayType,
    ) => NavButtonDetails[]) => {
      return (buttons: NavButtonsDetails, displayType: NavButtonDisplayType) => {
        return Object.values(buttons)
          .filter((btn) => btn.displayType === displayType)
          .sort((a, b) => a.order - b.order)
      }
    },
    filterEnabled(): boolean {
      const { enable, position } = this.activeAppConfig.filterByCategory
      const { isMobile } = typedStore.public.display
      return enable && (position === LegendPositions.TOP_RIGHT_BUTTON || isMobile)
    },
    searchEnabled(): boolean {
      const { enabled, enableSearchByLocation, enableSearchForProjects } =
        this.activeAppConfig.searchSettings
      return (enabled && (enableSearchByLocation || enableSearchForProjects)) ?? false
    },
    buttonEnabled(): (key: NavButtonKey) => boolean {
      return (key: NavButtonKey) => {
        switch (key) {
          case NavButtonKeys.MENU:
            return true
          case NavButtonKeys.SEARCH:
            return this.activeAppConfig.searchSettings.enabled
          case NavButtonKeys.INFO:
            return this.activeAppConfig.faq.enabled
          case NavButtonKeys.DOWNLOAD:
            return this.activeAppConfig.download.enabled
          case NavButtonKeys.FILTER:
            return this.filterEnabled
          case NavButtonKeys.VISUALISATIONS:
            return true
          case NavButtonKeys.BUBBLES:
            return this.activeVisualisationTypes.includes(VisualisationTypes.BUBBLES)
          case NavButtonKeys.NESTED_BUBBLES:
            return this.activeVisualisationTypes.includes(VisualisationTypes.NESTED_BUBBLES)
          case NavButtonKeys.XY_BUBBLES:
            return this.activeVisualisationTypes.includes(VisualisationTypes.XY_BUBBLES)
          case NavButtonKeys.SUNBURST:
            return this.activeVisualisationTypes.includes(VisualisationTypes.SUNBURST)
          case NavButtonKeys.MAP:
            return this.activeVisualisationTypes.includes(VisualisationTypes.MAP)
          case NavButtonKeys.RESET:
            return typedStore.primary.app.config.userDetails.collectUserDetails
          default:
            return false
        }
      }
    },
  },
  actions: {
    setMenuExpanded(expanded: Boolean) {
      this.menuExpanded = expanded
    },
    setVisualisationsExpanded(expanded: Boolean) {
      this.visualisationsExpanded = expanded
    },
    updateButtonsConfig(newKeys: NavButtonKey[], displayType: NavButtonDisplayType) {
      newKeys.forEach((key, index) => {
        const config = this.buttonsConfig[key]
        if (config) {
          config.order = index
          config.displayType = displayType
        }
      })

      this.filterAndSortButtons(this.allBtns, displayType)
        .filter((btn) => newKeys.every((key) => btn.key !== key))
        .forEach(({ key }, index) => {
          const config = this.buttonsConfig[key]
          if (config) {
            config.order = index + newKeys.length
          }
        })

      typedStore.primary.app.updateConfig({
        configPath: [NAV_BUTTONS_CONFIG_KEY],
        value: this.buttonsConfig,
      })
    },
  },
})
