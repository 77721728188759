import { RenderedBubble, RenderedBubbleHalo } from '@/components/public/Visualisations/Bubbles'
import { HaloBubblesConfig } from '@/types'
import { color, RGBColor } from 'd3-color'

const DEFAULT_STROKE_COLOR = 'black'
const DEFAULT_STROKE_OPACITY = '0.85'

export class BubbleHalo implements RenderedBubbleHalo {
  private _bubble: RenderedBubble
  private _activeConfig: HaloBubblesConfig | undefined

  constructor(bubble: RenderedBubble) {
    this._bubble = bubble
  }

  get show(): boolean {
    return this._activeConfig?.showHalo ?? false
  }

  get strokeWidth() {
    if (this.show) {
      return Number(this._activeConfig?.strokeWidth) ?? 0
    }
    return 0
  }

  get strokeColor() {
    const hex = this._activeConfig?.strokeColor ?? DEFAULT_STROKE_COLOR
    const opacity = Number(this._activeConfig?.strokeOpacity ?? DEFAULT_STROKE_OPACITY)
    const colour = color(hex) as RGBColor
    colour.opacity = opacity

    return colour.formatRgb()
  }

  get radius() {
    if (this.show) {
      return this._bubble.radius + this.radiusGap + this.strokeWidth / 2
    }
    return 0
  }

  get totalWidth(): number {
    if (this.show) {
      return this.radiusGap + this.strokeWidth
    }
    return 0
  }

  private get radiusGap(): number {
    return Number(this._activeConfig?.radiusGap) ?? 0
  }

  setActiveConfig(config?: HaloBubblesConfig) {
    this._activeConfig = config
  }
}
