import { is } from '@/helpers'
import { TreeNode } from '@/types'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class HeadersAndHandlesMixin extends Vue {
  get activeTabTreeNode() {
    return this.$typedStore.activeVisualisation.activeTabTreeNode
  }

  get activeTabTreeNodePath() {
    return this.activeTabTreeNode && this.activeTabTreeNode.path
  }

  get treeRootChildren() {
    return this.$typedStore.visualisationData.treeRoot.children ?? []
  }

  get nodes(): TreeNode[] {
    if (this.activeTabTreeNodePath && this.activeTabTreeNodePath.isRoot) return []
    if (this.activeTabTreeNodePath && this.activeTabTreeNodePath.isSubroot)
      return this.treeRootChildren
    if (this.activeTabTreeNode) {
      const handlePaths = this.$typedStore.visualisationData.nodeGroups.main.map(
        (g) => g.ancestorPath,
      )
      const activeTabTreeNode = this.activeTabTreeNode as TreeNode
      return handlePaths
        .map((path: string) => activeTabTreeNode.search(path))
        .filter(is<TreeNode>())
    }
    return []
  }
}
