import { getVersionList } from '@/api'
import store from '@/store'
import typedStore from '@/store/typedStore'
import { VersionItem } from '@/types'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface IVersionsState {
  versionList: VersionItem[]
}

@Module({ dynamic: true, store, name: 'versions', namespaced: true })
export default class Versions extends VuexModule implements IVersionsState {
  versionList: VersionItem[] = []

  @Mutation
  setVersionList(versionList: VersionItem[]) {
    this.versionList = versionList
  }

  get currentVersion() {
    const { primaryVersionId: versionId } = typedStore.currentRoute
    return this.versionList.find(({ id }) => id === versionId)
  }

  get versionName() {
    return this.currentVersion && this.currentVersion.name
  }

  @Action({ rawError: true })
  async fetchVersionList() {
    const appId = typedStore.currentRoute.primaryAppId
    this.setVersionList(await getVersionList({ appId }))
  }
}

export const VersionsModule = getModule(Versions)
