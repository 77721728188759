// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
  prefix: 'custom',
  iconName: 'visualisations',
  icon: [
    1024,
    1024,
    [],
    'f0000',
    'm512 359.31c-83.784 0-152.69 68.907-152.69 152.69 0 83.784 68.907 152.69 152.69 152.69 83.784 0 152.69-68.907 152.69-152.69 0-83.784-68.907-152.69-152.69-152.69zm0 92.077c34.021 0 60.614 26.593 60.614 60.614s-26.593 60.614-60.614 60.614-60.614-26.593-60.614-60.614 26.593-60.614 60.614-60.614zm0-271.37c-182.8 0-331.99 149.18-331.99 331.99s149.18 331.99 331.99 331.99 331.99-149.18 331.99-331.99-149.18-331.99-331.99-331.99zm0 92.077c133.05 0 239.91 106.86 239.91 239.91 0 133.05-106.86 239.91-239.91 239.91-133.05 0-239.91-106.86-239.91-239.91 0-133.05 106.86-239.91 239.91-239.91zm0-265.07c-133.91 0-262.38 53.213-357.07 147.91-94.688 94.688-147.91 223.17-147.91 357.07s53.213 262.38 147.91 357.07c94.688 94.688 223.17 147.91 357.07 147.91s262.38-53.213 357.07-147.91c94.688-94.688 147.91-223.17 147.91-357.07s-53.213-262.38-147.91-357.07c-94.688-94.688-223.17-147.91-357.07-147.91zm0 92.075c109.52 0 214.51 43.491 291.96 120.93 77.452 77.442 120.93 182.43 120.93 291.96 0 109.53-43.491 214.51-120.93 291.96-77.442 77.452-182.43 120.93-291.96 120.93s-214.51-43.491-291.96-120.93c-77.452-77.442-120.93-182.43-120.93-291.96 0-109.52 43.491-214.51 120.93-291.96s182.43-120.93 291.96-120.93z',
  ],
}
