<template>
  <ButtonTemplate :on-click="() => onClick()" icon="code-slash" title="Insert embedded URL" />
</template>

<script lang="ts">
import { Editor } from '@tiptap/core'
import Vue from 'vue'
import Component from 'vue-class-component'

import { ButtonTemplate } from '@orbica/vue-modules'

const components = {
  ButtonTemplate,
}
const props = {
  editor: {
    type: Editor,
    default: null,
  },
}

@Component({ components })
export default class InsertIframeButton extends Vue.extend({ props }) {
  onClick() {
    const src = prompt('Enter the url of your iframe here')
    if (src !== null && this.editor) {
      this.editor.chain().focus().setIframe({ src }).run()
    }
  }
}
</script>
