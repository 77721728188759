<template>
  <div class="stars" :class="{ disabled }">
    <div v-for="n in 5" :key="n" class="option star-container">
      <b-icon
        v-show="!disabled && hovered ? n <= hovered : n <= value"
        icon="star-fill"
        class="fill"
      />
      <b-icon
        icon="star"
        class="outline"
        @click="$emit('input', n)"
        @mouseover="hovered = n"
        @mouseleave="hovered = 0"
      />
    </div>
    <slot :rating-count="5"></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
const props = {
  value: Number,
  disabled: Boolean,
}

@Component
export default class StarsFeedbackControl extends Vue.extend({ props }) {
  hovered = 0
}
</script>

<style lang="scss" scoped>
.stars {
  width: 280px;
  &.disabled {
    .star-container {
      cursor: not-allowed;
    }
  }
  .star-container {
    width: 100%;
    padding: 0 4px;
    cursor: pointer;
    position: relative;
    svg {
      width: 100%;
      height: 100%;
      transition: transform 0.2s;
    }
    .outline {
      position: relative;
      z-index: 1;
    }
    .fill {
      padding: 0 4px;
      left: 0;
      top: -2px;
      position: absolute;
      pointer-events: none;
      color: #ffc154;
    }
  }
}
</style>
