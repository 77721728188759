import {
  MAINTENANCE_MODE,
  ROUTE_BUILDER_PRIMARY,
  ROUTE_BUILDER_SECONDARY,
  ROUTE_CALLBACK,
  ROUTE_DATATABLES,
  ROUTE_FROM_TEMPLATE,
  ROUTE_INTERNAL_PRIMARY,
  ROUTE_INTERNAL_SECONDARY,
  ROUTE_MAINTENANCE,
  ROUTE_PUBLISHED_PRIMARY,
  ROUTE_PUBLISHED_SECONDARY,
  ROUTE_READONLY_PRIMARY,
  ROUTE_READONLY_SECONDARY,
  ROUTE_UPDATE,
  ROUTE_UPLOAD,
  SECONDARY_APP_PATH_PREFIX,
  VIEW_MAP,
  VIEW_PUBLIC,
  VIEW_VISUAL,
} from '@/constants'
import {
  redirectMapToPublicGuard,
  requiresAuthGuard,
  storyPathToArrayGuard,
} from '@/router/routeGuards'
import { CallbackView } from '@orbica/vue-modules'
import Vue from 'vue'
import Router, { RouterOptions } from 'vue-router'
import multiguard from 'vue-router-multiguard'

const MaintenanceView = () =>
  import(/* webpackChunkName: "maintenance-view" */ '@/views/MaintenanceView.vue')

const EditDataView = () =>
  import(/* webpackChunkName: "edit-data-view" */ '@/views/admin/EditDataView.vue')
const FromTemplateView = () =>
  import(/* webpackChunkName: "from-template-view" */ '@/views/admin/FromTemplateView.vue')
const UploadView = () =>
  import(/* webpackChunkName: "upload-update-view" */ '@/views/admin/UploadView.vue')
const UpdateView = () =>
  import(/* webpackChunkName: "upload-update-view" */ '@/views/admin/UpdateView.vue')

const BuilderView = () => import(/* webpackChunkName: "builder-view" */ '@/views/BuilderView.vue')
const PublicView = () => import(/* webpackChunkName: "public-view" */ '@/views/PublicView.vue')
const AuthView = () => import(/* webpackChunkName: "auth-view" */ '@/views/AuthView.vue')

// Recommended fix to avoid navigation failure errors
// Replicates vue-router-next behaviour
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
// @ts-ignore changing underlying type behaviour
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  // @ts-ignore changing underlying type behaviour
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const APP_ID_VERSION_ID_PATH = '/app/:primaryAppId/version/:primaryVersionId'
const BUILDER_PATH = 'builder/:sidepanel'
const ORG_APP_SLUG_PATH = '/:orgSlug/:primaryAppSlug'
const MAINPANEL_PATH = `/:mainpanel(${VIEW_PUBLIC}|${VIEW_MAP}|${VIEW_VISUAL})`
const PRIMARY_STORY_PATH = '/:storyPath*'
const SECONDARY_STORY_PATH = `/:storyPath(${SECONDARY_APP_PATH_PREFIX}[a-z0-9]{16})/:secondaryStoryPath+`
const FROM_TEMPLATE_PATH = 'from-template/:template'

export const routerOptions: RouterOptions = {
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: ROUTE_PUBLISHED_PRIMARY, params: { mainpanel: VIEW_PUBLIC } },
    },
    {
      path: APP_ID_VERSION_ID_PATH,
      component: AuthView,
      children: [
        {
          path: 'datatables',
          name: ROUTE_DATATABLES,
          component: EditDataView,
          meta: { requiresAuth: true, fluid: true, helpButtonBottom: true },
        },
        {
          path: FROM_TEMPLATE_PATH,
          name: ROUTE_FROM_TEMPLATE,
          component: FromTemplateView,
          meta: { requiresAuth: true, contain: true },
        },
        {
          path: 'upload',
          name: ROUTE_UPLOAD,
          component: UploadView,
          meta: { requiresAuth: true, contain: true },
        },
        {
          path: 'update',
          name: ROUTE_UPDATE,
          component: UpdateView,
          meta: { requiresAuth: true, contain: true },
        },
        {
          path: BUILDER_PATH + MAINPANEL_PATH + SECONDARY_STORY_PATH,
          name: ROUTE_BUILDER_SECONDARY,
          component: BuilderView,
          meta: { requiresAuth: true, isBuilder: true },
        },
        // Secondary route must come before primary route
        {
          path: BUILDER_PATH + MAINPANEL_PATH + PRIMARY_STORY_PATH,
          name: ROUTE_BUILDER_PRIMARY,
          component: BuilderView,
          meta: { requiresAuth: true, isBuilder: true },
        },
        {
          path: BUILDER_PATH,
          redirect: (to) => ({
            name: ROUTE_BUILDER_PRIMARY,
            params: {
              ...to.params,
              mainpanel: VIEW_PUBLIC,
            },
          }),
        },
        {
          path: '/',
          redirect: (to) => ({
            name: ROUTE_READONLY_PRIMARY,
            params: {
              ...to.params,
              mainpanel: VIEW_PUBLIC,
            },
          }),
        },
      ],
    },
    {
      path: '/callback',
      name: ROUTE_CALLBACK,
      component: CallbackView,
    },
    ...(MAINTENANCE_MODE
      ? [
          {
            path: '/maintenance',
            name: ROUTE_MAINTENANCE,
            component: MaintenanceView,
            meta: { contain: true },
          },
        ]
      : []),
    {
      path: APP_ID_VERSION_ID_PATH + MAINPANEL_PATH + SECONDARY_STORY_PATH,
      name: ROUTE_READONLY_SECONDARY,
      component: PublicView,
      meta: { requiresAuth: true },
    },
    // Secondary route must come before primary route
    {
      path: APP_ID_VERSION_ID_PATH + MAINPANEL_PATH + PRIMARY_STORY_PATH,
      name: ROUTE_READONLY_PRIMARY,
      component: PublicView,
      meta: { requiresAuth: true },
    },
    {
      path: MAINPANEL_PATH + SECONDARY_STORY_PATH,
      name: ROUTE_PUBLISHED_SECONDARY,
      component: PublicView,
    },
    // Secondary route must come before primary route
    {
      path: MAINPANEL_PATH + PRIMARY_STORY_PATH,
      name: ROUTE_PUBLISHED_PRIMARY,
      component: PublicView,
    },
    {
      path: ORG_APP_SLUG_PATH + MAINPANEL_PATH + SECONDARY_STORY_PATH,
      name: ROUTE_INTERNAL_SECONDARY,
      component: PublicView,
      meta: { requiresAuth: true },
    },
    // Secondary route must come before primary route
    {
      path: ORG_APP_SLUG_PATH + MAINPANEL_PATH + PRIMARY_STORY_PATH,
      name: ROUTE_INTERNAL_PRIMARY,
      component: PublicView,
      meta: { requiresAuth: true },
    },
    {
      path: ORG_APP_SLUG_PATH,
      redirect: (to) => ({
        name: ROUTE_INTERNAL_PRIMARY,
        params: {
          ...to.params,
          mainpanel: VIEW_PUBLIC,
        },
      }),
    },
    {
      path: '*',
      redirect: {
        name: ROUTE_PUBLISHED_PRIMARY,
        params: { mainpanel: VIEW_PUBLIC },
      },
    },
  ],
}

const router = new Router(routerOptions)

router.beforeEach(multiguard([redirectMapToPublicGuard, requiresAuthGuard, storyPathToArrayGuard]))

export default router
