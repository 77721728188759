<template>
  <div class="feedback-controls">
    <component
      :is="currentFeedbackControl"
      :value="value"
      :faces="faces"
      :results="results"
      :outline-colour="outlineColour"
      :fill-colour="fillColour"
      :disabled="disabled"
      class="control"
      @input="$emit('input', $event)"
    >
      <template v-if="results.length" #default="{ ratingCount }">
        <div v-for="rating in ratingCount" :key="`count-${rating}`" class="count">
          {{ results.length ? getCount(rating) : '' }}
        </div>
      </template>
    </component>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import ThumbsFeedbackControl from './ThumbsFeedbackControl.vue'
import VotesFeedbackControl from './VotesFeedbackControl.vue'
import StarsFeedbackControl from './StarsFeedbackControl.vue'
import FacesFeedbackControl from './FacesFeedbackControl.vue'
import Face1 from '@/assets/imgs/face_1.svg'
import Face2 from '@/assets/imgs/face_2.svg'
import Face3 from '@/assets/imgs/face_3.svg'
import Face4 from '@/assets/imgs/face_4.svg'
import Face5 from '@/assets/imgs/face_5.svg'
import { FeedbackRatingTypes, RatingResponse } from '@/types'
import { ColorTranslator } from 'colortranslator'

const props = {
  type: String,
  disabled: Boolean,
  value: Number,
  results: {
    type: Array as PropType<RatingResponse[]>,
  },
}
@Component({
  components: {
    ThumbsFeedbackControl,
    VotesFeedbackControl,
    StarsFeedbackControl,
    FacesFeedbackControl,
  },
})
export default class FeedbackControls extends Vue.extend({ props }) {
  hoverValue = 0
  types = FeedbackRatingTypes

  get currentFeedbackControl() {
    switch (this.type) {
      case FeedbackRatingTypes.THUMBS:
        return 'ThumbsFeedbackControl'
      case FeedbackRatingTypes.VOTES:
        return 'VotesFeedbackControl'
      case FeedbackRatingTypes.STARS:
        return 'StarsFeedbackControl'
      case FeedbackRatingTypes.FACES:
        return 'FacesFeedbackControl'
      default:
        return null
    }
  }

  getCount(rating: number) {
    return this.results.find((option) => rating === option.rating)?.count ?? 0
  }

  get isConsolidatedApp() {
    return this.$typedStore.primary.app.isConsolidatedApp
  }

  get originalColours() {
    return this.$typedStore.primary.app.config.appConfig?.originalTabColours ?? false
  }

  get outlineColour() {
    const { activeTab } = this.$typedStore.public.display
    let nodes
    let path
    if (this.isConsolidatedApp && this.originalColours) {
      nodes = this.$typedStore.secondary.entities.nodes
      path = activeTab?.path
    } else {
      nodes = this.$typedStore.primary.entities.nodes
      path = this.isConsolidatedApp ? activeTab?.secondaryAppPath : activeTab?.path
    }
    return nodes.colour(path ?? '') || '#455'
  }

  get fillColour() {
    const hsl = new ColorTranslator(this.outlineColour).HSLAObject
    hsl.L = Math.min(100, hsl.L + 10)
    return new ColorTranslator(hsl).RGBA
  }

  get faces() {
    return [Face1, Face2, Face3, Face4, Face5]
  }

  get current() {
    return this.hoverValue || this.value
  }
}
</script>

<style scoped lang="scss">
.feedback-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .total,
  .blank {
    font-size: 12px;
    font-weight: 700;
    color: #454;
    flex-basis: 50%;
    grid-row: 2;
  }
  .option,
  :deep(.option) {
    grid-row: 1;
  }
  .count {
    grid-row: 2;
  }
  > * {
    display: grid;
    place-items: center;
    gap: 6px 0;
  }
}
.faces {
  gap: 6px;
  &.disabled {
    .option {
      cursor: not-allowed;
      &:hover:not(.selected) {
        transform: none;
      }
    }
  }
  img,
  svg {
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: transform 0.2s;
    &.selected {
      transform: scale(1.3);
    }
    &:hover:not(.selected) {
      transform: scale(1.05);
    }
  }
}
</style>
