<template>
  <node-view-wrapper>
    <div class="rates-impact-table d-flex justify-content-between align-items-start">
      <div class="flex-grow-1">
        <b-table v-if="hasItems" class="rates-impact-table" :items="items" :fields="fields" small>
          <template #cell(groupBy)="data">{{ data.value }}</template>
          <template #cell(count)="data">
            <div class="d-flex align-items-center justify-content-around">
              <div>{{ data.item.count }}</div>
              <HistogramSparkline
                y-axis-name="Submissions"
                :data="data.item.distribution"
                :cols="impacts ? impacts.distributionCols : []"
              />
            </div>
          </template>
          <template #cell()="data"
            ><span title="Average (mean) rates impact for this year across submissions"
              >{{ numericFormatting(data.value) }}%</span
            ></template
          >
        </b-table>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import HistogramSparkline from '@/components/public/Charts/C3/HistogramSparkline.vue'
import _sum from 'lodash/sum'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { getRatesImpactTable } from '@/api'
import { type RatesImpactsResponse, Field } from '@/types'
let uniqueId = 0

const components = {
  NodeViewWrapper,
  HistogramSparkline,
}

@Component({ components })
export default class RatesImpactTable extends Vue.extend({ props: nodeViewProps }) {
  impacts: RatesImpactsResponse | null = null
  componentId = uniqueId++
  mounted() {
    this.getData()
  }

  async getData() {
    this.impacts = await getRatesImpactTable()
  }

  get hasItems() {
    return this.items.length > 0
  }

  get fields(): Field[] {
    return [
      {
        key: 'groupBy',
        label: '',
      },
      {
        key: 'count',
        label: 'Submissions',
      },
      ...(this.impacts?.columns ?? []),
    ]
  }

  get numericFormatting() {
    return this.$typedStore.visualisationApp.numericFormatting
  }

  get items() {
    return (this.impacts?.rows ?? []).map((row) => ({
      ...row,
      count: _sum(row.distribution),
    }))
  }
}
</script>

<style lang="scss">
.rates-impact-table {
  background: white;
  border-radius: var(--border-radius);
  padding: 2em;
  margin-bottom: 16px;
}
</style>
