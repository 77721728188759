import store from '@/store'
import FieldCalculation from '@/store/base/entities/fieldCalculation'
import typedStore from '@/store/typedStore'
import { getModule, Module } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'secondaryFieldCalculation', namespaced: true })
export default class SecondaryFieldCalculation extends FieldCalculation {
  get nodes() {
    return typedStore.secondary.entities.nodes
  }
}

export const SecondaryFieldCalculationModule = getModule(SecondaryFieldCalculation)
