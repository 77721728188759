import { Coordinate2D } from '@/types'

export function coordinatesDelta(one: Coordinate2D, two: Coordinate2D): Coordinate2D {
  return {
    x: one.x - two.x,
    y: one.y - two.y,
  }
}

export function rectCenter(rect: {
  top: number
  left: number
  bottom: number
  right: number
}): Coordinate2D {
  return {
    x: (rect.left + rect.right) / 2,
    y: (rect.top + rect.bottom) / 2,
  }
}
