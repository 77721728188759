// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
  prefix: 'custom',
  iconName: 'nested-bubbles',
  icon: [
    1024,
    1024,
    [],
    'f0000',
    'm331.99 514.17c-85.672 0-155.85 70.182-155.85 155.85s70.182 155.85 155.85 155.85 155.85-70.18 155.85-155.85-70.18-155.85-155.85-155.85zm0 68.031c48.905 0 87.82 38.917 87.82 87.822s-38.915 87.82-87.82 87.82-87.82-38.915-87.82-87.82 38.915-87.822 87.82-87.822zm354.49 17.535c-65.234 0-118.85 53.611-118.85 118.85s53.613 118.85 118.85 118.85 118.85-53.613 118.85-118.85-53.613-118.85-118.85-118.85zm0 68.031c28.467 0 50.814 22.347 50.814 50.814 0 28.467-22.347 50.816-50.814 50.816-28.467 0-50.814-22.349-50.814-50.816 0-28.467 22.347-50.814 50.814-50.814zm-90.568-546.03c-120.59 0-219.07 98.483-219.07 219.07 0 120.59 98.483 219.07 219.07 219.07 120.59 0 219.07-98.483 219.07-219.07 0-120.59-98.483-219.07-219.07-219.07zm0 68.031c83.818 0 151.04 67.219 151.04 151.04 0 83.818-67.219 151.04-151.04 151.04-83.818 0-151.04-67.219-151.04-151.04 0-83.818 67.219-151.04 151.04-151.04zm-83.92-182.74c-133.91 0-262.38 53.211-357.07 147.9-94.691 94.691-147.9 223.16-147.9 357.07s53.211 262.38 147.9 357.07c94.691 94.691 223.16 147.9 357.07 147.9s262.38-53.211 357.07-147.9 147.9-223.16 147.9-357.07-53.211-262.38-147.9-357.07c-94.691-94.691-223.16-147.9-357.07-147.9zm0 68.033c115.9 0 227.01 46.024 308.96 127.97s127.97 193.07 127.97 308.96-46.024 227.01-127.97 308.96-193.07 127.97-308.96 127.97-227.01-46.024-308.96-127.97-127.97-193.07-127.97-308.96 46.024-227.01 127.97-308.96 193.07-127.97 308.96-127.97z',
  ],
}
