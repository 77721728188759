import { DEFAULT_NODE_TYPE, DEFAULT_TAB_SCALE_FACTOR } from '@/constants'
import typedStore from '@/store/typedStore'
import {
  CustomTabNode,
  NodeConfig,
  NodeConfigKey,
  NodeConfigKeys,
  NodeData,
  NodeTypes,
  Path,
} from '@/types'
import { VuexModule } from 'vuex-module-decorators'

export default abstract class Nodes extends VuexModule {
  abstract get allNodes(): NodeData[]
  abstract get removedNodes(): string[]

  // Implementing @Actions on a parent class results in any
  // @Mutations always resolving to 'undefined'.
  // @Actions must be implemented on child classes.
  abstract getNodes(): Promise<void>

  get customTabNodes(): CustomTabNode[] {
    return this.allNodes.filter((x) => x.config?.nodeType === NodeTypes.CUSTOM) as CustomTabNode[]
  }

  get activeCustomTabNode(): CustomTabNode | undefined {
    return this.customTabNodes.find((x) => x.path === typedStore.public.display.activeTab?.path)
  }

  get hiddenNodes() {
    return this.allNodes.filter((node) => node.config?.visible === false)
  }

  get bubblePosition() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.BUBBLE_POSITION] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.BUBBLE_POSITION,
      ) as NodeConfig[typeof NodeConfigKeys.BUBBLE_POSITION]
  }

  get bubblePositions() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.BUBBLE_POSITIONS] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.BUBBLE_POSITIONS,
      ) as NodeConfig[typeof NodeConfigKeys.BUBBLE_POSITIONS]
  }

  get colour() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.COLOUR] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.COLOUR,
      ) as NodeConfig[typeof NodeConfigKeys.COLOUR]
  }

  get headerPosition() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.HEADER_POSITION] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.HEADER_POSITION,
      ) as NodeConfig[typeof NodeConfigKeys.HEADER_POSITION]
  }

  get headerPositions() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.HEADER_POSITIONS] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.HEADER_POSITIONS,
      ) as NodeConfig[typeof NodeConfigKeys.HEADER_POSITIONS]
  }

  get icon() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.ICON] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.ICON,
      ) as NodeConfig[typeof NodeConfigKeys.ICON]
  }

  get label() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.LABEL] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.LABEL,
      ) as NodeConfig[typeof NodeConfigKeys.LABEL]
  }

  get order() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.ORDER] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.ORDER,
      ) as NodeConfig[typeof NodeConfigKeys.ORDER]
  }

  get name() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.NAME] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.NAME,
      ) as NodeConfig[typeof NodeConfigKeys.NAME]
  }

  get fontColour() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.FONT_COLOUR] =>
      this.nodeGetter(
        path.toString(),
        NodeConfigKeys.FONT_COLOUR,
      ) as NodeConfig[typeof NodeConfigKeys.FONT_COLOUR]
  }

  get scaleFactor() {
    return (path: string | Path) =>
      (this.nodeGetter(
        path.toString(),
        NodeConfigKeys.SCALE_FACTOR,
      ) as NodeConfig[typeof NodeConfigKeys.SCALE_FACTOR]) || DEFAULT_TAB_SCALE_FACTOR
  }

  get visible() {
    return (path: string | Path): NodeConfig[typeof NodeConfigKeys.VISIBLE] =>
      (this.nodeGetter(
        path.toString(),
        NodeConfigKeys.VISIBLE,
      ) as NodeConfig[typeof NodeConfigKeys.VISIBLE]) ?? true
  }

  get nodeType() {
    return (path: string | Path) =>
      (this.nodeGetter(
        path.toString(),
        NodeConfigKeys.NODE_TYPE,
      ) as NodeConfig[typeof NodeConfigKeys.NODE_TYPE]) || DEFAULT_NODE_TYPE
  }

  get nodeGetter() {
    return (path: string, key: NodeConfigKey) => {
      const node = this.allNodes.find((n) => n.path === path)
      if (node && node.config) {
        return node.config[key]
      } else {
        return undefined
      }
    }
  }
}
