import store from '@/store'
import typedStore from '@/store/typedStore'
import { AppStatuses } from '@/types'
import { getModule, Module, VuexModule } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'userPermission', namespaced: true })
export default class UserPermission extends VuexModule {
  get read() {
    return Boolean(typedStore.primary.app.app.read)
  }

  get write() {
    return Boolean(typedStore.primary.app.app.write)
  }

  get publish() {
    return Boolean(typedStore.primary.app.app.publish)
  }

  get internal() {
    return Boolean(typedStore.primary.app.app.internal)
  }

  get appDisabled() {
    return Boolean(typedStore.primary.app.app.status === AppStatuses.DISABLED)
  }

  get appInitial() {
    return Boolean(typedStore.primary.app.app.status === AppStatuses.INITIAL)
  }

  get appPending() {
    return Boolean(typedStore.primary.app.app.status === AppStatuses.PENDING)
  }

  get appLive() {
    return Boolean(typedStore.primary.app.app.status === AppStatuses.LIVE)
  }

  get appInternal() {
    return Boolean(typedStore.primary.app.app.status === AppStatuses.INTERNAL)
  }

  get readOnly() {
    return this.read && !this.write && !this.publish
  }

  get builder() {
    return this.write && !typedStore.primary.app.isCurrentPublishedVersion
  }
}

export const UserPermissionModule = getModule(UserPermission)
