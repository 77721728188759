import { Bubble, BubbleConfig } from '@/components/public/Visualisations/Bubbles'
import { Path, VisNode } from '@/types'

export class Cluster {
  path: string
  nodes: VisNode[]
  bubbleConfig: BubbleConfig
  activeStoryPath: Path
  centerX?: number
  centerY?: number

  constructor(
    path: string,
    visNodes: VisNode[],
    bubbleConfig: BubbleConfig,
    activeStoryPath: Path,
  ) {
    this.path = path
    this.nodes = visNodes
    this.bubbleConfig = bubbleConfig
    this.activeStoryPath = activeStoryPath
  }

  within(leftPositionLimit: number, rightPositionLimit: number) {
    return this.centerX !== undefined &&
      this.centerX > leftPositionLimit &&
      this.centerX < rightPositionLimit &&
      this.centerY !== undefined &&
      this.centerY > leftPositionLimit &&
      this.centerY < rightPositionLimit
  }

  get bubbles() {
    return this.nodes.map((node) => new Bubble(node, this.bubbleConfig, this.activeStoryPath))
  }

  get diameter() {
    // The area of the all the bubbles in cluster should make the area of the cluster
    // Get the list of the areas of the bubbles in cluster
    // π * radius ^ 2 is the formula to calculate the area of the circle
    const bubblesAreas = this.bubbles.map(
      (bubble) => Math.PI * Math.pow(bubble.displacementRadius, 2),
    )
    const totalBubblesArea = bubblesAreas.reduce(
      (totalArea, bubbleArea) => totalArea + bubbleArea,
      0,
    )
    // Calculates the diameter of the cluster
    return 2 * Math.sqrt(totalBubblesArea / Math.PI)
  }
}
