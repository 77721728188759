import { getAllImages, getImage, postImage } from '@/api'
import logger from '@/plugins/logging/logger'
import store from '@/store'
import App from '@/store/base/app'
import Images, {
  configPathType,
  CONFIG_PATH_IS_CONFIG,
  CONFIG_PATH_IS_NODE,
  IImagesState,
} from '@/store/base/entities/images'
import Nodes from '@/store/base/entities/nodes'
import typedStore from '@/store/typedStore'
import { AppRank, AppRanks, ImagePreview } from '@/types'
import Vue from 'vue'
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators'

export interface IPrimaryImagesState extends IImagesState {
  imageUploading: Boolean
  previews: ImagePreview
  secondaryAppsIcons: Record<number, string>
}

@Module({ dynamic: true, store, name: 'primaryImages', namespaced: true })
export default class PrimaryImages extends Images implements IPrimaryImagesState {
  imageUploading = false
  previews: ImagePreview = {}
  secondaryAppsIcons: Record<number, string> = {}

  get appRank(): AppRank {
    return AppRanks.PRIMARY
  }

  get app(): App {
    return typedStore.primary.app
  }

  get nodes(): Nodes {
    return typedStore.primary.entities.nodes
  }

  @Mutation
  setNewImage(payload: { key: number; value: string }) {
    this.allImages[payload.key] = payload.value
  }

  @Mutation
  setImageUploading(payload: boolean) {
    this.imageUploading = payload
  }

  @Mutation
  addPreviewImage(payload: { configPath: string[]; file: File }) {
    const key = payload.configPath.join('/')
    Vue.set(this.previews, key, {
      url: URL.createObjectURL(payload.file),
      ...payload,
    })
  }

  @Mutation
  removePreviewImage(payload: { configPath: string[] }) {
    const key = payload.configPath.join('/')
    if (key in this.previews) {
      const url = this.previews[key].url
      URL.revokeObjectURL(url)
      Vue.delete(this.previews, key)
    }
  }

  get isImageUploading() {
    return this.imageUploading
  }

  get imagesNeedUpload() {
    return Object.keys(this.previews).length > 0
  }

  get imageOrPreview() {
    return (configPath: string[]) => {
      const imagePreview = this.previewUrl(configPath)
      const imageId = this.imageId(configPath)
      const src = imageId && this.imageUrl(imageId)
      return imagePreview || src
    }
  }

  get imagePreview() {
    return (configPath: string[]) => {
      const key = configPath.join('/')
      return key in this.previews && this.previews[key]
    }
  }

  get previewUrl() {
    return (configPath: string[]) => {
      const imagePreview = this.imagePreview(configPath)
      return imagePreview && imagePreview.url
    }
  }

  get previewFile() {
    return (configPath: string[]) => {
      const imagePreview = this.imagePreview(configPath)
      return imagePreview && imagePreview.file
    }
  }

  @Action({ rawError: true })
  async getAllImages() {
    this.setAllImages(await getAllImages(this.appRank))
  }

  @Action({ rawError: true })
  async saveAllImages() {
    return Promise.all(Object.keys(this.previews).map((key) => this.saveImage(key.split('/'))))
  }

  @Action({ rawError: true })
  async saveImage(configPath: string[]) {
    const file = this.previewFile(configPath)
    if (file) {
      const resp = await postImage(file)
      const id = resp.img
      this.updateImageConfig({ configPath, id })
      await this.getAllImages()
      this.removePreviewImage({ configPath })
    } else {
      throw new Error('Preview image not found')
    }
  }

  @Action({ rawError: true })
  removeExistingImage(configPath: string[]) {
    this.updateImageConfig({ configPath, id: null })
  }

  @Action({ rawError: true })
  clearPreviews() {
    Object.keys(this.previews).forEach((key) => {
      const configPath = key.split('/')
      // removePreviewImage expects config path not key
      this.removePreviewImage({ configPath })
    })
  }

  @Action({ rawError: true })
  async updateImageConfig(payload: { configPath: string[]; id: number | null }) {
    const { configPath, id } = payload
    const type = configPathType(configPath)
    if (type === CONFIG_PATH_IS_NODE) {
      const storyPath = configPath.slice(1, -1).join('/')
      const propName = configPath[configPath.length - 1]
      await typedStore.primary.entities.nodes.updateNodeProp({
        path: storyPath,
        configPath: ['config', propName],
        value: { img: id },
      })
    } else if (type === CONFIG_PATH_IS_CONFIG) {
      typedStore.primary.app.updateConfig({
        configPath: configPath.slice(1),
        value: { img: id },
      })
    } else {
      logger.error('Invalid configPath for saveImage')
    }
  }

  get secondaryAppIcon(): (iconId: number) => string | undefined {
    return (iconId: number) => this.secondaryAppsIcons[iconId]
  }

  @Mutation
  updateSecondaryAppIcons(payload: { iconId: number; imageUrl: string }) {
    Vue.set(this.secondaryAppsIcons, payload.iconId, payload.imageUrl)
  }

  @Action({ rawError: true })
  async getIconsForAccessibleSecondaryApps() {
    await Promise.all(
      typedStore.primary.entities.nodes.accessibleSecondaryAppReferenceNodes.map((node) => {
        if (node.config.secondaryAppId) {
          return this.getSecondaryAppIcons(node.config.secondaryAppId)
        }
        return Promise.resolve()
      }),
    )
  }

  @Action({ rawError: true })
  async getSecondaryAppIcons(appId: number) {
    const secondaryAppIcons = this.secondaryAppsIcons[appId]
    if (!secondaryAppIcons) {
      try {
        const secondaryAppTabNodes = typedStore.primary.entities.nodes.secondaryAppTabNodes(appId)
        if (secondaryAppTabNodes) {
          const secondaryAppTabIconsIds = secondaryAppTabNodes
            .map((node) => node.config?.icon?.img)
            .filter((x): x is number => Boolean(x))
          secondaryAppTabIconsIds.forEach(async (iconId) => {
            const imageUrl = await getImage(iconId)
            this.updateSecondaryAppIcons({ iconId, imageUrl })
          })
        }
      } catch {
        // TODO: Actual error handling
        logger.error(`error retrieving secondary app icons for app: ${appId}`)
      }
    }
  }
}

export const PrimaryImagesModule = getModule(PrimaryImages)
