import { SERVER as serverURL } from '@/constants'
import { HttpError } from '@/errors/index'
import typedStore from '@/store/typedStore'
import { AppRank, AppRanks, SuccessResponse } from '@/types'
import { getInstance } from '@orbica/vue-modules'
import Bottleneck from 'bottleneck'

const limiter = new Bottleneck({
  maxConcurrent: 10, // as of writing this, most browsers can only handle 6 concurrent requests to a single domain
})

export interface HttpResponse<T> extends Response {
  parsedBody?: T
}

async function generateHeaders({
  contentType = 'application/json',
  includeAuth = true,
  responseKeyCase = 'camel',
}: {
  contentType?: string
  includeAuth?: boolean
  responseKeyCase?: string
} = {}) {
  const auth0 = getInstance()
  const accessToken =
    includeAuth && auth0 && auth0.isAuthenticated ? await auth0.getTokenSilently() : false
  const hostname = window.location.hostname
  const headers: {
    'Content-Type': string
    'Response-Key-Case': string
    authorization?: string
    hostname?: string
  } = {
    'Content-Type': contentType,
    'Response-Key-Case': responseKeyCase, // request that any JSON keys in the response are in the provided case
    hostname,
  }
  // logged in users have their version defined
  if (accessToken) {
    headers.authorization = `JWT ${accessToken}`
  }
  return new Headers(headers)
}

export async function authPost({
  path,
  body,
  appRank = AppRanks.PRIMARY,
  params = {},
}: {
  path: string
  body: object
  appRank?: AppRank
  params?: object
}) {
  const headers = await generateHeaders()
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  }
  return limiter.schedule(() => fetch(buildUrl(path, params, appRank), options))
}

export async function authPut(path: string, body: object, params = {}) {
  const headers = await generateHeaders()
  const options = {
    headers,
    method: 'PUT',
    body: JSON.stringify(body),
  }
  return limiter.schedule(() => fetch(buildUrl(path, params), options))
}

export async function authDelete(path: string, body: object, params = {}) {
  const headers = await generateHeaders()
  const options = {
    headers,
    method: 'DELETE',
    body: JSON.stringify(body),
  }
  return limiter.schedule(() => fetch(buildUrl(path, params), options))
}

export async function authGet(
  path: string,
  params = {},
  appRank: AppRank = AppRanks.PRIMARY,
  includeCamelCaseHeader = true,
) {
  const responseKeyCase = includeCamelCaseHeader ? 'camel' : ''
  const headers = await generateHeaders({ responseKeyCase })
  const options = {
    headers,
    method: 'GET',
  }
  return limiter.schedule(() => fetch(buildUrl(path, params, appRank), options))
}

export async function authPostImg(path: string, file: File, params = {}) {
  const headers = await generateHeaders({ contentType: file.type })
  const options = {
    headers,
    method: 'POST',
    body: file,
  }
  return limiter.schedule(() => fetch(buildUrl(path, params), options))
}

export async function awaitRequestJson<T>(promise: Promise<HttpResponse<T>>): Promise<T> {
  const res = await promise
  if (res.ok) {
    return res.json()
  } else {
    throw new HttpError(res)
  }
}

export async function awaitRequest(
  promise: Promise<HttpResponse<SuccessResponse>>,
): Promise<SuccessResponse> {
  const res = await promise
  if (res.ok) {
    return 'Success'
  } else {
    throw new HttpError(res)
  }
}

export async function awaitRequestJsonOrNull<T>(
  promise: Promise<HttpResponse<T>>,
): Promise<T | null> {
  const res = await promise
  if (res.ok) {
    if (res.status === 204) {
      return null
    } else {
      return res.json()
    }
  } else {
    throw new HttpError(res)
  }
}

export async function awaitRequestBlob(promise: Promise<HttpResponse<Blob>>): Promise<Blob> {
  const res = await promise
  if (res.ok) {
    return res.blob()
  } else {
    throw new HttpError(res)
  }
}

function buildUrl(path: string, params: object, appRank: AppRank = AppRanks.PRIMARY) {
  const url = new URL(`${serverURL}/${path}`)
  const orgSlug = typedStore.currentRoute.orgSlug

  let appId
  let appSlug
  let versionId
  if (appRank === AppRanks.PRIMARY) {
    appId = typedStore.primary.app.appId
    appSlug = typedStore.primary.app.appSlug
    versionId = typedStore.primary.app.versionId
  } else if (appRank === AppRanks.SECONDARY) {
    appId = typedStore.secondary.app.currentAppId
  }

  params = {
    ...(appId ? { appId } : {}),
    ...(versionId ? { versionId } : {}),
    ...(orgSlug ? { orgSlug } : {}),
    ...(appSlug ? { appSlug } : {}),
    ...params,
  }
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => url.searchParams.append(key, v))
    } else {
      url.searchParams.set(key, value)
    }
  })
  return url.toString()
}
