import { ROUTE_INITIAL } from '@/constants'
import router from '@/router'
import { authPlugin, AuthServiceOptions } from '@orbica/vue-modules'
import Vue from 'vue'

const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
const AUTH0_AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE
const BUBBLES_URL = process.env.VUE_APP_BUBBLES_URL

if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID || !AUTH0_AUDIENCE || !BUBBLES_URL) {
  throw new Error('No Auth0 options provided')
}

const authOptions: AuthServiceOptions = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  redirectUri: `${BUBBLES_URL}/callback`,
  onRedirectCallback: (appState) =>
    router.push(appState && appState.targetUrl ? appState.targetUrl : { name: ROUTE_INITIAL }),
}

Vue.use(authPlugin, authOptions)
