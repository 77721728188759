<template>
  <node-view-wrapper>
    <div class="d-flex justify-content-between align-items-start">
      <div class="flex-grow-1">
        <b-table
          v-if="hasItems"
          class="index-table"
          :items="items"
          :fields="fields"
          small
          hover
          tbody-tr-class="cursor-pointer"
          sort-by="name"
          @row-clicked="updatePath"
        />
      </div>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { type Field } from '@/types'

interface Item {
  name: string
  path: string
}

const components = {
  NodeViewWrapper,
}

@Component({ components })
export default class IndexTable extends Vue.extend({ props: nodeViewProps }) {
  get hasItems() {
    return this.items.length > 0
  }

  get isRootOrSubroot() {
    return this.storyPath.isRootOrSubroot
  }

  get storyPath() {
    return this.$typedStore.activeVisualisation.storyPath
  }

  get projectLabelField() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config.appConfig
      .projectLabelField
  }

  get labelName() {
    const { activeVisualisation } = this.$typedStore
    const {
      visualisationStore: { app },
    } = activeVisualisation
    const {
      levels: levelsHelper,
      config: { fieldMap },
    } = app

    const labelLevel = this.storyPath.level + 1
    const labelField =
      labelLevel === levelsHelper.maxLevel
        ? fieldMap.find((f) => f.column === this.projectLabelField)
        : null

    return labelField?.name || levelsHelper.fieldForLevel(labelLevel)?.name || ''
  }

  get fields(): Field[] {
    return [
      {
        key: 'name',
        label: this.labelName,
      },
    ]
  }

  get items(): Item[] {
    if (this.isRootOrSubroot) {
      return this.rootItems
    } else {
      return this.nonRootItems
    }
  }

  get rootItems(): Item[] {
    return this.$typedStore.visualisationData.sortedRootChildren.map((node) => {
      const name = this.$typedStore.activeVisualisation.visualisationStore.entities.nodes.name(
        node.path,
      )
      const label = this.$typedStore.activeVisualisation.visualisationStore.entities.nodes.label(
        node.path,
      )
      return { name: label ?? name, path: node.path.toString() }
    })
  }

  get nonRootItems(): Item[] {
    return this.$typedStore.visualisationData.childrenByPath(this.storyPath).map((node) => {
      const projectLabel = node.data?.[this.projectLabelField] || node.name || ''
      return { name: projectLabel, path: node.path.toString() }
    })
  }

  async updatePath(item: Item) {
    if (item.path) this.$typedStore.activeVisualisation.updateStoryPath(item.path)
  }
}
</script>

<style>
table.table-hover tbody tr:hover {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.index-table {
  color: inherit;
}
</style>
