// TODO redefining this here prevents circular deps which were breaking the tests. Fix those deps.
const PATH_ROOT = 'root'
const PATH_SUBROOT = 'subroot'
const PATH_SEPARATOR = '/'

export class Path {
  parts: string[]
  private static _subroot = new Path({ path: 'subroot' })
  private static _root = new Path({ path: 'root' })

  constructor(args: { path?: string; parts?: string[] }) {
    this.parts = args.parts ?? args.path?.split(PATH_SEPARATOR) ?? []
  }

  get name() {
    return this.parts[this.parts.length - 1]
  }

  get level1() {
    return this.parts[0]
  }

  ancestor(level: number) {
    return this.parts.slice(0, level).join(PATH_SEPARATOR)
  }

  ancestorPath(level: number) {
    return new Path({ parts: this.parts.slice(0, level) })
  }

  // Checks arrays by value up to the length of parts1
  private partsMatch(parts1: string[], parts2: string[]): boolean {
    return !parts1.some((part, i) => part !== parts2[i])
  }

  sharesAncestorAtLevel(other: Path, level: number) {
    return (
      level <= this.level &&
      level <= other.level &&
      this.partsMatch(this.parts.slice(0, level), other.parts)
    )
  }

  isAncestorOf(other: Path) {
    return this.level < other.level && this.partsMatch(this.parts, other.parts)
  }

  isDescendantOf(other: Path) {
    return other.isAncestorOf(this)
  }

  get partPaths() {
    return this.parts.map(
      (_: string, index: number, items: string[]) => new Path({ parts: items.slice(0, index + 1) }),
    )
  }

  get isRootOrSubroot() {
    return this.parts[0] === PATH_ROOT || this.parts[0] === PATH_SUBROOT
  }

  get isRoot() {
    return this.parts[0] === PATH_ROOT
  }

  get isSubroot() {
    return this.parts[0] === PATH_SUBROOT
  }

  get level(): number {
    return this.isRootOrSubroot ? 0 : this.parts.length
  }

  get parent(): string {
    return this.partPaths.slice(0, -1).join(PATH_SEPARATOR)
  }

  equals(other: Path) {
    return this.toString() === other.toString()
  }

  toString() {
    return this.parts.join(PATH_SEPARATOR)
  }

  static get subroot() {
    return Path._subroot
  }

  static get root() {
    return Path._root
  }
}
