import { settingsService } from '@/services'
import moment from 'moment'
import { CallerInfo, LoggerOptions, LogLevel, StringifyObjectsHook } from 'vue-logger-plugin'
import { StoreLoggerHook } from './storeLoggerHook'

export const loggerPrefixFormat = ({
  level,
  caller,
}: {
  level: LogLevel
  caller: CallerInfo | undefined
}) => {
  const defaultPrefix = `[${moment().format('HH:mm:ss.SSS')}] [${level.toUpperCase()}]`
  return caller
    ? `${defaultPrefix} [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]`
    : defaultPrefix
}

export default {
  level: settingsService.isProd ? 'error' : 'debug',
  prefixFormat: loggerPrefixFormat,
  beforeHooks: [StringifyObjectsHook],
  afterHooks: [StoreLoggerHook],
} as LoggerOptions
