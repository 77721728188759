import Vue from 'vue'

const logger = {
  debug: (message: string, ...args: any[]) => Vue.prototype.$log.debug(message, ...args),
  info: (message: string, ...args: any[]) => Vue.prototype.$log.info(message, ...args),
  warn: (message: string, ...args: any[]) => Vue.prototype.$log.warn(message, ...args),
  error: (message: string, ...args: any[]) => Vue.prototype.$log.error(message, ...args),
}

export default logger
