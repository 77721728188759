import BubblesLegendsNodeView from '@/components/TipTap/extensions/BubblesLegendsNodeView.vue'
import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    BubblesLegends: {
      /**
       * Insert bubbles legends node
       */
      setLegends: () => ReturnType
    }
  }
}

export default Node.create({
  name: 'BubblesLegends',
  group: 'block',
  draggable: true,

  addCommands() {
    return {
      setLegends:
        () =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create()
          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }
          return true
        },
    }
  },

  parseHTML() {
    return [{ tag: 'BubblesLegends' }, { tag: 'bubbles-legends' }]
  },

  renderHTML() {
    return ['BubblesLegends']
  },

  addNodeView() {
    return VueNodeViewRenderer(BubblesLegendsNodeView)
  },
})
