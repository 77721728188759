import { authGet, authPost, awaitRequestJson } from '@/api/helpers'
import {
  AppIdParam,
  AppRank,
  PostConfigResponse,
  VersionConfigResponse,
  VersionIdParam,
} from '@/types'

export function getConfigByAppRank(appRank: AppRank) {
  return getConfig({}, appRank)
}

export function getConfigByAppId(params: AppIdParam, appRank: AppRank) {
  return getConfig(params, appRank)
}

function getConfig(params: {}, appRank?: AppRank) {
  return awaitRequestJson<VersionConfigResponse>(authGet('configs', params, appRank))
}

export function getConfigsByVersion(params: VersionIdParam) {
  return awaitRequestJson<VersionConfigResponse>(authGet('configs', params))
}

export type PostConfigPayload = keyof Required<VersionConfigResponse>

export function postConfig(payload: { key: PostConfigPayload; value: object | boolean }) {
  return awaitRequestJson<PostConfigResponse>(authPost({ path: 'config', body: payload }))
}
