import { BubbleConfig, Cluster, Header } from '@/components/public/Visualisations/Bubbles'
import { VISUALISATION_VIEWBOX_POSITION_OFFSET, VISUALISATION_VIEWBOX_SIZE } from '@/constants'
import { Path, VisNode } from '@/types'

export const SortClustersByOptions = {
  ALPHABETICAL: 'alphabetical',
  REVERSE_ALPHABETICAL: 'reverse-alphabetical',
  LARGEST_TO_SMALLEST: 'largest-to-smallest',
  SMALLEST_TO_LARGEST: 'smallest-to-largest',
} as const

export type SortClustersByOption =
  (typeof SortClustersByOptions)[keyof typeof SortClustersByOptions]

export class ClusterAndHeaderBox {
  path: string
  header: Header
  cluster: Cluster

  constructor(
    path: string,
    visNodes: VisNode[],
    bubbleConfig: BubbleConfig,
    activeStoryPath: Path,
  ) {
    this.path = path
    this.header = new Header(path)
    this.cluster = new Cluster(path, visNodes, bubbleConfig, activeStoryPath)
  }

  get height() {
    return this.cluster.diameter + this.header.height
  }

  get width() {
    return this.cluster.diameter > this.header.width ? this.cluster.diameter : this.header.width
  }
}

export class ClusterAndHeaderBoxCollection implements Iterable<ClusterAndHeaderBox> {
  boxes: ClusterAndHeaderBox[]

  constructor(boxes: ClusterAndHeaderBox[]) {
    this.boxes = boxes
  }

  [Symbol.iterator](): Iterator<ClusterAndHeaderBox> {
    return this.boxes.values()
  }

  sort(sortBy: SortClustersByOption) {
    switch (sortBy) {
      case SortClustersByOptions.ALPHABETICAL:
        this.boxes = this.sortByPathCaseInsensitive()
        break
      case SortClustersByOptions.REVERSE_ALPHABETICAL:
        this.boxes = this.sortByPathCaseInsensitive().reverse()
        break
      case SortClustersByOptions.SMALLEST_TO_LARGEST:
        this.boxes = this.sortByClusterDiameter().reverse()
        break
      case SortClustersByOptions.LARGEST_TO_SMALLEST:
        this.boxes = this.sortByClusterDiameter()
        break
      default:
        this.boxes = this.sortByClusterDiameter()
        break
    }
  }

  calculateAutoLayoutPositions() {
    const rows: ClusterAndHeaderBox[][] = []
    let currentRow: ClusterAndHeaderBox[] = []
    let currentRowWidth = 0

    for (const box of this.boxes) {
      if (currentRowWidth + box.width > VISUALISATION_VIEWBOX_SIZE) {
        rows.push(currentRow)
        currentRow = [box]
        currentRowWidth = box.width
      } else {
        currentRow.push(box)
        currentRowWidth += box.width
      }
    }
    rows.push(currentRow)

    // Take the largest box of the row as the row's height
    const totalHeight = rows.reduce((sum, row) => {
      const maxHeight = Math.max(...row.map((box) => box.height))
      return sum + maxHeight
    }, 0)
    const verticalSpaceLeft = VISUALISATION_VIEWBOX_SIZE - totalHeight
    // If there's no vertical space left, put no padding
    const verticalPadding = Math.max(verticalSpaceLeft / (rows.length + 1), 0)

    let y = verticalPadding

    for (const row of rows) {
      const totalRowWidth = row.reduce((sum, box) => sum + box.width, 0)
      const horizontalSpaceLeft = VISUALISATION_VIEWBOX_SIZE - totalRowWidth
      const horizontalPadding = Math.max(horizontalSpaceLeft / (row.length + 1), 0)
      const maxHeight = Math.max(...row.map((box) => box.height))

      let x = horizontalPadding

      for (const box of row) {
        const coordinateX = x + box.width / 2 + VISUALISATION_VIEWBOX_POSITION_OFFSET

        const clusterCenterX = coordinateX
        const clusterCenterY =
          y + maxHeight / 2 + VISUALISATION_VIEWBOX_POSITION_OFFSET + box.header.height
        const headerCenterX = coordinateX
        const headerCenterY = y + VISUALISATION_VIEWBOX_POSITION_OFFSET

        // positions the cluster's center
        box.cluster.centerX = clusterCenterX
        box.cluster.centerY = clusterCenterY
        // position the header at the top of the cluster
        box.header.centerX = headerCenterX
        box.header.centerY = headerCenterY

        x += box.width + horizontalPadding // Set starting position of next box
      }

      y += maxHeight + verticalPadding
    }
  }

  private sortByClusterDiameter() {
    return this.boxes.sort((a, b) => b.cluster.diameter - a.cluster.diameter)
  }

  private sortByPathCaseInsensitive() {
    return this.boxes.sort((a, b) =>
      a.path.localeCompare(b.path, undefined, { sensitivity: 'base' }),
    )
  }
}
