import { GtmOptions, OrbVizAnalyticsPlugin } from '@/plugins/analytics'
import Vue from 'vue'

const GTM_CONTAINER_ID = 'GTM-KSF56D4'

const APP_TYPE = process.env.VUE_APP_TYPE

const envOptions: Record<string, GtmOptions> = {
  production: {
    id: GTM_CONTAINER_ID,
    environment: {
      auth: 'pzWAvE9glu_pWgl9uNwrmw',
      preview: 'env-12',
    },
  },
  staging: {
    id: GTM_CONTAINER_ID,
    environment: {
      auth: '5ntT-STPjObA10b4RGfwnA',
      preview: 'env-11',
    },
  },

  test: {
    id: GTM_CONTAINER_ID,
    environment: {
      auth: 'aKEGNXsTDlV7yiF889fqGQ',
      preview: 'env-10',
    },
  },
  // Same as test
  development: {
    id: GTM_CONTAINER_ID,
    environment: {
      auth: 'aKEGNXsTDlV7yiF889fqGQ',
      preview: 'env-10',
    },
  },
}

const options = envOptions[APP_TYPE || 'development']

Vue.use(OrbVizAnalyticsPlugin, options)
