import {
  App,
  AppUserPermission,
  AwsMarketplaceCustomer,
  Field,
  NodeData,
  Organisation,
  Version,
} from '.'

export type AppIdParam = { appId: App['id'] }

export type VersionIdParam = { versionId: Version['id'] }

export type OrganisationIdParam = { orgId: Organisation['id'] }

/** App details including permissions for the current user */
interface AppResponseItem {
  id: App['id']
  uuid: App['uuid']
  name: App['name']
  hostname: App['hostname']
  status: App['status']
  read: AppUserPermission['read'] | null
  write: AppUserPermission['write'] | null
  publish: AppUserPermission['publish'] | null
  internal: boolean
  created: App['created']
  modified: App['modified']
  orgId: Organisation['id']
  orgSlug: Organisation['slug'] | null
  appSlug: App['slug'] | null
  appType: App['appType']
  currentVersionId: Version['id'] | null
  ga4MeasurementIds: Organisation['ga4MeasurementIds']
}

export type AppResponse = AppResponseItem

export type SuccessResponse = 'Success'

export interface RatingResponse {
  rating: number
  count: number
}

export type ImagesSource = {
  [id: number]: string
}

export interface PostImageResponse {
  img: number
  url: string
}

export interface NodeResponse extends NodeData {}

export interface AwsMarketplaceCustomerResponse {
  customerId: AwsMarketplaceCustomer['id']
  dimension: AwsMarketplaceCustomer['dimension']
  expirationDate: AwsMarketplaceCustomer['expirationDate']
  productCode: AwsMarketplaceCustomer['productCode']
  subscriptionStatus: AwsMarketplaceCustomer['status']
}

export interface OrganisationResponse {
  name: Organisation['name']
  contact: Organisation['contactId']
  id: Organisation['id']
  selfRegistrationEmailDomains: Organisation['selfRegistrationEmailDomains']
  awsMarketplaceCustomer: AwsMarketplaceCustomerResponse | null
}

export interface OrganisationAppResponse {
  id: App['id']
  organisation: App['organisation']['id']
  name: App['name']
  currentVersion: App['currentVersion'] | null
  hostname: App['hostname'] | null
  status: App['status']
}

export type RecordItem = {
  dataid: number
  value: string
}

export interface RecordResponse {
  recordid: number
  [key: string]: number | RecordItem
}

export interface GeomDataResponse {
  results: Record<string, Record<string, number>>
  counts: Record<string, number>
}

export interface PostCsvPayload {
  rows: Record<string, string>[]
}

export interface VersionItem {
  name: Version['name']
  id: Version['id']
  created: Version['created']
  modified: Version['modified']
  showMap: boolean
}

export type PostConfigResponse = 'Success - config created' | 'Success - config updated'

export interface FeedbackItem {
  path: string
  comment: string
  rating: number | null
  answer: string | null
}

export interface Feedback {
  feedback: FeedbackItem[]
  feedbackQuestionId: string
}

export interface RatesImpactsResponse {
  columns: Field[]
  rows: any[]
  distributionCols: string[]
}

export enum AppRelationshipType {
  Report = 'Report',
}

export interface AppRelationship {
  appId: App['id']
  type: AppRelationshipType
}

export type AppRelationshipsResponse = AppRelationship[]
