import InternalLink from '@/components/TipTap/extensions/InternalLink/InternalLink.vue'
import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

export interface InternalLinkOptions {
  link: string
  title: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    InternalLink: {
      /**
       * Insert internal link node
       */
      setInternalLink: (options: InternalLinkOptions) => ReturnType
    }
  }
}

export default Node.create({
  name: 'InternalLink',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      link: {
        parseHTML: (element) => element.getAttribute('link'),
        renderHTML: (attributes) => ({ 'link': attributes.link }),
      },
      title: {
        parseHTML: (element) => element.getAttribute('title'),
        renderHTML: (attributes) => ({ 'title': attributes.title })
      },
    }
  },

  addCommands() {
    return {
      setInternalLink:
        (attrs: InternalLinkOptions) =>
          ({ tr, dispatch }) => {
            const { selection } = tr
            const node = this.type.create(attrs)
            if (dispatch) {
              tr.replaceRangeWith(selection.from, selection.to, node)
            }
            return true
          },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['InternalLink', HTMLAttributes]
  },

  parseHTML() {
    return [{ tag: 'InternalLink' }]
  },

  addNodeView() {
    return VueNodeViewRenderer(InternalLink)
  },
})
