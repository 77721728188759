<template>
  <node-view-wrapper>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe
        v-show="iframeLoaded"
        class="embed-responsive-item"
        :src="src"
        allowfullscreen
        @load="iframeLoaded = true"
      />
      <div
        v-if="!iframeLoaded"
        class="embed-responsive-item d-flex justify-content-center align-items-center"
      >
        <b-spinner
          label="Loading"
          variant="info"
          type="grow"
          style="width: min(100px, 50vw, 50vh); height: min(100px, 50vw, 50vh)"
        />
      </div>
    </div>

    <b-input-group v-if="editor.view.editable" prepend="URL">
      <b-input v-model="src" type="text" @paste.stop />
    </b-input-group>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { convertYoutubeUrl } from './helpers'

const components = {
  NodeViewWrapper,
}

@Component({ components })
export default class IframeNodeView extends Vue.extend({ props: nodeViewProps }) {
  iframeLoaded = false

  get src() {
    return this.node.attrs.src
  }

  set src(src) {
    this.iframeLoaded = false
    this.updateAttributes({
      src: convertYoutubeUrl(src),
    })
  }
}
</script>
