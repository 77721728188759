<template>
  <b-table-lite
    :fields="tableFields"
    :items="tableItems"
    thead-class="d-none"
    borderless
    small
    class="d-flex flex-nowrap"
  >
    <template #cell(bubble)="data">
      <b-row no-gutters>
        <b-col
          v-if="haloExist || displayHollowBubbles"
          :class="{ 'pr-2': data.item.showHalo && data.item.showPulse }"
        >
          <b-iconstack v-if="data.item.showHalo || data.item.showSolid || data.item.showHollow">
            <b-icon
              v-if="data.item.showHalo"
              stacked
              scale="1.5"
              icon="circle"
              :style="{
                color: data.item.strokeColor,
                '--bubble-halo-stroke-color': data.item.strokeColor,
              }"
              class="halo-bubble"
            />
            <b-icon
              v-if="data.item.showSolid || data.item.showHalo"
              stacked
              icon="circle-fill"
              :style="{ color: legendBubbleColor, '--legend-bubble-color': legendBubbleColor }"
              class="solid-bubble"
            />
            <b-icon
              v-if="data.item.showHollow"
              stacked
              icon="circle"
              :style="{ color: legendBubbleColor, '--legend-bubble-color': legendBubbleColor }"
              class="hollow-bubble"
            />
          </b-iconstack>
        </b-col>
        <b-col v-if="pulseExist" :class="{ 'pl-2': data.item.showHalo && data.item.showPulse }">
          <b-iconstack v-if="data.item.showPulse">
            <b-icon
              stacked
              scale="1.5"
              icon="circle-fill"
              animation="throb"
              :style="{
                color: data.item.pulsingStrokeColor,
                '--bubble-pulsing-stroke-color': data.item.pulsingStrokeColor,
              }"
              class="pulsing-bubble"
            />
            <b-icon
              stacked
              icon="circle-fill"
              :style="{ color: legendBubbleColor, '--legend-bubble-color': legendBubbleColor }"
              class="solid-bubble"
            />
          </b-iconstack>
        </b-col>
      </b-row>
    </template>
  </b-table-lite>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { HALO_BUBBLES_CONFIG_KEY, HOLLOW_BUBBLES_CONFIG_KEY } from '@/constants'
import { HollowBubbleDisplays } from '@/types'

@Component
export default class BubblesLegends extends Vue {
  get hollowBubblesDisplayed() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config[
      HOLLOW_BUBBLES_CONFIG_KEY
    ].display
  }

  get displayHollowBubbles() {
    return this.hollowBubblesDisplayed !== HollowBubbleDisplays.NONE
  }

  get solidLabel() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config[
      HOLLOW_BUBBLES_CONFIG_KEY
    ].solidLabel
  }

  get hollowLabel() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config[
      HOLLOW_BUBBLES_CONFIG_KEY
    ].hollowLabel
  }

  get legendBubbleColor() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config[
      HOLLOW_BUBBLES_CONFIG_KEY
    ].legendBubbleColor
  }

  get haloBubbles() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config[
      HALO_BUBBLES_CONFIG_KEY
    ]
  }

  get pulseExist() {
    return this.haloBubbles.some(({ showPulse }) => showPulse)
  }

  get haloExist() {
    return this.haloBubbles.some(({ showHalo }) => showHalo)
  }

  get tableFields() {
    return [{ key: 'bubble' }, { key: 'name' }]
  }

  get tableItems() {
    return [
      ...(this.displayHollowBubbles
        ? [
            {
              showSolid: true,
              name: this.solidLabel,
            },
            {
              showHollow: true,
              name: this.hollowLabel,
            },
          ]
        : []),
      ...this.haloBubbles
        .map(({ name, showHalo, strokeColor, showPulse, pulsingStrokeColor }) => ({
          showHalo,
          strokeColor,
          showPulse,
          pulsingStrokeColor,
          name,
        }))
        .filter(({ showHalo, showPulse }) => showHalo || showPulse),
    ]
  }
}
</script>

<style>
/* Black colour as fallback for IE */
.solid-bubble circle {
  fill: var(--legend-bubble-color, black) !important;
  fill: black;
}

/* Black colour as fallback for IE */
.hollow-bubble path {
  stroke: var(--legend-bubble-color, black) !important;
  stroke: black;
}

.halo-bubble path {
  stroke: var(--bubble-halo-stroke-color, black) !important;
  stroke: black;
}

.pulsing-bubble circle {
  fill: var(--bubble-pulsing-stroke-color, grey) !important;
  fill: grey;
}
</style>
