import { getProjectsByLatLon, LatLonParams } from '@/api'
import store from '@/store'
import { useFilterStore } from '@/store/app/filter'
import typedStore from '@/store/typedStore'
import { LeafNode, TreeNode } from '@/types'
import _isEmpty from 'lodash/isEmpty'
// @ts-ignore vuex-map-fields import
import { getField, updateField } from 'vuex-map-fields'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface PlaceholderLatLonParams extends LatLonParams {
  placeholder: true
  // eslint-disable-next-line camelcase
  displayName: string
}

export type CurrentLocation = LatLonParams | PlaceholderLatLonParams

export interface ISearchState {
  showModal: Boolean
  searchString: string
  currentLocation: CurrentLocation
  locationResults: any[]
  projectResults: any[]
  filterRecords: Boolean
  recordsToShow: Set<number>
}

@Module({ dynamic: true, store, name: 'search', namespaced: true })
export default class Search extends VuexModule implements ISearchState {
  showModal = false
  searchString = ''
  currentLocation: CurrentLocation = {} as unknown as CurrentLocation
  locationResults: any[] = []
  projectResults: TreeNode[] = []
  filterRecords = false
  recordsToShow: Set<number> = new Set<number>()

  @Mutation
  resetState() {
    this.showModal = false
    this.searchString = ''
    this.currentLocation = {} as unknown as CurrentLocation
    this.locationResults = []
    this.projectResults = []
    this.filterRecords = false
    this.recordsToShow = new Set<number>()
  }

  @Mutation
  setShowModal(payload: boolean) {
    this.showModal = payload
  }

  @Mutation
  setSearchString(payload: string) {
    this.searchString = payload
  }

  @Mutation
  setCurrentLocation(payload: CurrentLocation) {
    this.currentLocation = payload
  }

  @Mutation
  setLocationResults(payload: any[]) {
    this.locationResults = payload
  }

  @Mutation
  setProjectResults(payload: TreeNode[]) {
    this.projectResults = payload
  }

  @Mutation
  setFilterRecords(payload: boolean) {
    this.filterRecords = payload
  }

  @Mutation
  setRecordsToShow(payload: number[]) {
    this.recordsToShow = new Set(payload)
  }

  @Action({ rawError: true })
  async filterByLocation(location: LatLonParams) {
    this.setCurrentLocation(location)
    const records = await getProjectsByLatLon(
      {
        lat: location.lat,
        lon: location.lon,
        displayName: '',
      },
      typedStore.activeVisualisation.visualisationAppRank,
    )
    this.setRecordsToShow(records)
    this.setFilterRecords(true)
  }

  @Action({ rawError: true })
  resetFilterByLocation() {
    this.setCurrentLocation({} as unknown as CurrentLocation)
    this.setRecordsToShow([])
    this.setFilterRecords(false)
  }

  get getField() {
    return getField(this)
  }

  get isLocationFiltering() {
    return this.filterRecords && !_isEmpty(this.currentLocation)
  }

  get isCategoryFiltering() {
    return useFilterStore().isFiltering
  }

  get isFiltering() {
    return this.isLocationFiltering || this.isCategoryFiltering
  }

  get leafNodeFilter() {
    if (this.isLocationFiltering) {
      return (node: LeafNode) => this.recordsToShow.has(node.recordId)
    }
    const filterStore = useFilterStore()
    if (filterStore.isFiltering) {
      return filterStore.leafNodeFilter
    }
    return () => true
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }
}

export const SearchModule = getModule(Search)
