import { getData, UpdateDataPayloadItem } from '@/api'
import store from '@/store'
import { RecordResponse } from '@/types'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface ICSVDataState {
  csvdata: RecordResponse[]
  changedcsvdata: UpdateDataPayloadItem[]
}

@Module({ dynamic: true, store, name: 'csvData', namespaced: true })
export default class CSVData extends VuexModule implements ICSVDataState {
  csvdata: RecordResponse[] = []
  changedcsvdata: UpdateDataPayloadItem[] = []

  @Mutation
  setCSVData(payload: RecordResponse[]) {
    this.csvdata = payload
  }

  @Mutation
  addChangedCSVData(payload: UpdateDataPayloadItem) {
    this.changedcsvdata.push(payload)
  }

  @Mutation
  resetChangedCSVData() {
    this.changedcsvdata = []
  }

  @Action({ rawError: true })
  async getCSVData() {
    this.setCSVData(await getData())
  }
}

export const CSVDataModule = getModule(CSVData)
