import { authPost, awaitRequest } from '@/api/helpers'
import { recaptchaService } from '@/services'
import { PostUserDetailsAnswerRequest } from '@/types'

export async function postUserDetailsAnswer(payload: PostUserDetailsAnswerRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return awaitRequest(
    authPost({
      path: 'user-details',
      body: {
        user_session_id: payload.sessionId,
        question_id: payload.questionId,
        answer: payload.answer,
        recaptcha_token: recaptchaToken,
      },
    }),
  )
}
