<template>
  <ButtonTemplate :on-click="onClick" icon="view-stacked" title="Insert index" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'

const components = {
  ButtonTemplate,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

@Component({ components })
export default class InsertIndexTableButton extends Vue.extend({ props }) {
  onClick() {
    if (this.editor) {
      this.editor.chain().focus().setIndexTable().run()
    }
  }
}
</script>
