import router from '@/router'
import { settingsService } from '@/services'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const API_ADDRESS = process.env.VUE_APP_API_ADDRESS
const GRAPHQL_URL = process.env.VUE_APP_GRAPHQL_HTTP

Sentry.init({
  dsn: settingsService.isProd
    ? 'https://eeb7b363ac5e490a977227eb2848f84d@o932979.ingest.sentry.io/6000921'
    : undefined,
  Vue,
  logErrors: !settingsService.isProd,
  environment: process.env.VUE_APP_TYPE,
  release: process.env.VUE_APP_RELEASE,
  integrations: [
    new Integrations.BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          name: router.currentRoute.name || router.currentRoute.fullPath,
        }
      },
      tracingOrigins: [
        ...(API_ADDRESS ? [API_ADDRESS] : []),
        ...(GRAPHQL_URL ? [GRAPHQL_URL] : []),
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 0.25,
  tracingOptions: {
    trackComponents: true,
  },
})
