import { UI_EVENTS } from '@/constants'
import logger from '@/plugins/logging/logger'

const UIEventLogger = {
  install(Vue: any, enabled: boolean) {
    if (!enabled) return

    Vue.mixin({
      mounted() {
        this.initializeEventLogging()
      },
      methods: {
        initializeEventLogging() {
          this.addEventListenerAll(window, (evt: Event) => {
            const target = evt.target as HTMLElement
            const targetClassName = target.className ? '.' + target.className : ''
            const targetLabel = `${target.localName}${targetClassName}`

            // do not log events that occur in DebugConsole
            if (targetLabel !== 'textarea.debug-messages')
              logger.debug(`${evt.type} : ${targetLabel}`)
          })
        },
        addEventListenerAll(
          target: EventTarget,
          listener: EventListenerOrEventListenerObject,
          ...otherArguments: any[]
        ) {
          const listenEventTypes = Object.values(UI_EVENTS)
          for (const eventType of listenEventTypes) {
            target.addEventListener(eventType, listener, ...otherArguments)
          }

          const originalDispatchEvent = target.dispatchEvent
          target.dispatchEvent = function (...args: [Event]): boolean {
            if (listenEventTypes.includes(args[0].type as keyof HTMLElementEventMap)) {
              target.addEventListener(args[0].type, listener, ...otherArguments)
            }
            return originalDispatchEvent.apply(this, args as [Event])
          }
        },
      },
    })
  },
}

export default UIEventLogger
