import { DefaultBasemap } from '@/components/builder/MapSettings/defaultMaps'
import { ReorderableListItem } from '@/components/common/ReorderableList'
import { ClustersHash } from '@/components/public/Visualisations/Bubbles'
import { Coordinate2D, NodeType, NodeTypes, SortDirection } from '.'
import {
  BasemapType,
  ComparisonEqualityOperator,
  ConfigKey,
  CustomBubblesCompare,
  CustomTabConfigKeys,
  FeedbackRatingType,
  FieldCalculationFunctionType,
  FieldCalculationOperatorType,
  FieldCalculationTypes,
  FieldType,
  FieldTypes,
  FilterDisplayType,
  HollowBubbleDisplay,
  InfoCardContentPanelKeys,
  InfoCardContentPanelLinkType,
  InfoCardSize,
  LegendPosition,
  NavButtonDisplayType,
  NavButtonKey,
  NavigationButtonDisplay,
  NodeConfigKeys,
  QuestionInputType,
  SplashScreenDisplay,
  SplashScreenWidth,
  StoryKey,
  StoryKeys,
  StoryPaths,
  SummaryTableOption,
  VisualisationType,
} from './constants'

export type ImageRef = { img: number } | null

export interface MonetaryFormattingConfig {
  currency: Intl.NumberFormatOptions['currency']
  numDigits: number | string
}

export interface NumericFormattingConfig {
  locale: string
  numDigits: number | string
}

export interface FieldMapItem {
  name: string
  type: Exclude<FieldType, typeof FieldTypes.CALCULATION>
  unit: string
  total: boolean
  comparisonTotal: boolean
  column: string
  level?: number
  showBubbles: boolean
  headerPerBubble?: boolean
}

export interface LevelField {
  name: string
  column: string
  level: number
  showBubbles: boolean
  headerPerBubble?: boolean
}

export interface UserDetailsQuestionConfig {
  id: string
  title: string
  inputType: QuestionInputType
  options: string[]
}

export interface UserDetailsConfig {
  collectUserDetails: boolean
  content: string
  questions: UserDetailsQuestionConfig[]
}

export interface FeedbackQuestionConfig {
  id: string
  level: number
  ratingType: FeedbackRatingType
  answerType?: QuestionInputType
  answerOptions?: string[]
  title: string
  subtitle: string
  displayResults: boolean
  allowComments: boolean
}

export interface FieldCalculationConfig {}

export interface FieldCalculationFunction {
  type: typeof FieldCalculationTypes.FUNCTION
  column: string
  function: FieldCalculationFunctionType
}

export interface FieldCalculationOperator {
  type: typeof FieldCalculationTypes.OPERATOR
  operator: FieldCalculationOperatorType
}

export interface FieldCalculationNumber {
  type: typeof FieldCalculationTypes.NUMBER
  number: string
}

export type FieldCalculationDefinition =
  | FieldCalculationFunction
  | FieldCalculationOperator
  | FieldCalculationNumber

export interface FieldCalculationV2Config
  extends Omit<FieldMapItem, 'total' | 'type' | 'level' | 'showBubbles'> {
  type: typeof FieldTypes.CALCULATION
  isMonetary: boolean
  definitions: FieldCalculationDefinition[]
}

export function isFieldCalculation(
  field: FieldMapItem | FieldCalculationV2Config,
): field is FieldCalculationV2Config {
  return field.type === FieldTypes.CALCULATION
}

export function isFieldNumeric(
  field: FieldMapItem | FieldCalculationV2Config,
): field is FieldMapItem {
  return field.type === FieldTypes.NUMERIC
}

export function isFieldMonetary(
  field: FieldMapItem | FieldCalculationV2Config,
): field is FieldMapItem {
  return field.type === FieldTypes.MONETARY
}

export function isFieldString(
  field: FieldMapItem | FieldCalculationV2Config,
): field is FieldMapItem {
  return field.type === FieldTypes.STRING
}

export interface NavButtonConfig {
  key: NavButtonKey
  displayType: NavButtonDisplayType
  label: string
  icon?: ImageRef
  order: number
}

export type NavButtonsConfig = Record<NavButtonKey, NavButtonConfig>

/**
 * @deprecated replaced with `NavButton`
 */
export interface NavigationButton {
  /**
   * @deprecated replaced with `NavButton`
   */
  buttonName: string
  /**
   * @deprecated replaced with `NavButton`
   */
  buttonIcon: ImageRef
}

/**
 * @deprecated replaced with `NavButton`
 */
export interface NavigationButtonConfigItem {
  /**
   * @deprecated replaced with `NavButton`
   */
  order: number
  /**
   * @deprecated replaced with `NavButton`
   */
  display: NavigationButtonDisplay
}

/**
 * @deprecated replaced with `NavButton`
 */
export interface NavigationButtonConfig {
  /**
   * @deprecated replaced with `NavButton`
   */
  faq: NavigationButtonConfigItem
  /**
   * @deprecated replaced with `NavButton`
   */
  showMap: NavigationButtonConfigItem
  /**
   * @deprecated replaced with `NavButton`
   */
  download: NavigationButtonConfigItem
  /**
   * @deprecated replaced with `NavButton`
   */
  searchSettings: NavigationButtonConfigItem
  /**
   * @deprecated replaced with `NavButton`
   */
  filterByCategory: NavigationButtonConfigItem
  selectVisualisationType: NavigationButtonConfigItem
}

/**
 * @deprecated replaced with `NavButton`
 */
export interface NavigationConfig extends NavigationButton {
  /**
   * @deprecated replaced with `NavButton`
   */
  mapIcon: ImageRef
  /**
   * @deprecated replaced with `NavButton`
   */
  mapName: string
  /**
   * @deprecated replaced with `NavButton`
   */
  bubblesIcon: ImageRef
  /**
   * @deprecated replaced with `NavButton`
   */
  bubblesName: string
  /**
   * @deprecated replaced with `NavButton`
   */
  buttonConfig: NavigationButtonConfig
}

export interface BubbleParamsConfig {
  spacing: number
  bubbleMax: number
  bubbleMin: number
  bubbleMinAuto: boolean
  sideBubblePositionX: number
  sideBubblePositionY: number
  showZeroValueBubbles: boolean
  hideSideBubblesPercentage: number
}

export interface BubblesClustersSortingConfig {
  sortBy: string
  direction: SortDirection
}

export interface FaqConfig extends NavigationButton {
  text: string
  title: string
  enabled: boolean
}

export interface ComparisonGraphCategory {
  title: string
  fields: string[]
}
export interface ComparisonGraphLegendItem {
  color: string
  label: string
}

export interface ComparisonGraphSettingsConfig {
  show: boolean
  showOnRoot: boolean
  showOnSubroot: boolean
  useStoryColour: boolean
  rotate: boolean
  multilineXLabels: boolean
  showXAxis: boolean
  showYAxis: boolean
  categories: ComparisonGraphCategory[]
  showLegend: boolean
  legenditems: ComparisonGraphLegendItem[]
}

export function isStoryKeys(key: ConfigKey | StoryKey): key is StoryKey {
  return Object.values(StoryKeys as any).includes(key)
}

export type NodeStoryKeys = Exclude<
  StoryKey,
  typeof StoryKeys.INFO_FAQ | typeof StoryKeys.SPLASH_SCREEN
>

export interface StorySetting {
  fontSize: number | string // should be number
  fontColor: string
  linkColor: string
  fontFamily: string
  headingColor: string
  tableBorderColor?: string
}

export interface StorySettingsConfig {
  [StoryKeys.INFO_FAQ]?: StorySetting
  [StoryKeys.ABOVE_MAP]: StorySetting
  [StoryKeys.SIDEBAR_BOT]: StorySetting
  [StoryKeys.SIDEBAR_TOP]: StorySetting
  [StoryKeys.ABOVE_BUBBLES]: StorySetting
  [StoryKeys.SPLASH_SCREEN]?: StorySetting
}

export interface MapStyle {
  fill: boolean
  color: string
  stroke: boolean
  weight: string // should be number
  opacity: string // should be number
  dashArray: string // should be number
  dashOffset: string // should be number
  fillOpacity: string // should be number
  highlightColor: string
}

export interface MapExtentItem {
  lat: number | string // should be number
  lng: number | string // should be number
}
export interface MapExtent {
  NWcoords: MapExtentItem
  SEcoords: MapExtentItem
}

export interface MapLegendItem {
  range: number | string // should be number
  colour: string
}

export interface MapBasemapCustom {
  url: string
  attribution: string
}

export interface MapConfig {
  style: MapStyle
  extent: MapExtent
  legends: MapLegendItem[]
  maxScale: number
  minScale: number
  basemapType: BasemapType // TODO find other options
  basemapCustom: MapBasemapCustom
  basemapDefault: DefaultBasemap
  markerSize?: number
  markerCustom?: boolean
  markerIconName?: string // TODO specify choices
}

// deprecated
export interface UnitForTotal {
  type: typeof FieldTypes.NUMERIC | typeof FieldTypes.MONETARY
  unit: string
}

export interface GeomConfig {
  [key: string]: string
}

export interface PiechartItem {
  color: string
  column: FieldMapItem['column']
}

export interface SearchSettingsConfig {
  /**
   * @deprecated replaced with `NavButton`
   */
  icon?: ImageRef
  enabled: boolean
  lightness: number
  /**
   * @deprecated replaced with `NavButton`
   */
  searchName?: string
  basemapType: BasemapType
  basemapCustom: MapBasemapCustom
  basemapDefault: DefaultBasemap
  filterDisplayType: FilterDisplayType
  projectResultFields: string[]
  enableSearchByLocation?: boolean
  enableSearchForProjects?: boolean
}

export interface DownloadConfig extends NavigationButton {
  fields: string[]
  enabled: boolean
}

export interface SummaryTableConfig {
  [StoryPaths.PATH_ROOT]: SummaryTableOption
  [StoryPaths.PATH_SUBROOT]: SummaryTableOption
  levelOptions: Record<number, SummaryTableOption>
  sortBy: string | null
  sortDesc: boolean
  collapsable: boolean
  sortTabOrder: boolean
  displayHeaders: boolean
  startCollapsed: boolean
  allowCustomSort: boolean
  displayAutoTotal: boolean
  collapseButtonText: string
}

export interface BubblesLegendsConfig {
  showLegend: boolean
  legendTitle: string
  legendPosition: LegendPosition
}

export interface HollowBubbleConfig extends BubblesLegendsConfig {
  display: HollowBubbleDisplay
  solidLabel: string
  borderWidth: number | string // should be number
  hollowLabel: string
  categoryField: string | null
  categoryValue: string | null
  legendBubbleColor: string
}

interface AppConfigDeprecated {
  showMap?: boolean
  numericFormatting?: {
    locale: string
    currency: string
    numDigits: number | string
  }
  /** deprecated? */
  pageTitle?: string
}

export interface AppConfig extends AppConfigDeprecated {
  appName: string
  customiseTabFontColour?: boolean
  customiseTabScaleFactor?: boolean
  customPageDescription?: boolean
  pageDescription?: string
  customPageTitle: boolean
  pageTitle?: string
  graphStyle: 'pie' | 'donut'
  logo: ImageRef
  originalTabColours: boolean
  orgName: string
  popupSubtitleField?: string // deprecated
  popupTitleField?: string // deprecated
  projectLabelField: string
  projectPlural?: string
  projectSingular?: string
  showIcon?: boolean
  showMobileIcon?: boolean
  showMobileLabel?: boolean
  showLabel?: boolean
  showPiechartSidebar?: boolean
  usePieChartStoryColour?: boolean
  showPopupChart: boolean // deprecated
  showProjectCount?: boolean
  showSummaryTable: boolean
  disableMobileHamburger?: boolean
  disableMobileBreadcrumb?: boolean
  totalStringLabel?: string
  piechartTotalLabel?: string
  hidePiechartOnRootSubroot?: boolean
  hidePiechartOnZeroNegative?: boolean
  hideSubrootTab?: boolean
  showPopupTotal?: boolean // deprecated
  geomColumn?: string
  geomShpProp?: string
}

export interface PopupLevelConfig {
  subtitleField?: string
  titleField?: string
  showChart?: boolean
  showTotal?: boolean
  items?: string[]
}

export interface PopupConfig {
  global?: PopupLevelConfig
  levels?: Record<number, PopupLevelConfig>
}

export interface SplashScreenConfig {
  title: string
  width?: SplashScreenWidth
  content: string
  enabled: boolean
  display?: SplashScreenDisplay
}

export interface SplashScreenConfigDeprecated {
  None?: 'None'
}

export interface VisualisationConfig {
  type: VisualisationType
  defaultVisualisationType: VisualisationType
  activeVisualisationTypes: VisualisationType[]
}

export interface FilterByCategoryConfig extends NavigationButton {
  label: string
  title: string
  enable: boolean
  columns: [FieldMapItem['column']] | [FieldCalculationV2Config['column']] | null
  category?: FieldMapItem['column'] | FieldCalculationV2Config['column'] | null // deprecated
  position: LegendPosition
}

export interface SunburstConfig {
  max: number
}

export interface CustomBubblesFilter {
  name?: string
  category: FieldMapItem['column']
  compare: null | CustomBubblesCompare
  operator: ComparisonEqualityOperator
  value: string | number | FieldMapItem['column']
}

export interface CustomBubblesFilters {
  name: string
  filters: CustomBubblesFilter[]
}

export type CustomBubblesGroups = Record<string, CustomBubblesFilters>

export interface HaloBubblesConfig {
  id: string
  name: string
  showHalo: boolean
  radiusGap: number
  strokeOpacity: number
  strokeWidth: number
  strokeColor: string
  showPulse: boolean
  pulsingDuration: number
  pulsingRadiusGap: number
  pulsingStrokeOpacity: number
  pulsingStrokeWidth: number
  pulsingStrokeColor: string
  filter: keyof CustomBubblesGroups | null
}

export interface AxisLabel {
  show: boolean
  text?: string
}

export interface AxisLabels {
  x: AxisLabel
  y: AxisLabel
}

export interface XYDimensionsMapping {
  xAxisTickKey: string
  xAxisTickLabel: string
  yAxisColumn: string
  bubbleSizeColumn: string
}

export interface YAxisTickLabel {
  value: number
  text: string
}

export interface YAxisTicksConfig {
  showLabels: boolean
  numericUnit?: string
  useDistinctValues: boolean
  customLabels: YAxisTickLabel[]
}

export interface XYBubblesConfig {
  axisLabels: AxisLabels
  dimensionsMappings: XYDimensionsMapping[]
  yAxisTicks: YAxisTicksConfig
}

export interface ComparisonBubblesConfig {
  enabled: boolean
}

interface VersionConfigDeprecated {
  treeDetails?: any
  unitForTotal?: UnitForTotal
}

export interface VersionConfigResponse extends VersionConfigDeprecated {
  // Handles old config with deprecated syntax
  appConfig?: AppConfig
  bubbleParams?: BubbleParamsConfig
  bubblesLegends?: BubblesLegendsConfig
  bubblesClustersSorting?: BubblesClustersSortingConfig
  comparisonGraphSettings?: ComparisonGraphSettingsConfig
  customBubblesGroups?: CustomBubblesGroups
  download?: DownloadConfig
  faq?: FaqConfig
  feedbackQuestions: FeedbackQuestionConfig[]
  fieldCalculations?: FieldCalculationConfig[]
  fieldCalculationsV2Format?: FieldCalculationV2Config[]
  fieldMap: FieldMapItem[]
  filterByCategory?: FilterByCategoryConfig
  geomConfig?: GeomConfig
  haloBubbles?: HaloBubblesConfig[]
  hollowBubbles?: HollowBubbleConfig
  mapConfig?: MapConfig
  mapPopupTotalItems?: string[]
  monetaryFormatting?: MonetaryFormattingConfig
  /**
   * @deprecated replaced with `navButtons`
   */
  navigation: NavigationConfig
  navButtons?: NavButtonsConfig
  numericFormatting?: NumericFormattingConfig
  piechartItems: PiechartItem[]
  popupConfig?: PopupConfig // overrides other (non-map) popup fields when set
  comparisonPopupConfig?: PopupConfig
  popupItems: string[] // deprecated
  searchSettings: SearchSettingsConfig
  showMap?: boolean
  splashScreen?: SplashScreenConfig | SplashScreenConfigDeprecated
  storySettings: StorySettingsConfig
  summaryItems: string[]
  summaryTable?: SummaryTableConfig
  sunburst?: SunburstConfig
  visualisation?: VisualisationConfig
  xyBubblesConfig?: XYBubblesConfig
  userDetails?: UserDetailsConfig
  comparisonBubbles: ComparisonBubblesConfig
}

export interface VersionConfig {
  // Actual config once default config is added
  appConfig: AppConfig
  bubbleParams: BubbleParamsConfig
  bubblesLegends: BubblesLegendsConfig
  bubblesClustersSorting: BubblesClustersSortingConfig
  comparisonGraphSettings: ComparisonGraphSettingsConfig
  customBubblesGroups: CustomBubblesGroups
  download: DownloadConfig
  faq: FaqConfig
  feedbackQuestions: FeedbackQuestionConfig[]
  userDetails: UserDetailsConfig
  fieldCalculationsV2Format: FieldCalculationV2Config[]
  fieldMap: FieldMapItem[]
  filterByCategory: FilterByCategoryConfig
  geomConfig?: GeomConfig
  haloBubbles: HaloBubblesConfig[]
  hollowBubbles: HollowBubbleConfig
  mapConfig: MapConfig
  mapPopupTotalItems: string[]
  monetaryFormatting: MonetaryFormattingConfig
  navButtons: NavButtonsConfig
  numericFormatting: NumericFormattingConfig
  piechartItems: PiechartItem[]
  popupConfig: PopupConfig // overrides other (non-map) popup fields when set
  comparisonPopupConfig: PopupConfig
  searchSettings: SearchSettingsConfig
  showMap?: boolean // default is true
  splashScreen?: SplashScreenConfig
  storySettings: StorySettingsConfig
  summaryItems: string[]
  summaryTable: SummaryTableConfig
  sunburst: SunburstConfig
  visualisation: VisualisationConfig
  xyBubblesConfig: XYBubblesConfig
  comparisonBubbles: ComparisonBubblesConfig
}

export interface VisPositions {
  default: Coordinate2D
  levelOverrides?: Record<number, Coordinate2D>
  clusterPathsOverrides?: Record<ClustersHash, Coordinate2D>
}

export interface NodeConfig {
  [NodeConfigKeys.ICON]: ImageRef
  [NodeConfigKeys.NAME]: string
  [NodeConfigKeys.ORDER]: number | null
  [NodeConfigKeys.COLOUR]: string | null
  [NodeConfigKeys.FONT_COLOUR]?: string
  [NodeConfigKeys.LABEL]?: string | null
  [NodeConfigKeys.BUBBLE_POSITION]: [number, number]
  [NodeConfigKeys.HEADER_POSITION]: [number, number]
  [NodeConfigKeys.BUBBLE_POSITIONS]?: VisPositions
  [NodeConfigKeys.HEADER_POSITIONS]?: VisPositions
  [NodeConfigKeys.SCALE_FACTOR]?: number
  [NodeConfigKeys.VISIBLE]?: boolean
  [NodeConfigKeys.NODE_TYPE]?: NodeType
}

export interface InfoCardContentPanel extends ReorderableListItem {
  [InfoCardContentPanelKeys.CONTENT]?: string
  [InfoCardContentPanelKeys.HIDE_BORDER]?: boolean
  [InfoCardContentPanelKeys.LINK_TYPE]?: InfoCardContentPanelLinkType
  [InfoCardContentPanelKeys.LINK]?: string
  [InfoCardContentPanelKeys.TOOLTIP]?: string
}

export interface CustomTabNodeConfig extends NodeConfig {
  [CustomTabConfigKeys.SHOW_INFO_CARD]: boolean
  [CustomTabConfigKeys.INFO_CARD_SIZE]: InfoCardSize
  [CustomTabConfigKeys.BACKGROUND_IMAGE]: ImageRef
  [CustomTabConfigKeys.INFO_CARD_CONTENT_PANELS]: InfoCardContentPanel[]
  [CustomTabConfigKeys.INFO_CARD_OPACITY]?: number
  [CustomTabConfigKeys.INFO_CARD_COLOUR]?: string
  [CustomTabConfigKeys.INFO_CARD_CONTENT_PANELS_OPACITY]?: number
  [CustomTabConfigKeys.INFO_CARD_CONTENT_PANELS_COLOUR]?: string
}

export interface SecondaryAppReferenceNodeConfig extends NodeConfig {
  [NodeConfigKeys.NODE_TYPE]: typeof NodeTypes.APP
  secondaryAppId: number | null
}
