import { authDelete, authGet, authPost, awaitRequest, awaitRequestJson } from '@/api/helpers'
import { AppIdParam, AppRank, Node, NodeResponse, SuccessResponse, VersionIdParam } from '@/types'

export function getNodes(appRank: AppRank) {
  return awaitRequestJson<NodeResponse[]>(authGet('nodes', {}, appRank))
}

export function getNodesByVersion(params: VersionIdParam) {
  return awaitRequestJson<NodeResponse[]>(authGet('nodes', params))
}

export function getNodesByParentPath(appId: number, path: string, appRank: AppRank) {
  return awaitRequestJson<NodeResponse[]>(authGet('nodes/by_parent', { appId, path }, appRank))
}

export function postNodes(paths: Node['path'][]) {
  return awaitRequestJson<SuccessResponse>(authPost({ path: 'nodes', body: { paths } }))
}

export function deleteNode(path: string) {
  return awaitRequest(authDelete('nodes', {}, { path }))
}

export async function getTabNodes(params: AppIdParam, appRank: AppRank) {
  return awaitRequestJson<NodeResponse[]>(authGet('nodes/tabs', params, appRank))
}
