<template>
  <div>
    <ButtonTemplate :on-click="() => onClick()" icon="code-square" title="Open URL In Dialog" />
    <b-modal v-model="showModal" hide-footer hide-header size="lg" @ok="setLink" @show="getLink">
      <b-form @submit="submit">
        <b-form-group :invalid-input="state">
          <b-input-group prepend="Link">
            <b-input v-model="src" debounce="500" placeholder="https://" />
            <b-input v-model="text" debounce="500" placeholder="Text" />
            <b-input-group-append>
              <b-button type="submit"> Insert </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Editor } from '@tiptap/core'
import Vue from 'vue'
import Component from 'vue-class-component'

import { ButtonTemplate } from '@orbica/vue-modules'
import { iFrameDialogTag } from './IframeDialogExtension'

const components = {
  ButtonTemplate,
}
const props = {
  editor: {
    type: Editor,
    default: null,
  },
}

@Component({ components })
export default class InsertIframeDialogButton extends Vue.extend({ props }) {
  src = ''
  text = ''
  showModal = false

  onClick() {
    this.showModal = true
  }

  setLink() {
    if (this.editor) {
      this.editor.chain().focus().setIframeDialog({ src: this.src, text: this.text }).run()
    }
  }

  getLink() {
    const attr = this.editor?.getAttributes(iFrameDialogTag)
    this.src = attr?.src || null
    this.text = attr?.text || null
  }

  get state() {
    return null
  }

  submit(e: Event) {
    e.preventDefault()
    if (this.src) {
      this.setLink()
      this.showModal = false
    }
  }
}
</script>
