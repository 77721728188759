import { MAINTENANCE_MODE, ROUTE_MAINTENANCE, VIEW_MAP, VIEW_PUBLIC } from '@/constants'
import typedStore from '@/store/typedStore'
import { VisualisationTypes } from '@/types'
import { authGuard } from '@orbica/vue-modules'
import { NavigationGuard, RawLocation } from 'vue-router'

export const requiresAuthGuard: NavigationGuard = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (MAINTENANCE_MODE) {
      // If in maintenance mode, all requiresAuth routes should redirect to the maintenance page
      authGuard(to, from, () => next({ name: ROUTE_MAINTENANCE }))
    } else {
      authGuard(to, from, next)
    }
  } else {
    next()
  }
}

export const storyPathToArrayGuard: NavigationGuard = (to, from, next) => {
  // storyPath should be an array of strings. However, on first page load it is
  // parsed as a string. This function converts it to an array of strings again.
  let customNext = false
  const toStoryPath = to.params.storyPath
  const toSecondaryStoryPath = to.params.secondaryStoryPath

  let storyPath
  if (toStoryPath && !toSecondaryStoryPath && !Array.isArray(toStoryPath)) {
    storyPath = toStoryPath.split('/')
    customNext = true
  } else {
    storyPath = toStoryPath
  }

  let secondaryStoryPath
  if (toSecondaryStoryPath && !Array.isArray(toSecondaryStoryPath)) {
    secondaryStoryPath = toSecondaryStoryPath.split('/')
    customNext = true
  } else {
    secondaryStoryPath = toSecondaryStoryPath
  }

  if (customNext) {
    next({
      ...to,
      params: {
        ...to.params,
        storyPath,
        secondaryStoryPath,
      },
    } as RawLocation)
  }

  next()
}

export const redirectMapToPublicGuard: NavigationGuard = (to, from, next) => {
  if (to.params.mainpanel === VIEW_MAP) {
    typedStore.public.display.setInitialVisualisationType(VisualisationTypes.MAP)

    next({
      ...to,
      params: {
        ...to.params,
        mainpanel: VIEW_PUBLIC,
      },
    } as RawLocation)
  } else {
    next()
  }
}
