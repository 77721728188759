import { getNodes, getTabNodes } from '@/api'
import { NAME_ID_SEPARATOR, NODE_CONFIG_KEY, SECONDARY_APP_PATH_PREFIX, TAB_COLORS } from '@/constants'
import { is, isPlainObject } from '@/helpers'
import logger from '@/plugins/logging/logger'
import { nodeService, NodeUpdate } from '@/services'
import store from '@/store'
import Nodes from '@/store/base/entities/nodes'
import { calculateNextIdFromNames } from '@/store/helpers'
import typedStore from '@/store/typedStore'
import {
  AppRank,
  AppRanks,
  AppStatuses,
  ConfigKey,
  NodeConfig,
  NodeData,
  NodeResponse,
  NodeStoryKeys,
  NodeTypes,
  Path,
  SecondaryAppReferenceNode,
} from '@/types'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators'

const _cloneDeep = require('lodash.clonedeep')

export interface IPrimaryNodesState {
  fromApi: NodeResponse[]
  created: NodeData[]
  updated: NodeData[]
  deleted: string[]
  toUpdate: NodeUpdate[]
  toCreate: NodeData[]
  toDelete: string[]
  secondaryAppsTabNodes: Record<number, NodeData[]>
}

@Module({ dynamic: true, store, name: 'primaryNodes', namespaced: true })
export default class PrimaryNodes extends Nodes implements IPrimaryNodesState {
  fromApi: NodeResponse[] = [] // array of objects, each having {path: , parent: , config: , storyKeys: stories, ...}
  created: NodeData[] = [] // array of objects that have been created, each having {path: , parent: , config: , storyKeys: stories, ...}
  updated: NodeData[] = [] // array of objects that have been updated, each having {path: , parent: , config: , storyKeys: stories, ...}
  deleted: string[] = []
  toUpdate: NodeUpdate[] = [] // {path: set(key1, key2...)}
  toCreate: NodeData[] = [] // array of objects, each having {path: , parent: , config: , storyKeys: stories, ...}
  toDelete: string[] = [] // array of node paths
  secondaryAppsTabNodes: Record<number, NodeData[]> = {} // a dictionary of secondary app ids and the tab nodes for the secondary app

  // Getters
  get appRank(): AppRank {
    return AppRanks.PRIMARY
  }

  get allNodes(): NodeData[] {
    // Get all of the nodes from nodes.toUpdate
    const toUpdateNodes: NodeData[] = this.toUpdate.map((nodeUpdate) => nodeUpdate.node)

    // Filter out the toUpdate nodes from the updated nodes so the toUpdate nodes can be returned instead
    let updatedNodes = this.updated
    updatedNodes = updatedNodes.filter(
      (updated) => !toUpdateNodes.find((toUpdate) => toUpdate.path === updated.path),
    )
    updatedNodes = [...updatedNodes, ...toUpdateNodes]

    // Filter out the updated nodes from the original nodes so the updated nodes can be returned instead
    let originalNodes = [...this.fromApi, ...this.toCreate, ...this.created]
    originalNodes = originalNodes.filter(
      (original) => !updatedNodes.find((updated) => updated.path === original.path),
    )

    return [...originalNodes, ...updatedNodes]
  }

  get secondaryAppReferenceNodes(): SecondaryAppReferenceNode[] {
    return this.allNodes.filter(
      (x) => !this.removedNodes.includes(x.path) && x.config?.nodeType === NodeTypes.APP,
    ) as SecondaryAppReferenceNode[]
  }

  get accessibleSecondaryAppReferenceNodes(): SecondaryAppReferenceNode[] {
    return this.secondaryAppReferenceNodes.filter((x) => {
      // if the primary app version being accessed is not the current
      // published version then all secondary apps are accessible
      if (!typedStore.primary.app.isCurrentPublishedVersion) return true

      // filter the secondary apps based on the state of the primary app
      if (x.config.secondaryAppId) {
        const primaryAppStatus = typedStore.primary.app.app.status
        const secondaryAppStatus = typedStore.primary.app.secondaryApp(x.config.secondaryAppId)
          ?.status
        if (secondaryAppStatus) {
          switch (primaryAppStatus) {
            case AppStatuses.LIVE:
              return secondaryAppStatus === AppStatuses.LIVE
            case AppStatuses.INTERNAL:
              return (
                secondaryAppStatus === AppStatuses.LIVE ||
                secondaryAppStatus === AppStatuses.INTERNAL
              )
            default:
              return false
          }
        }
      }

      return false
    })
  }

  get removedNodes(): string[] {
    return [...this.toDelete, ...this.deleted]
  }

  get secondaryAppTabNodes(): (appId: number) => NodeData[] | undefined {
    return (appId: number) => this.secondaryAppsTabNodes[appId]
  }

  // Mutations

  @Mutation
  setObjectProp(payload: {
    object: object
    key: string | number
    value: object | string | number | boolean | null
  }) {
    const { object, key, value } = payload
    Vue.set(object, key, value)
  }

  @Mutation
  setNodesFromApi(payload: NodeResponse[]) {
    this.fromApi = payload
  }

  @Mutation
  moveNodesToCreateToCreated() {
    this.created.push(...this.toCreate)
    this.toCreate = []
  }

  @Mutation
  moveNodesToUpdateToUpdated() {
    // filter out any nodes in nodes.toUpdate from nodes.updated so we don't end up with duplicates in nodes.updated
    this.updated = this.updated.filter(
      (updated) => !this.toUpdate.find((toUpdate) => toUpdate.path === updated.path),
    )

    this.updated.push(...this.toUpdate.map((nodeUpdate) => nodeUpdate.node))
    this.toUpdate = []
  }

  @Mutation
  moveNodesToDeleteToDeleted() {
    this.deleted.push(...this.toDelete)
    this.toDelete = []
  }

  @Mutation
  resetNodeModifications() {
    this.toUpdate = []
    this.toCreate = []
    this.toDelete = []
  }

  @Mutation
  addUpdatedNode(node: NodeData) {
    this.updated.push(node)
  }

  @Mutation
  addToUpdate(nodeUpdate: NodeUpdate) {
    this.toUpdate.push(nodeUpdate)
  }

  @Mutation
  addToCreate(payload: NodeData) {
    this.toCreate.push(payload)
  }

  @Mutation
  addToDelete(path: string) {
    this.toDelete.push(path)
  }

  @Mutation
  updateSecondaryAppsTabNodes(payload: { appId: number; nodes: NodeData[] }) {
    Vue.set(this.secondaryAppsTabNodes, payload.appId, payload.nodes)
  }

  // Actions

  @Action({ rawError: true })
  setObjectPropRecursive(payload: {
    configPath: (string | number)[]
    object: any
    value: object | number | string | boolean | null
  }) {
    const { configPath, object, value } = payload

    let currentObject = object // Current object to work with
    for (let i = 0; i < configPath.length - 1; i++) {
      const propName = configPath[i]
      // If the property doesn't exist, set it to an empty object
      if (!currentObject[propName]) {
        this.setObjectProp({ object: currentObject, key: propName, value: {} })
      }
      // Update currentObject to the nested object
      currentObject = currentObject[propName]
    }

    if (isPlainObject(value)) {
      // If the value is an object, recursively set properties for each key
      for (const key in value) {
        const childValue = value[key] as object | number | string | boolean | null
        this.setObjectPropRecursive({ configPath: [...configPath, key], object, value: childValue })
      }
    } else {
      // Otherwise, set the property directly
      this.setObjectProp({ object: currentObject, key: configPath[configPath.length - 1], value })
    }
  }

  @Action({ rawError: true })
  async getOrAddNodeUpdate(updatePath: string | Path): Promise<NodeUpdate> {
    // check the node exists in nodes.toUpdate, if it doesn't get it from allNodes and add
    // it to nodes.toUpdate
    const path = updatePath.toString()
    let nodeUpdate = this.toUpdate.find((nodeUpdate) => nodeUpdate.path === path)
    if (!nodeUpdate) {
      const originalNode = this.allNodes.find((n) => n.path === path)
      if (!originalNode) {
        throw new Error(`node with path ${path} does not exist`)
      } else {
        const node = _cloneDeep(originalNode)

        nodeUpdate = {
          path,
          node: node! as NodeData,
          modifiedPropKeys: new Set<ConfigKey | NodeStoryKeys>(),
        }

        this.addToUpdate(nodeUpdate)
      }
    }

    return nodeUpdate!
  }

  @Action({ rawError: true })
  async getNodes() {
    this.setNodesFromApi(await getNodes(this.appRank))
  }

  @Action({ rawError: true })
  async updateNodeValue(payload: { path: string; key: ConfigKey | NodeStoryKeys; value: string }) {
    const { path, key, value } = payload
    // For a particular node (defined by its path), it replaces the value
    // (type string) of a particular key with a new value
    // payload: { path: , key: , value: }
    const nodeUpdate = await this.getOrAddNodeUpdate(path)
    nodeUpdate.modifiedPropKeys.add(key)
    this.setObjectPropRecursive({ configPath: [key], object: nodeUpdate.node, value })
  }

  @Action({ rawError: true })
  async batchUpdateNodeConfigProps(payload: Record<string, { key: keyof NodeConfig; value: any }[]>) {
    for (const original of this.allNodes) {
      const newNode = payload[original.path]
      if (newNode) {
        const existingUpdate = this.toUpdate.find((nodeUpdate) => nodeUpdate.path === original.path)
        if (existingUpdate) {
          for (const { key, value } of newNode) {
            const existingNode = existingUpdate.node[NODE_CONFIG_KEY]!
            this.setObjectProp({ object: existingNode, key, value })
            existingUpdate.modifiedPropKeys.add(NODE_CONFIG_KEY)
          }
        } else {
          this.addToUpdate({
            path: original.path,
            node: {
              ...original,
              [NODE_CONFIG_KEY]: {
                ...original[NODE_CONFIG_KEY],
                ...Object.fromEntries(
                  newNode.map(({ key, value }) => [key, value]),
                ),
              } as NodeConfig
            },
            modifiedPropKeys: new Set([NODE_CONFIG_KEY])
          })
        }
      }
    }
  }

  @Action({ rawError: true })
  async updateNodeProp(payload: {
    path: string
    configPath: [ConfigKey | NodeStoryKeys, ...string[]]
    value: object | number | string | boolean | null
  }) {
    const { path, configPath, value } = payload
    // Updates a single property for a node
    // Uses config path similar to updateConfig to identify the prop to update
    // payload: { path: , configPath: , value: }
    const nodeUpdate = await this.getOrAddNodeUpdate(path)
    nodeUpdate.modifiedPropKeys.add(configPath[0])
    this.setObjectPropRecursive({ configPath, object: nodeUpdate.node, value })
  }

  @Action({ rawError: true })
  async saveNewNodes() {
    try {
      const responses = await Promise.all(nodeService.createNodes(this.toCreate))

      this.moveNodesToCreateToCreated()

      // Check that each response has been successful
      if (responses.every((response) => response && response.includes('Success'))) {
        return 'success'
      }
    } catch {
      return 'failure'
    }
    return 'failure'
  }

  @Action({ rawError: true })
  async saveNodeUpdates() {
    try {
      const responses = await Promise.all(nodeService.updateNodes(this.toUpdate))

      this.moveNodesToUpdateToUpdated()

      // Check that each response has been successful
      if (responses.every((response) => response && response.includes('Success'))) {
        return 'success'
      }
    } catch {
      return 'failure'
    }
    return 'failure'
  }

  @Action({ rawError: true })
  async saveNodeDeletions() {
    try {
      const responses = await Promise.all(nodeService.deleteNodes(this.toDelete))

      this.moveNodesToDeleteToDeleted()

      // Check that each response has been successful
      if (responses.every((response) => response && response.includes('Success'))) {
        return 'success'
      }
    } catch {
      return 'failure'
    }
    return 'failure'
  }

  @Action({ rawError: true })
  async getTabNodesForAccessibleSecondaryApps() {
    await Promise.all(
      this.accessibleSecondaryAppReferenceNodes.map((node) => {
        if (node.config.secondaryAppId) {
          return this.getSecondaryAppTabNodes(node.config.secondaryAppId)
        }
        return false
      }),
    )
  }

  @Action({ rawError: true })
  async getSecondaryAppTabNodes(appId: number) {
    const secondaryAppTabNodes = this.secondaryAppsTabNodes[appId]
    if (!secondaryAppTabNodes) {
      try {
        const nodes = await getTabNodes({ appId }, AppRanks.SECONDARY)
        this.updateSecondaryAppsTabNodes({ appId, nodes })
      } catch {
        // TODO: Actual error handling
        logger.error(`error retrieving secondary app tab nodes for app: ${appId}`)
      }
    }
  }

  @Action({ rawError: true })
  addSecondaryAppReferenceNode() {
    const secondaryAppReferenceNodeNames = this.secondaryAppReferenceNodes.map(
      (node) => node.config?.name,
    )
    const nextId = calculateNextIdFromNames(secondaryAppReferenceNodeNames)

    const newNode: SecondaryAppReferenceNode = {
      path: `${SECONDARY_APP_PATH_PREFIX}${uuidv4().replace(/-/g, '').slice(0, 16)}`,
      parent: null,
      config: {
        nodeType: NodeTypes.APP,
        secondaryAppId: null,
        order: typedStore.primary.entities.tabs.allTabs.length + 1,
        colour: TAB_COLORS[typedStore.primary.entities.tabs.allTabs.length % TAB_COLORS.length],
        name: `App${NAME_ID_SEPARATOR}${nextId}`,
        // Unnecessary properties
        icon: null,
        bubblePosition: [-1, -1],
        headerPosition: [0, 0],
      },
    }
    this.addToCreate(newNode)
  }

  @Action({ rawError: true })
  removeSecondaryAppReferenceNode(path: string) {
    if (this.secondaryAppReferenceNodes.find((x) => x.path === path)) {
      this.addToDelete(path)
    } else {
      throw new Error(`Cannot find node with nodeType=[${NodeTypes.APP}] and path=[${path}]`)
    }
  }
}

export const PrimaryNodesModule = getModule(PrimaryNodes)
