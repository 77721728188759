import { getAllImages } from '@/api'
import store from '@/store'
import App from '@/store/base/app'
import Images, { IImagesState } from '@/store/base/entities/images'
import Nodes from '@/store/base/entities/nodes'
import typedStore from '@/store/typedStore'
import { AppRank, AppRanks } from '@/types'
import { Action, getModule, Module } from 'vuex-module-decorators'

export interface ISecondaryImagesState extends IImagesState {}

@Module({ dynamic: true, store, name: 'secondaryImages', namespaced: true })
export default class SecondaryImages extends Images implements ISecondaryImagesState {
  get appRank(): AppRank {
    return AppRanks.SECONDARY
  }

  get app(): App {
    return typedStore.secondary.app
  }

  get nodes(): Nodes {
    return typedStore.secondary.entities.nodes
  }

  get imageOrPreview() {
    return (configPath: string[]) => {
      const imageId = this.imageId(configPath)
      return imageId && this.imageUrl(imageId)
    }
  }

  @Action({ rawError: true })
  async getAllImages() {
    this.setAllImages(await getAllImages(this.appRank))
  }
}

export const SecondaryImagesModule = getModule(SecondaryImages)
