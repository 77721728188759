import { getNodes } from '@/api'
import store from '@/store'
import Nodes from '@/store/base/entities/nodes'
import { AppRank, AppRanks, NodeData, NodeResponse } from '@/types'
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators'

export interface ISecondaryNodesState {
  fromApi: NodeResponse[]
}

@Module({ dynamic: true, store, name: 'secondaryNodes', namespaced: true })
export default class SecondaryNodes extends Nodes implements ISecondaryNodesState {
  fromApi: NodeResponse[] = [] // array of objects, each having {path: , parent: , config: , storyKeys: stories, ...}

  // Getters
  get appRank(): AppRank {
    return AppRanks.SECONDARY
  }

  get allNodes(): NodeData[] {
    return this.fromApi
  }

  get removedNodes(): string[] {
    return []
  }

  // Mutations
  @Mutation
  setNodesFromApi(payload: NodeResponse[]) {
    this.fromApi = payload
  }

  // Actions
  @Action({ rawError: true })
  async getNodes() {
    this.setNodesFromApi(await getNodes(this.appRank))
  }
}

export const SecondaryNodesModule = getModule(SecondaryNodes)
