import {
  AlertPlugin,
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  ButtonToolbarPlugin,
  CardPlugin,
  CarouselPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  IconsPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin,
} from 'bootstrap-vue'
import { VueConstructor } from 'vue'

const bootstrapPlugins = [
  AlertPlugin,
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  ButtonToolbarPlugin,
  CardPlugin,
  CarouselPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  IconsPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin,
]

export default function registerBootstrapComponents(Vue: VueConstructor) {
  for (const item of bootstrapPlugins) {
    Vue.use(item)
  }
}
