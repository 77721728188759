import { getOrganisation } from '@/api'
import {
  DEFAULT_APP_NAME,
  DEFAULT_CONFIG,
  DEFAULT_FIELD_MAP_CONFIG,
  DEFAULT_ORG_NAME,
  DEFAULT_SEARCH_CONFIG,
  DEFAULT_VISUALISATION_CONFIG,
  FIELD_CALCULATIONS_CONFIG_KEY,
  FIELD_CALCULATIONS_NEW_CONFIG_KEY,
  NAME_ID_SEPARATOR,
  ROUTE_SIDEBAR_SETTINGS,
} from '@/constants'
import { LevelsHelper } from '@/store/levelsHelper'
import {
  AppResponse,
  AppTypes,
  DeepPartial,
  FieldCalculationConfig,
  FieldCalculationTypes,
  FieldMapItem,
  NavButtonDisplayTypes,
  NavButtonKeys,
  NavButtonsConfig,
  NavigationButtonDisplay,
  NavigationButtonDisplays,
  NodeResponse,
  Path,
  VersionConfig,
  VersionConfigResponse,
  VisualisationTypes,
} from '@/types'
import mergeOptions from 'merge-options'

export const getObjectProp = (p: (string | number)[], o: VersionConfig | NodeResponse) =>
  p.reduce(
    (xs, x) =>
      // @ts-ignore
      xs && xs[x as keyof VersionConfig | keyof NodeResponse]
        ? // @ts-ignore
          xs[x as keyof VersionConfig | keyof NodeResponse]
        : null,
    o,
  )

export function sortByPath(
  a: { path: Path },
  b: { path: Path },
  propertyAccessor: { (path: string): number | null },
) {
  const aOrder = propertyAccessor(a.toString()) || 0
  const bOrder = propertyAccessor(b.toString()) || 0
  return aOrder - bOrder
}

export function calculateNextIdFromNames(names: string[]): number {
  if (names.length) {
    const existingIds = names.map((name) => {
      const parsed = parseInt(name.split(NAME_ID_SEPARATOR).pop() || '0')
      if (isNaN(parsed)) {
        return 0
      }
      return parsed
    })
    return Math.max(...existingIds) + 1
  } else {
    return 1
  }
}

// app
export function getLocalStorage() {
  if (localStorage.getItem('designMode') === null) {
    localStorage.setItem('designMode', ROUTE_SIDEBAR_SETTINGS + '_new')
  }
}

// config
export async function addDefaultConfig(app: AppResponse, config: VersionConfigResponse) {
  const customConfig =
    app.appType === AppTypes.BUBBLES ? addVisualisationAppCustomConfig(config) : {}

  if (!Array.isArray(config.fieldMap)) {
    config.fieldMap = DEFAULT_FIELD_MAP_CONFIG
  }

  // The last options given to mergeOptions will overwrite earlier options
  const merged = mergeOptions(DEFAULT_CONFIG, customConfig, config) as VersionConfig

  // Should update appName and orgName on first load only
  if (merged.appConfig.appName === DEFAULT_APP_NAME) {
    merged.appConfig.appName = app.name
  }
  if (merged.appConfig.orgName === DEFAULT_ORG_NAME) {
    try {
      const org = await getOrganisation()
      if (org) {
        merged.appConfig.orgName = org.name
      }
    } catch {
      merged.appConfig.orgName = ''
    }
  }

  return merged
}

function addVisualisationAppCustomConfig(config: VersionConfigResponse) {
  // This is used to add new config to existing apps
  // Default config should have all the new config
  // Custom config should be used (reluctantly) to shift values around from the old config to the new
  const customConfig: DeepPartial<VersionConfig> = {
    appConfig: {
      projectLabelField: LevelsHelper.fromFieldMapItems(config.fieldMap).leafLevelField?.column,
    },
    searchSettings: {
      enableSearchByLocation: (config.searchSettings || DEFAULT_SEARCH_CONFIG).enabled,
      enableSearchForProjects: (config.searchSettings || DEFAULT_SEARCH_CONFIG).enabled,
    },
    numericFormatting: {
      locale:
        config?.appConfig?.numericFormatting?.locale || DEFAULT_CONFIG.numericFormatting.locale,
    },
    monetaryFormatting: {
      currency:
        config?.appConfig?.numericFormatting?.currency ||
        DEFAULT_CONFIG.monetaryFormatting.currency,
      numDigits:
        config?.appConfig?.numericFormatting?.numDigits ??
        DEFAULT_CONFIG.monetaryFormatting.numDigits,
    },
    bubblesLegends: {
      showLegend: config?.hollowBubbles?.showLegend || DEFAULT_CONFIG.bubblesLegends.showLegend,
      legendTitle: config?.hollowBubbles?.legendTitle || DEFAULT_CONFIG.bubblesLegends.legendTitle,
      legendPosition:
        config?.hollowBubbles?.legendPosition || DEFAULT_CONFIG.bubblesLegends.legendPosition,
    },
    fieldMap: ensureShowBubblesInFieldMap(config?.fieldMap),
    navButtons: migrateNavButtons(config),
    visualisation: migrateVisualisationConfig(config),
  }

  if (!(FIELD_CALCULATIONS_NEW_CONFIG_KEY in config) && FIELD_CALCULATIONS_CONFIG_KEY in config) {
    // Need to update FIELD_CALCULATIONS_CONFIG_KEY to FIELD_CALCULATIONS_NEW_CONFIG_KEY
    // @ts-ignore TODO
    customConfig.fieldCalculationsV2Format = migrateFieldCalc(config.fieldCalculations)
  }

  if (!config.popupConfig) {
    const { appConfig, popupItems } = config
    const { showPopupChart, showPopupTotal, popupTitleField, popupSubtitleField } = appConfig ?? {}
    customConfig.popupConfig = {
      global: {
        showChart: showPopupChart,
        showTotal: showPopupTotal,
        titleField: popupTitleField,
        subtitleField: popupSubtitleField,
        items: popupItems,
      },
    }
  }

  return customConfig
}

function ensureShowBubblesInFieldMap(fieldMap: FieldMapItem[]) {
  // Ensure the leaf level always has "show as bubbles" enabled
  const fields = fieldMap.filter((f) => f.level).sort((a, b) => (a.level || 0) - (b.level || 0))
  if (fields.length) {
    const maxLevelField = fields[fields.length - 1]
    if (!maxLevelField.showBubbles) {
      maxLevelField.showBubbles = true
    }
  }

  return fieldMap
}

function migrateFieldCalc(oldFieldCalcConfig: FieldCalculationConfig[]) {
  return oldFieldCalcConfig.map((field) => {
    return {
      ...field,
      // @ts-ignore TODO
      definitions: field.definitions.map((def) => {
        if (def.includes('__')) {
          const [functionValue, column] = def.split('__')
          return {
            type: FieldCalculationTypes.FUNCTION,
            function: functionValue,
            column,
          }
        } else if (!isNaN(def)) {
          return {
            type: FieldCalculationTypes.NUMBER,
            number: def,
          }
        } else {
          return {
            type: FieldCalculationTypes.OPERATOR,
            operator: def,
          }
        }
      }),
    }
  })
}

function mapNavButtonDisplayType(from: NavigationButtonDisplay) {
  switch (from) {
    case NavigationButtonDisplays.ON:
      return NavButtonDisplayTypes.STANDALONE
    case NavigationButtonDisplays.DROPDOWN:
      return NavButtonDisplayTypes.MENU_ITEM
    default:
      return NavButtonDisplayTypes.STANDALONE
  }
}

function migrateNavButtons(config: VersionConfigResponse) {
  return (
    config?.navButtons ||
    ({
      [NavButtonKeys.INFO]: {
        key: NavButtonKeys.INFO,
        displayType: mapNavButtonDisplayType(config?.navigation?.buttonConfig?.faq?.display),
        order: config?.navigation?.buttonConfig?.faq?.order || 0,
        icon: config?.faq?.buttonIcon,
        label: config?.faq?.buttonName,
      },
      [NavButtonKeys.SEARCH]: {
        key: NavButtonKeys.SEARCH,
        displayType: mapNavButtonDisplayType(
          config?.navigation?.buttonConfig?.searchSettings?.display,
        ),
        order: config?.navigation?.buttonConfig?.searchSettings?.order || 0,
        icon: config?.searchSettings?.icon,
        label: config?.searchSettings?.searchName,
      },
      [NavButtonKeys.DOWNLOAD]: {
        key: NavButtonKeys.DOWNLOAD,
        displayType: mapNavButtonDisplayType(config?.navigation?.buttonConfig?.download?.display),
        order: config?.navigation?.buttonConfig?.download?.order || 0,
        icon: config?.download?.buttonIcon,
        label: config?.download?.buttonName,
      },
      [NavButtonKeys.MAP]: {
        key: NavButtonKeys.MAP,
        displayType: NavButtonDisplayTypes.VISUALISATION,
        order: config?.navigation?.buttonConfig?.showMap?.order || 0,
        icon: config?.navigation?.mapIcon,
        label: config?.navigation?.mapName,
      },
      [NavButtonKeys.BUBBLES]: {
        key: NavButtonKeys.BUBBLES,
        displayType: NavButtonDisplayTypes.VISUALISATION,
        order: config?.navigation?.buttonConfig?.showMap?.order || 0,
        icon: config?.navigation?.bubblesIcon,
        label: config?.navigation?.bubblesName,
      },
      [NavButtonKeys.MENU]: {
        key: NavButtonKeys.MENU,
        displayType: NavButtonDisplayTypes.STATIC,
        order: -1,
        icon: config?.navigation?.buttonIcon,
        label: config?.navigation?.buttonName,
      },
      [NavButtonKeys.FILTER]: {
        key: NavButtonKeys.FILTER,
        displayType: mapNavButtonDisplayType(
          config?.navigation?.buttonConfig?.filterByCategory?.display,
        ),
        order: config?.navigation?.buttonConfig?.filterByCategory?.order || 0,
        icon: config?.filterByCategory?.buttonIcon,
        label: config?.filterByCategory?.buttonName,
      },
    } as NavButtonsConfig)
  )
}

function migrateVisualisationConfig(config: VersionConfigResponse) {
  const { visualisation = DEFAULT_VISUALISATION_CONFIG, showMap = true } = config
  const { defaultVisualisationType, type, activeVisualisationTypes } = visualisation

  const visualisationType =
    defaultVisualisationType || type || DEFAULT_CONFIG.visualisation.defaultVisualisationType

  const userSelectableTypes = activeVisualisationTypes || [visualisationType]

  if (!activeVisualisationTypes && showMap) {
    userSelectableTypes.push(VisualisationTypes.MAP)
  }

  return {
    defaultVisualisationType: visualisationType,
    activeVisualisationTypes: userSelectableTypes,
  }
}

// titleDescriptionGetters

export function stripHtml(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const nameof = <T>(name: Extract<keyof T, string>): string => name
