import { VISUALISATION_VIEWBOX_SIZE } from '@/constants'
import store from '@/store'
import { Coordinate2D, Dimensions } from '@/types'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface ISizeState {
  msieversion: Number | false
  windowHeight: Number
  clientWidth: Number
  clientHeight: Number
  appNavHeight: Number
  mainContentWidth: Number
  mobileStoryWidth: Number
  sidebarContainerWidth: Number
  sidebarWidgetsContainerWidth: Number
  sidetabsContainerWidth: Number
  navButtonsWidth: Number
  mainPanelDimensions: Dimensions
  bubblesContainerDimensions: Dimensions
  bubblesZoomFactor: number
  bubblesDragOffset: Coordinate2D
  bubblesMinSizeSquareOffset: Coordinate2D
  bubblesVisualisationScaleFactor: number
}

@Module({ dynamic: true, store, name: 'size', namespaced: true })
export default class Size extends VuexModule implements ISizeState {
  msieversion: number | false = false
  windowHeight = 0
  clientWidth = 0
  clientHeight = 0
  appNavHeight = 0
  mainContentWidth = 0
  sidebarContainerWidth = 0
  sidebarWidgetsContainerWidth = 0
  mobileStoryWidth = 0
  sidetabsContainerWidth = 0
  navButtonsWidth = 0
  mainPanelDimensions: Dimensions = { width: 0, height: 0 }
  bubblesContainerDimensions: Dimensions = { width: 0, height: 0 }
  bubblesZoomFactor: number = 1
  bubblesDragOffset: Coordinate2D = { x: 0, y: 0 }
  bubblesMinSizeSquareOffset: Coordinate2D = { x: 0, y: 0 }
  bubblesVisualisationScaleFactor: number = 1

  @Mutation
  setMSIEversion() {
    this.msieversion = detectIE()
  }

  @Mutation
  updateWindowHeight() {
    this.windowHeight = window.innerHeight
  }

  @Mutation
  updateClientWidth() {
    this.clientWidth = document.documentElement.clientWidth
  }

  @Mutation
  updateClientHeight() {
    this.clientHeight = document.documentElement.clientHeight
  }

  @Mutation
  updateAppNavHeight() {
    const ele = document.querySelector('#appNav')
    this.appNavHeight = ele ? ele.clientHeight : 0
  }

  @Mutation
  updateMainContentWidth() {
    const ele = document.querySelector('#mainContent')
    this.mainContentWidth = ele ? ele.clientWidth : 0
  }

  @Mutation
  updateSidebarContainerWidth() {
    const ele = document.querySelector('#sidebarContainer')
    this.sidebarContainerWidth = ele ? ele.clientWidth : 0
  }

  @Mutation
  updateSidebarWidgetsContainerWidth() {
    const ele = document.querySelector('#sidebar-widgets-container')
    this.sidebarWidgetsContainerWidth = ele ? ele.clientWidth : 0
  }

  @Mutation
  updateSidetabsContainerWidth() {
    const ele = document.querySelector('#sidetabsContainer')
    this.sidetabsContainerWidth = ele ? ele.clientWidth : 0
  }

  @Mutation
  updateNavButtonsWidth() {
    const ele = document.querySelector('#nav-buttons')
    this.navButtonsWidth = ele ? ele.clientWidth : 0
  }

  @Mutation
  updateMainPanelDimensions() {
    const mainPanel = document.querySelector('#main-panel')
    if (mainPanel) {
      this.mainPanelDimensions = {
        width: mainPanel?.clientWidth ?? 0,
        height: mainPanel?.clientHeight ?? 0,
      }
    }
  }

  @Mutation
  updateBubblesContainerDimensions() {
    const bubblesContainer = document.querySelector('#bubblesContainer')
    this.bubblesContainerDimensions = {
      width: bubblesContainer?.clientWidth ?? 0,
      height: bubblesContainer?.clientHeight ?? 0,
    }
  }

  @Mutation
  updateMobileStoryWidth() {
    const storyContainer = document.querySelector('#mobileStoryContainer')
    this.mobileStoryWidth = storyContainer?.clientWidth ?? 0
  }

  @Mutation
  updateBubblesDragOffset(offset: Coordinate2D) {
    this.bubblesDragOffset = { ...offset }
  }

  @Mutation
  updateBubblesMinSizeSquareOffset() {
    const bubblesMinSizeSquare = document.querySelector('#bubblesMinSizeSquare')
    this.bubblesMinSizeSquareOffset = {
      x:
        (this.bubblesContainerDimensions.width - (bubblesMinSizeSquare?.clientWidth ?? 0)) / 2 +
        this.bubblesDragOffset.x,
      y:
        (this.bubblesContainerDimensions.height - (bubblesMinSizeSquare?.clientHeight ?? 0)) / 2 +
        this.bubblesDragOffset.y,
    }
  }

  @Mutation
  updateBubblesZoomFactor(zoomFactor: number) {
    this.bubblesZoomFactor = zoomFactor
  }

  @Mutation
  updateBubblesVisualisationScaleFactor() {
    this.bubblesVisualisationScaleFactor =
      (Math.min(this.bubblesContainerDimensions.width, this.bubblesContainerDimensions.height) /
        VISUALISATION_VIEWBOX_SIZE) *
      this.bubblesZoomFactor
  }

  get contentHeight() {
    return this.windowHeight - this.appNavHeight
  }

  @Action({ rawError: true })
  recalculate() {
    if (this.msieversion) {
      // Only needed for IE fix in HeaderPanel
      this.updateSidebarContainerWidth()
      this.updateSidetabsContainerWidth()
      this.updateMainContentWidth()
      this.updateNavButtonsWidth()
    }
    this.updateMobileStoryWidth()
    this.updateSidebarWidgetsContainerWidth()
    this.updateWindowHeight()
    this.updateClientWidth()
    this.updateClientHeight()
    this.updateMainPanelDimensions()
    this.updateAppNavHeight()
  }

  @Action({ rawError: true })
  setBubblesDragOffset(offset: Coordinate2D) {
    this.updateBubblesDragOffset(offset)
    this.updateBubblesMinSizeSquareOffset()
  }

  @Action({ rawError: true })
  setBubblesZoomFactor(zoomFactor: number) {
    this.updateBubblesZoomFactor(zoomFactor)
    this.updateBubblesVisualisationScaleFactor()
  }
}

function detectIE() {
  // returns version of IE or false, if browser is not Internet Explorer
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

export const SizeModule = getModule(Size)
