import {
  Bubble,
  BubbleCircle,
  bubblesVisualisationScaleFactor,
  hidePopup,
  RenderedBubble,
  RenderedBubbleGroup,
  RenderedBubblePulse,
  showPopup,
} from '@/components/public/Visualisations/Bubbles'
import { UI_EVENTS } from '@/constants'
import { VisNode } from '@/types'
import { color, RGBColor } from 'd3-color'
import Konva from 'konva'

class BubblesShapesService {
  // the bubbles property should always represent the bubbles that are displayed on the visualisation
  private _bubbles: Record<string, Bubble> = {}
  private _bubbleGroups: Record<string, RenderedBubbleGroup> = {}

  reset() {
    this._bubbles = {}
    this._bubbleGroups = {}
  }

  addBubble(id: string, bubble: Bubble) {
    this._bubbles[id] = bubble
  }

  addBubbleGroup(id: string, bubbleGroup: RenderedBubbleGroup) {
    this._bubbleGroups[id] = bubbleGroup
  }

  removeBubble(id: string) {
    delete this._bubbles[id]
  }

  removeBubbleGroup(id: string) {
    delete this._bubbleGroups[id]
  }

  getBubble(id: string): Bubble {
    return this._bubbles[id]
  }

  getBubbleGroup(id: string): RenderedBubbleGroup {
    return this._bubbleGroups[id]
  }

  get bubbles() {
    return Object.values(this._bubbles)
  }

  get bubbleGroups() {
    return Object.values(this._bubbleGroups)
  }

  buildBubbleCircle(renderedBubble: RenderedBubble, node: VisNode): BubbleCircle {
    const circle = new BubbleCircle(renderedBubble, node)
    circle.on(UI_EVENTS.MouseOver, () => showPopup(circle))
    circle.on(UI_EVENTS.MouseOut, () => hidePopup())
    return circle
  }

  buildBubbleHaloCircle(bubble: RenderedBubble): Konva.Circle {
    return new Konva.Circle({
      id: bubble.id,
      name: 'halo',
      perfectDrawEnabled: false, // "perfect draw" has a considerable impact on performance for little to no image quality improvement
    })
  }

  buildBubblePulseCircle(bubble: RenderedBubble): Konva.Circle {
    return new Konva.Circle({
      id: bubble.id,
      name: 'pulse',
      perfectDrawEnabled: false, // "perfect draw" has a considerable impact on performance for little to no image quality improvement
    })
  }

  buildBubblePulseAnimation(pulse: RenderedBubblePulse, circle: Konva.Circle): Konva.Animation {
    const pulseDuration = pulse.duration * 1000
    const pulseStrokeWidth = pulse.strokeWidth * bubblesVisualisationScaleFactor()
    const pulseStrokeOpacity = pulse.strokeOpacity
    return new Konva.Animation((frame) => {
      const normalizedTime = (frame!.time % pulseDuration) / pulseDuration // Normalized time [0, 1]

      let strokeWidth
      let strokeOpacity

      // Dividing animation into phases according to the CSS keyframes
      if (normalizedTime < 0.25) {
        strokeWidth = 0
        strokeOpacity = this._interpolateBetweenValues(0, pulseStrokeOpacity, normalizedTime / 0.25)
      } else if (normalizedTime < 0.5) {
        strokeWidth = this._interpolateBetweenValues(
          0,
          pulseStrokeWidth,
          (normalizedTime - 0.25) / 0.25,
        )
        strokeOpacity = this._interpolateBetweenValues(
          pulseStrokeOpacity,
          0,
          (normalizedTime - 0.25) / 0.25,
        )
      } else {
        strokeWidth = this._interpolateBetweenValues(
          pulseStrokeWidth,
          0,
          (normalizedTime - 0.5) / 0.5,
        )
        strokeOpacity = 0
      }

      circle.strokeWidth(strokeWidth)
      const stroke = color(pulse.strokeColor) as RGBColor
      stroke.opacity = strokeOpacity

      circle.stroke(stroke.formatRgb())
    }, circle.getLayer())
  }

  private _interpolateBetweenValues(
    startValue: number,
    endValue: number,
    interpolationFactor: number,
  ): number {
    return (1 - interpolationFactor) * startValue + interpolationFactor * endValue
  }
}

export const bubblesShapesService = new BubblesShapesService()
