<template>
  <div ref="wrapper" class="histogram-wrapper" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import c3 from 'c3'

const props = {
  yAxisName: {
    type: String,
    default: 'Data',
  },
  data: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
  cols: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
}

@Component
export default class HistogramSparkline extends Vue.extend({ props }) {
  mounted() {
    const ref = this.$refs.wrapper as HTMLElement
    return c3.generate({
      bindto: ref,
      data: {
        columns: [[this.yAxisName, ...this.data]],
        type: 'bar',
        colors: {
          [this.yAxisName]: 'black',
        },
      },
      bar: {
        width: {
          ratio: 1,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        grouped: false,
        position: function () {
          return { top: 30, left: -50 }
        },
        contents: (d) => {
          return `${d[0].value} ${this.yAxisName.toLowerCase()} from ${this.cols[d[0].index]}`
        },
        format: {
          title: (d) => {
            return this.cols[d as number]
          },
          value: function (value, ratio, id) {
            return value?.toString()
          },
        },
      },
      axis: {
        x: { show: false },
        y: { show: false },
      },
      size: { height: 30, width: 50 },
      point: {
        show: false,
      },
    })
  }
}
</script>

<style lang="scss">
.histogram-wrapper {
  width: 50px;
  .c3-tooltip-container {
    background: white;
    border: 1px solid #455;
    width: fit-content;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    padding: 0.5em 1em;
  }
}
</style>
