import App from '@/store/base/app'
import Nodes from '@/store/base/entities/nodes'
import { ImagesSource, NodeConfigKey } from '@/types'
import { Mutation, VuexModule } from 'vuex-module-decorators'

export interface IImagesState {
  allImages: ImagesSource
}

export default abstract class Images extends VuexModule implements IImagesState {
  allImages: ImagesSource = {}

  abstract get app(): App
  abstract get nodes(): Nodes

  abstract getAllImages(): Promise<void>
  abstract get imageOrPreview(): (configPath: string[]) => any

  @Mutation
  setAllImages(payload: ImagesSource) {
    this.allImages = payload
  }

  get image() {
    return (key: number) => {
      return this.allImages[key]
    }
  }

  get imageUrl() {
    return (imageId: number) => {
      return this.image(imageId)
    }
  }

  get imageId() {
    return (configPath: string[]) => {
      const type = configPathType(configPath)
      if (type === CONFIG_PATH_IS_NODE) {
        const storyPath = configPath.slice(1, -1).join('/')
        const propName = configPath[configPath.length - 1] as NodeConfigKey
        const obj = this.nodes.nodeGetter(storyPath, propName) as { img: number } | undefined
        return obj && obj.img
      } else if (type === CONFIG_PATH_IS_CONFIG) {
        const obj = this.app.nestedConfig(configPath.slice(1))
        return obj && obj.img
      } else {
        return undefined
      }
    }
  }
}

export const CONFIG_PATH_IS_NODE = 'node'
export const CONFIG_PATH_IS_CONFIG = 'config'

export function configPathType(configPath: string[]) {
  if (configPath[0] === 'node' && configPath[configPath.length - 1]) {
    return CONFIG_PATH_IS_NODE
  } else if (configPath[0] === 'config') {
    return CONFIG_PATH_IS_CONFIG
  } else {
    return null
  }
}
