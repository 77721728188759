import { getAppByRank, getConfigByAppRank, getVersionStatus } from '@/api'
import store from '@/store'
import App, { IAppState } from '@/store/base/app'
import Images from '@/store/base/entities/images'
import Nodes from '@/store/base/entities/nodes'
import { addDefaultConfig, getLocalStorage } from '@/store/helpers'
import { useFeedbackStore } from '@/store/public/feedback'
import typedStore from '@/store/typedStore'
import { AppRank, AppRanks, SecondaryAppReferenceNode } from '@/types'
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators'

interface ISecondaryAppState extends IAppState {
  dataLoading: boolean
  dataLoaded: boolean
  loadedAppId: number | null
}

export interface ISecondaryAppModuleState {
  appState: ISecondaryAppState
}

@Module({ dynamic: true, store, name: 'secondaryApp', namespaced: true })
export default class SecondaryApp extends App implements ISecondaryAppModuleState {
  appState: ISecondaryAppState = this.initialState()

  get initialState(): () => ISecondaryAppState {
    return () => ({
      ...this.initialBaseState,
      dataLoading: false,
      dataLoaded: false,
      loadedAppId: null,
    })
  }

  @Action({ rawError: true })
  resetState() {
    this.setState(this.initialState())
  }

  @Mutation
  setState(state: ISecondaryAppState) {
    this.appState = state
  }

  get appRank(): AppRank {
    return AppRanks.SECONDARY
  }

  get activeSecondaryAppReferenceNode(): SecondaryAppReferenceNode | null {
    if (typedStore.activeVisualisation.visualisationAppRank === AppRanks.SECONDARY) {
      return (
        typedStore.primary.entities.nodes.secondaryAppReferenceNodes.find(
          (node) => node.path === typedStore.public.display.activeTab?.secondaryAppPath,
        ) ?? null
      )
    }
    return null
  }

  get currentAppId(): number | null {
    return this.activeSecondaryAppReferenceNode?.config.secondaryAppId ?? null
  }

  get nodes(): Nodes {
    return typedStore.secondary.entities.nodes
  }

  get images(): Images {
    return typedStore.secondary.entities.images
  }

  @Action({ rawError: true })
  async initialise() {
    try {
      if (!this.appState.loadedAppId || this.appState.loadedAppId !== this.currentAppId) {
        // If a secondary app has already been loaded then the current secondary app
        // is a new app and the state needs to be reset
        if (this.appState.loadedAppId) {
          this.resetState()
        }

        // Always reset app load failed
        this.setAppLoadFailed(false)

        if (!this.appState.dataLoaded && !this.appState.dataLoading) {
          await this.getAllData()
          typedStore.public.search.resetState()
        }
      }

      if (this.appState.dataLoaded) {
        // Check story path is valid; else redirect to initial tab (first visible tab)
        // storyPathToArrayGuard will ensure secondaryStoryPath is string[] but not reflected in vue-router types
        const storyPath = typedStore.currentRoute.secondaryStoryPath
        const fullStoryPath = storyPath && storyPath.join('/')

        const validPath = this.isValidPath(fullStoryPath)

        if (!validPath) {
          typedStore.public.display.activateInitialTab({
            dataTabsOnly: false,
            primaryAppOnly: false,
          })
        }

        await useFeedbackStore().populateExistingFeedback(this.appRank, this.currentAppId!)
        this.setLoadedAppId(this.currentAppId!)
        this.setAppLoaded(true)
      }
    } catch {
      this.setAppLoadFailed(true)
      this.setDataLoaded(false)
      this.setDataLoading(false)
    } finally {
      typedStore.public.display.setSwitchingSecondaryApps(false)
    }
  }

  @Mutation
  setLoadedAppId(id: number) {
    this.appState.loadedAppId = id
  }

  @Action({ rawError: true })
  async getAllData() {
    getLocalStorage()
    this.setDataLoading(true)
    this.setDataLoaded(false)

    await this.getApp()
    await this.getVersionStatus()
    await Promise.all([this.getConfig(), this.images.getAllImages(), this.nodes.getNodes()])
    await typedStore.visualisationData.getTreeData()

    this.setDataLoading(false)
    this.setDataLoaded(true)
  }

  get isValidPath() {
    return (path: string | undefined) => {
      const secondaryAppPath = this.activeSecondaryAppReferenceNode?.path
      return Boolean(
        path &&
          ((secondaryAppPath &&
            typedStore.primary.entities.tabs.searchTabsByPath(path, secondaryAppPath)) ||
            typedStore.visualisationData.treeRoot.search(path)),
      )
    }
  }

  @Action({ rawError: true })
  async getApp() {
    const app = await getAppByRank(this.appRank)
    if (!app) throw new Error('Secondary app not found')
    this.setApp(app)
  }

  @Mutation
  setDataLoading(payload: boolean) {
    this.appState.dataLoading = payload
  }

  @Mutation
  setDataLoaded(payload: boolean) {
    this.appState.dataLoaded = payload
  }

  @Action({ rawError: true })
  async getVersionStatus() {
    const status = await getVersionStatus(this.appRank)
    this.setAppLoadFailed(status === 0)
  }

  // config
  @Action({ rawError: true })
  async getConfig() {
    const config = await getConfigByAppRank(this.appRank)
    this.setConfig(await addDefaultConfig(this.appState.app, config))
  }
}

export const SecondaryAppModule = getModule(SecondaryApp)
