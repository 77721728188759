import { DEFAULT_STORY_KEY, PATH_ROOT, PATH_SUBROOT } from '@/constants'
import { PathItem, SecondaryAppTabData } from '@/store/primary/entities/tabs'
import {
  NodeData,
  NodeStoryKeys,
  NodeTypes,
  StoryKeys,
  Tab,
  VersionConfig,
  VisualisationTypes,
} from '@/types'

const rootPaths: PathItem[] = [
  { path: PATH_ROOT, text: 'Root' },
  { path: PATH_SUBROOT, text: 'Subroot' },
]

export function calculateRootTabPaths(config: VersionConfig | undefined): PathItem[] {
  // Filter out subroot tab if sunburst is chosen

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce#replace_.filter.map_with_.reduce
  // Replace .filter().map() with .reduce()
  // Using Array.filter() then Array.map() traverses the array twice,
  // but you can achieve the same effect while traversing only once with Array.reduce(),
  // thereby being more efficient.
  const { type } = config?.visualisation ?? { type: VisualisationTypes.BUBBLES }
  const hideSubrootTab = config?.appConfig?.hideSubrootTab ?? false

  return rootPaths.reduce((tabs, { path, ...currentValue }) => {
    const isSunburst = type === VisualisationTypes.SUNBURST
    const isSubroot = path === PATH_SUBROOT
    // subroot is always hidden on sunburst
    const pathHidden = isSubroot && (hideSubrootTab || isSunburst)

    if (!pathHidden) {
      tabs.push({ path, name: path, ...currentValue })
    }

    return tabs
  }, [] as PathItem[])
}

export function filterTabNodes(nodes: NodeData[]) {
  return nodes.filter((x) => {
    const parentIsRoot = x.parent === PATH_ROOT
    const nodeTypeIsCustom = x.config?.nodeType === NodeTypes.CUSTOM
    const nodeTypeIsApp = x.config?.nodeType === NodeTypes.APP
    const nodeIsRootPaths = rootPaths.map((y) => y.path).includes(x.path)
    const nodeIsDefaultLevelStory =
      x.path.startsWith(DEFAULT_STORY_KEY) || x.path.startsWith('defaultStoryTier') // TODO deleteme when key no longer in prod db

    return (
      (parentIsRoot || nodeTypeIsCustom || nodeTypeIsApp) &&
      !nodeIsRootPaths &&
      !nodeIsDefaultLevelStory
    )
  })
}

export function buildTab(
  node: NodeData,
  config: VersionConfig,
  secondaryAppTabData: SecondaryAppTabData | undefined = undefined,
): Tab {
  return {
    path: node.path,
    name: node.config?.name,
    nodeType: node.config?.nodeType ?? NodeTypes.DATA,
    label: node.config?.label,
    visible: node.config?.visible ?? true,
    colour: node.config?.colour,
    tabFontColor: getTabFontColor(node.config?.fontColour, StoryKeys.SIDEBAR_BOT, config),
    customiseTabFontColour: config.appConfig.customiseTabFontColour,
    showIcon: config.appConfig.showIcon,
    showLabel: config.appConfig.showLabel,
    showMobileIcon: config.appConfig.showMobileIcon,
    showMobileLabel: config.appConfig.showMobileLabel,
    iconId: node.config?.icon?.img,
    secondaryAppPath: secondaryAppTabData?.secondaryAppPath,
    secondaryAppId: secondaryAppTabData?.secondaryAppId,
    isSecondaryInitialTab: secondaryAppTabData?.isSecondaryInitialTab,
    barColour: secondaryAppTabData?.barColour,
  } as Tab
}

export function getTabFontColor(
  nodeFontColour: string | undefined,
  panel: Exclude<
    NodeStoryKeys,
    typeof StoryKeys.CUSTOM_DESCRIPTION | typeof StoryKeys.CUSTOM_TITLE
  >,
  config: VersionConfig,
) {
  const customiseTabFontColour = config.appConfig?.customiseTabFontColour
  const sidebarPanelFontColor = config.storySettings[panel].fontColor
  return (customiseTabFontColour && nodeFontColour) || sidebarPanelFontColor
}
