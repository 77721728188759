import { DEFAULT_VISUALISATION_CONFIG, PATH_SUBROOT } from '@/constants'
import store from '@/store'
import { useFeaturesStore } from '@/store/common/features'
import typedStore from '@/store/typedStore'
import {
  AppRank,
  AppRanks,
  BubblesPopupDetails,
  NodeTypes,
  Path,
  Tab,
  TreeNode,
  VisualisationType,
  VisualisationTypes,
} from '@/types'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'activeVisualisation', namespaced: true })
export default class ActiveVisualisation extends VuexModule {
  appRank: AppRank = AppRanks.PRIMARY
  bubblesPopupDetails?: BubblesPopupDetails
  lastActiveVisualisationType?: VisualisationType
  activeVisualisationTypeByAppId: { [appId: number]: VisualisationType } = JSON.parse(
    localStorage.getItem('activeVisualisationTypes') || '{}',
  )

  @Mutation
  setVisualisationAppRank(appRank: AppRank) {
    this.appRank = appRank
  }

  @Mutation
  setLastActiveVisualisationType(visualisationType: VisualisationType) {
    this.lastActiveVisualisationType = visualisationType
  }

  @Mutation
  setActiveVisualisationType(payload: { appId: number; visualisationType: VisualisationType }) {
    const { appId, visualisationType } = payload
    Vue.set(this.activeVisualisationTypeByAppId, appId, visualisationType)
    localStorage.setItem(
      'activeVisualisationTypes',
      JSON.stringify(this.activeVisualisationTypeByAppId),
    )

    this.lastActiveVisualisationType = visualisationType
  }

  get visualisationAppRank(): AppRank {
    return this.appRank
  }

  get isPrimaryApp(): boolean {
    return this.visualisationAppRank === AppRanks.PRIMARY
  }

  get isSecondaryApp(): boolean {
    return this.visualisationAppRank === AppRanks.SECONDARY
  }

  get appLoaded() {
    let result: Boolean = false
    if (this.isPrimaryApp) {
      result = typedStore.primary.app.appLoaded
    } else if (this.isSecondaryApp) {
      result = typedStore.primary.app.appLoaded && typedStore.secondary.app.appLoaded
    }
    return result
  }

  get appId() {
    return this.isSecondaryApp ? typedStore.secondary.app.app.id : typedStore.primary.app.app.id
  }

  get activeTabTreeNode(): TreeNode | false {
    const activeTab = typedStore.public.display.activeTab
    if (activeTab) {
      if (activeTab.path === PATH_SUBROOT) {
        return new TreeNode({ name: PATH_SUBROOT, path: PATH_SUBROOT })
      }
      return typedStore.visualisationData.treeRoot.search(activeTab.path)
    }
    return false
  }

  get visualisationConfig() {
    return this.visualisationStore.app.config?.visualisation ?? DEFAULT_VISUALISATION_CONFIG
  }

  get defaultVisualisationType() {
    const defaultVisualisationType = this.visualisationConfig.defaultVisualisationType

    return this.activeVisualisationTypes.includes(defaultVisualisationType)
      ? defaultVisualisationType
      : this.activeVisualisationTypes[0]
  }

  get visualisationType(): VisualisationType {
    const activeTab = typedStore.public.display.activeTab
    if (this.isPrimaryApp && typedStore.primary.app.isConsolidatedApp && !activeTab) {
      return VisualisationTypes.NONE
    }

    if (activeTab && activeTab.nodeType === NodeTypes.CUSTOM) {
      return VisualisationTypes.CUSTOM
    }

    let visualisationType: VisualisationType | undefined
    if (this.isSecondaryApp) {
      visualisationType = this.lastActiveVisualisationType
    }
    if (!visualisationType || !this.activeVisualisationTypes.includes(visualisationType)) {
      visualisationType = this.activeVisualisationType
    }
    if (!visualisationType || !this.activeVisualisationTypes.includes(visualisationType)) {
      visualisationType = this.defaultVisualisationType
    }

    return visualisationType
  }

  get activeVisualisationTypes() {
    return this.visualisationConfig.activeVisualisationTypes.filter((value) => {
      if (value === VisualisationTypes.NESTED_BUBBLES) return useFeaturesStore().nestedBubbles
      if (value === VisualisationTypes.XY_BUBBLES) return useFeaturesStore().xyBubbles

      return true
    })
  }

  get activeVisualisationType(): VisualisationType | undefined {
    const activeVisualisationType = this.activeVisualisationTypeByAppId[this.appId]

    return this.activeVisualisationTypes.includes(activeVisualisationType)
      ? activeVisualisationType
      : undefined
  }

  get isBubbles() {
    return (
      this.visualisationStore.app.isVisualisationApp &&
      this.visualisationType === VisualisationTypes.BUBBLES
    )
  }

  get isMap() {
    return (
      this.visualisationStore.app.isVisualisationApp &&
      this.visualisationType === VisualisationTypes.MAP
    )
  }

  get isNestedBubbles() {
    return (
      this.visualisationStore.app.isVisualisationApp &&
      this.visualisationType === VisualisationTypes.NESTED_BUBBLES
    )
  }

  get isSunburst() {
    return (
      this.visualisationStore.app.isVisualisationApp &&
      this.visualisationType === VisualisationTypes.SUNBURST
    )
  }

  get isXYBubbles() {
    return (
      this.visualisationStore.app.isVisualisationApp &&
      this.visualisationType === VisualisationTypes.XY_BUBBLES
    )
  }

  get isCustom() {
    return this.visualisationType === VisualisationTypes.CUSTOM
  }

  get displaysTreeData() {
    return (
      this.isBubbles || this.isNestedBubbles || this.isSunburst || this.isMap || this.isXYBubbles
    )
  }

  get storyPath(): Path {
    return this.isSecondaryApp
      ? typedStore.public.display.secondaryStoryPath
      : typedStore.public.display.storyPath
  }

  get leafLevel(): number {
    return this.visualisationStore.app.levels.maxLevel
  }

  get visualisationStore() {
    if (this.isSecondaryApp) {
      return typedStore.secondary
    }
    return typedStore.primary
  }

  get visibleTabs() {
    if (this.isPrimaryApp) {
      return typedStore.primary.entities.tabs.visibleTabs.filter(
        (tab: Tab) => !tab.secondaryAppPath,
      )
    } else {
      return typedStore.primary.entities.tabs.visibleTabs.filter(
        (tab: Tab) =>
          tab.secondaryAppPath === typedStore.public.display.activeTab?.secondaryAppPath,
      )
    }
  }

  get visibleTabCount() {
    return typedStore.primary.entities.tabs.visibleTabs.length
  }

  get visibleTabHeight() {
    const height = typedStore.public.size.contentHeight
    const numberOfTabs = this.visibleTabCount
    return height / numberOfTabs
  }

  @Action({ rawError: true })
  updateStoryPath(payload: string | Path) {
    const path = payload.toString()
    if (this.isPrimaryApp) {
      typedStore.public.display.updatePrimaryStoryPath(path)
    } else {
      typedStore.public.display.updateSecondaryStoryPath({
        primaryStoryPath: typedStore.public.display.activeTab!.secondaryAppPath!,
        secondaryStoryPath: path,
      })
    }
  }

  @Mutation
  setActiveBubblesPopupDetails(popupDetails: BubblesPopupDetails | undefined) {
    this.bubblesPopupDetails = popupDetails
  }

  get activeBubblesPopupDetails(): BubblesPopupDetails | undefined {
    return this.bubblesPopupDetails
  }
}

export const ActiveVisualisationModule = getModule(ActiveVisualisation)
