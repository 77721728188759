// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
  prefix: 'custom',
  iconName: 'xy-bubbles',
  icon: [
    1024,
    1024,
    [],
    'f0000',
    'M 20,20 V 903.99609 1004 h 984 V 903.99609 H 120.00195 V 20 Z m 318.49609,71.884766 c -78.35877,0 -142.95703,64.596314 -142.95703,142.955074 0,78.35879 64.59825,142.95704 142.95703,142.95704 78.35879,0 142.95118,-64.59825 142.95118,-142.95704 0,-78.35876 -64.59239,-142.95507 -142.95118,-142.955074 z M 783.67383,272.22852 c -98.47464,0 -179.37891,80.90633 -179.37891,179.38086 0,98.47463 80.90431,179.37695 179.37891,179.37695 98.47459,0 179.37109,-80.90232 179.37109,-179.37695 0,-98.47453 -80.8965,-179.38086 -179.37109,-179.38086 z M 430.20117,609.77148 c -50.99304,0 -93.40234,42.41715 -93.40234,93.41016 0,50.99301 42.4093,93.4043 93.40234,93.4043 50.99305,0 93.40821,-42.41129 93.40821,-93.4043 0,-50.99301 -42.41516,-93.41016 -93.40821,-93.41016 z',
  ],
}
