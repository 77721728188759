<template>
  <div class="text-center">
    <b-spinner v-if="loading" label="Loading data" type="grow" variant="info" data-test="spinner" />
    <b-select
      v-show="displaySelect"
      v-model="selectedValue"
      :options="children"
      data-test="select"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { AppRank, NodeData, SelectOption } from '@/types'
import { getNodesByParentPath } from '@/api'
import { DEFAULT_SELECT_OPTION, PATH_SEPARATOR } from '@/constants'

const props = {
  selectedStoryPath: String as PropType<string | undefined>,
  appId: Number as PropType<number>,
  parentStoryPath: String as PropType<string>,
  appRank: String as PropType<AppRank>,
}

@Component({})
export default class ChildStorySelect extends Vue.extend({ props }) {
  loading: boolean = true
  childNodes: NodeData[] = []

  async created() {
    this.childNodes = await getNodesByParentPath(this.appId, this.parentStoryPath, this.appRank)
    this.loading = false
  }

  get children(): SelectOption[] {
    return this.mapNodes(this.childNodes)
  }

  get displaySelect() {
    return !this.loading && this.childNodes.length > 0
  }

  get selectedValue(): string | undefined {
    return this.selectedStoryPath
  }

  set selectedValue(newValue: string | undefined) {
    // if the selected child story path is undefined emit the parent story path
    this.$emit('input', newValue ?? this.parentStoryPath)
  }

  mapNodes(nodes?: NodeData[]): SelectOption[] {
    if (!nodes?.length) {
      return []
    }

    const options = nodes.map(({ path, config }) => ({
      value: path,
      text: config?.name ?? path.split(PATH_SEPARATOR).pop()!,
    }))

    return [DEFAULT_SELECT_OPTION, ...options]
  }
}
</script>
