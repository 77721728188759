<template>
  <node-view-wrapper v-if="link" as="span" :class="{ 'font-weight-bold': showTitle }">
    <template v-if="showTitle">
      <!-- no white space -->{{ nameTitle
      }}<!-- no white space -->
    </template>
    <template v-else>
      <!-- there should never be more than one link in the story due to dynamic link mention is hidden if there's more than one result -->
      <a
        v-for="dynamicLink in dynamicLinks"
        :key="dynamicLink"
        :href="`${dynamicLink}`"
        target="_blank"
      >
        <!-- no white space -->{{ dynamicLink
        }}<!-- no white space -->
      </a>
    </template>
  </node-view-wrapper>
  <node-view-wrapper v-else-if="id && showTitle" as="span" class="font-weight-bold">
    <!-- no white space -->{{ nameTitle
    }}<!-- no white space -->
  </node-view-wrapper>
  <node-view-wrapper v-else-if="id" as="span" :class="{ isEmpty }">
    <!-- eslint-disable vue/no-v-html -->
    <span v-html="renderedText" />
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { AUTO_TOTAL_FIELD_COLUMN, AUTO_TOTAL_FIELD_NAME } from '@/constants'

const components = {
  NodeViewWrapper,
}

@Component({ components })
export default class MentionView extends Vue.extend({ props: nodeViewProps }) {
  get name() {
    return this.$typedStore.visualisationApp.name
  }

  get mentionContent() {
    return this.$typedStore.visualisationApp.mentionContent
  }

  get uniqueStrings() {
    return this.$typedStore.visualisationApp.uniqueStrings
  }

  get isAutoTotal() {
    return this.id === AUTO_TOTAL_FIELD_COLUMN
  }

  get id() {
    return this.node.attrs.id
  }

  get link() {
    return this.node.attrs.link
  }

  get field() {
    if (this.isAutoTotal) {
      return {
        column: AUTO_TOTAL_FIELD_COLUMN,
        name: AUTO_TOTAL_FIELD_NAME,
      }
    }
    return this.$typedStore.visualisationApp.field(this.id)
  }

  get showTitle() {
    return this.editable || !this.field
  }

  get title() {
    if (this.field) {
      return this.field.name ? this.field.name : this.field.column
    } else {
      return this.id
    }
  }

  get nameTitle() {
    return `@${this.name(this.title)}`
  }

  get editable() {
    return this.editor.options.editable
  }

  get isEmpty() {
    return this.renderedText.length === 0
  }

  get renderedText() {
    return this.$sanitize(this.mentionContent(this.id))
  }

  get dynamicLinks() {
    return this.uniqueStrings(this.id)
  }
}
</script>

<style>
.isEmpty ~ img.ProseMirror-separator,
.isEmpty ~ br.ProseMirror-trailingBreak {
  display: none !important;
}
</style>
