import { FieldMapItem, LevelField } from '@/types'
import _maxBy from 'lodash/maxBy'
import _sortBy from 'lodash/sortBy'

export class LevelsHelper {
  private levelFields: LevelField[]

  constructor(levelFields: LevelField[]) {
    this.levelFields = levelFields
  }

  static fromFieldMapItems(fieldMap?: FieldMapItem[]) {
    return new LevelsHelper(
      fieldMap
        ?.filter((f) => f.level)
        ?.map((f) => ({
          ...f,
          level: f.level!,
        })) ?? [],
    )
  }

  fieldForLevel(level: number) {
    return this.levelFields.find((f) => f.level === level)
  }

  columnForLevel(level: number) {
    return this.fieldForLevel(level)?.column || ''
  }

  get leafLevelField() {
    return _maxBy(this.levelFields, (f) => f.level || 0)
  }

  get levels(): LevelField[] {
    return _sortBy(
      this.levelFields.filter((f) => f.level),
      (f) => f.level!,
    )
  }

  get maxLevel() {
    return this.levels[this.levels.length - 1]?.level ?? 1
  }
}
