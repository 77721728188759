<template>
  <node-view-wrapper>
    <div>
      <div class="open-dialog-button" @click="showModal = true">{{ text }}</div>
      <b-modal
        id="iframe-modal"
        v-model="showModal"
        size="xl"
        :hide-footer="true"
        :hide-header="true"
        :cancel-disabled="true"
      >
        <div class="close-button" @click="showModal = false">
          <font-awesome-icon icon="times" />
        </div>
        <iframe
          v-show="iframeLoaded"
          class="embed-responsive-item"
          style="width: 100%; height: 100%"
          :src="src"
          allowfullscreen
          @load="iframeLoaded = true"
        />
        <div
          v-if="!iframeLoaded"
          class="embed-responsive-item d-flex justify-content-center align-items-center"
        >
          <b-spinner
            label="Loading"
            variant="info"
            type="grow"
            style="width: min(100px, 50vw, 50vh); height: min(100px, 50vw, 50vh)"
          />
        </div>
      </b-modal>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { convertYoutubeUrl } from '../IframeExtension/helpers'

const components = {
  NodeViewWrapper,
}

@Component({ components })
export default class IframeDialogNodeView extends Vue.extend({ props: nodeViewProps }) {
  iframeLoaded = false
  showModal = false

  get src() {
    return this.node.attrs.src
  }

  get text() {
    return this.node.attrs.text
  }

  set src(src) {
    this.iframeLoaded = false
    this.updateAttributes({
      src: convertYoutubeUrl(src),
    })
  }
}
</script>

<style lang="scss">
.open-dialog-button {
  cursor: pointer;
  font-weight: 800;
  &:hover {
    text-decoration: underline;
  }
}
#iframe-modal {
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #000;
    background: rgba(255, 255, 255, 0.8);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .modal-dialog {
    max-width: calc(100% - 10px);
    margin: 0;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    height: calc(100vh - 10px);
    display: flex;
    position: fixed;
    z-index: 100000;
  }
}
</style>
