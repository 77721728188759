import { APP_CONFIG_KEY } from '@/constants'
import Images from '@/store/base/entities/images'
import Nodes from '@/store/base/entities/nodes'
import { getObjectProp, nameof, stripHtml } from '@/store/helpers'
import { LevelsHelper } from '@/store/levelsHelper'
import { getTabFontColor } from '@/store/tabsHelpers'
import typedStore from '@/store/typedStore'
import {
  AppRelationship,
  AppResponse,
  AppTypes,
  NodeStoryKeys,
  Path,
  StoryKeys,
  VersionConfig,
} from '@/types'
import { Editor } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Vue from 'vue'
import { Mutation, VuexModule } from 'vuex-module-decorators'

const _isEmpty = require('lodash.isempty')

export interface IAppState {
  app: AppResponse
  config: VersionConfig
  relationships: AppRelationship[]
  appLoaded: Boolean
  appLoadFailed: Boolean | null
  loadedVersion: Number | null
}

export interface IAppModuleState {
  appState: IAppState
}

export default abstract class App extends VuexModule implements IAppModuleState {
  abstract appState: IAppState

  abstract get nodes(): Nodes
  abstract get images(): Images

  // Implementing @Actions on a parent class results in any
  // @Mutations always resolving to 'undefined'.
  // @Actions must be implemented on child classes.
  abstract initialise(): Promise<void>
  abstract getApp(): Promise<void>
  abstract getVersionStatus(): Promise<void>
  abstract getConfig(): Promise<void>

  get initialBaseState(): IAppState {
    return {
      app: {} as unknown as AppResponse,
      config: {} as unknown as VersionConfig,
      relationships: [] as AppRelationship[],
      appLoaded: false,
      appLoadFailed: false,
      loadedVersion: null,
    }
  }

  get levels() {
    return LevelsHelper.fromFieldMapItems(this.config.fieldMap)
  }

  get app() {
    return this.appState.app
  }

  get relationships() {
    return this.appState.relationships
  }

  get config() {
    return this.appState.config
  }

  get appLoaded() {
    return this.appState.appLoaded
  }

  get appLoadFailed() {
    return this.appState.appLoadFailed
  }

  get loadedVersion() {
    return this.appState.loadedVersion
  }

  @Mutation
  setApp(app: AppResponse) {
    // We need to use Vue.set as app is initially set as an empty object
    // and subsequently has no reactivity to changes
    Vue.set(this.appState, nameof<IAppState>('app'), app)
  }

  get isConsolidatedApp() {
    return this.app.appType === AppTypes.CONSOLIDATED
  }

  get isVisualisationApp() {
    return this.app.appType === AppTypes.BUBBLES
  }

  get story() {
    return (path: Path, panel: NodeStoryKeys, showParent = true): string => {
      const nodes = this.nodes.allNodes
      if (nodes.length === 0) {
        return ''
      }

      if (this.isConsolidatedApp) {
        return typedStore.consolidatedApp.story(path, panel)
      }

      if (this.isVisualisationApp) {
        return typedStore.visualisationApp.story(path, panel, showParent)
      }

      return ''
    }
  }

  get isEmpty() {
    return (payload: any): boolean => {
      return _isEmpty(payload)
    }
  }

  // config
  @Mutation
  setConfig(payload: VersionConfig) {
    // We need to use Vue.set as config is initially set as an empty object
    // and subsequently has no reactivity to changes
    Vue.set(this.appState, nameof<IAppState>('config'), payload)
  }

  @Mutation
  setRelationships(payload: AppRelationship[]) {
    Vue.set(this.appState, nameof<IAppState>('relationships'), payload)
  }

  get nestedConfig() {
    return (configPath: string[]) => {
      // This getter takes an array of path strings to navigate the config object
      // eg ['treeDetails', 'Air/Cleaner home heating', 'order']
      // It then returns the final property, if that property exists
      const config = getObjectProp(configPath.slice(0, -1), this.appState.config)
      if (config) {
        // @ts-ignore
        return config[configPath.slice(-1)[0]] // error?
      } else {
        console.error(config, 'Invalid config path', configPath)
      }
    }
  }

  get tabFontColor() {
    return (
      tabPath: string,
      panel: Exclude<
        NodeStoryKeys,
        typeof StoryKeys.CUSTOM_DESCRIPTION | typeof StoryKeys.CUSTOM_TITLE
      >,
    ) => {
      const fontColour = this.nodes.fontColour(tabPath)
      return getTabFontColor(fontColour, panel, this.appState.config)
    }
  }

  // loaded
  @Mutation
  setAppLoadFailed(payload: boolean) {
    this.appState.appLoadFailed = payload
  }

  @Mutation
  setAppLoaded(payload: boolean) {
    this.appState.appLoaded = payload
  }

  @Mutation
  setLoadedVersion(payload: number) {
    this.appState.loadedVersion = payload
  }

  // titleDescriptionGetters
  get useCustomPageTitle() {
    return !!this.appState.config?.appConfig?.customPageTitle ?? false
  }

  get useCustomPageDescription() {
    return !!this.appState.config?.appConfig.customPageDescription
  }

  get pageTitle() {
    return this.useCustomPageTitle ? this.customTitle : this.defaultTitle
  }

  get pageDescription() {
    return this.useCustomPageDescription ? this.customDescription : this.defaultDescription
  }

  get defaultTitle(): string {
    if (this.isConsolidatedApp) {
      return `${this.appName} | ${this.orgName}`
    }

    if (this.isVisualisationApp) {
      return `${this.defaultLevelName} | ${this.appName} - ${typedStore.visualisationApp.mapOrProjectName} | ${this.orgName}`
    }

    return ''
  }

  get defaultLevelName() {
    const path = typedStore.activeVisualisation.storyPath

    const level1 =
      typedStore.activeVisualisation.visualisationStore.entities.nodes.label(path.level1) ||
      typedStore.activeVisualisation.visualisationStore.entities.nodes.name(path.level1) ||
      path.level1
    if (path.isRootOrSubroot || path.level === 1) {
      return level1
    }

    const otherLevels = path.parts.slice(1) // Get all but the first parts
    if (path.level < this.levels.levels.length) {
      return [level1, ...otherLevels].reverse().join(' | ')
    }

    const midLevels = path.parts.slice(1, -1) // Get all but the first and last parts
    const leafLevel = this.projectLabel(path)
    return [level1, ...midLevels, leafLevel].reverse().join(' | ')
  }

  get projectLabel() {
    return (path: Path): string => {
      const treeItem = typedStore.visualisationData.treeRoot.search(path)
      const projectLabelField =
        typedStore.activeVisualisation.visualisationStore.app.config.appConfig.projectLabelField
      return (treeItem && treeItem?.data && treeItem.data[projectLabelField]) || ''
    }
  }

  get orgName() {
    return this.appState.config?.appConfig.orgName
  }

  get appName() {
    return this.appState.config?.appConfig.appName
  }

  get appLogo() {
    return this.images.imageOrPreview(['config', APP_CONFIG_KEY, 'logo'])
  }

  get customTitle() {
    if (this.isConsolidatedApp) {
      return this.appState.config?.appConfig.pageTitle ?? ''
    }

    if (this.isVisualisationApp) {
      return this.getCurrentPathStory(StoryKeys.CUSTOM_TITLE)
    }

    return ''
  }

  get defaultDescription() {
    const content = stripHtml(this.sidebarBottomStory || this.sidebarTopStory)
    const maxLength = 160 - 4 // allow for " ..."
    return content.length > maxLength ? content.slice(0, maxLength) + ' ...' : content
  }

  get sidebarBottomStory() {
    return this.getCurrentPathStory(StoryKeys.SIDEBAR_BOT)
  }

  get sidebarTopStory() {
    return this.getCurrentPathStory(StoryKeys.SIDEBAR_TOP)
  }

  get customDescription() {
    if (this.isConsolidatedApp) {
      return this.appState.config?.appConfig.pageDescription ?? ''
    }

    if (this.isVisualisationApp) {
      return this.getCurrentPathStory(StoryKeys.CUSTOM_DESCRIPTION)
    }

    return ''
  }

  get getCurrentPathStory() {
    return (panel: NodeStoryKeys) => {
      const story = this.story(typedStore.public.display.storyPath, panel)
      let extensions = [Document, Text, Paragraph]

      if (this.isVisualisationApp) {
        extensions = [...extensions, ...typedStore.visualisationApp.storyEditorExtensions]
      }

      const editor = new Editor({
        content: story,
        editable: false,
        extensions,
      })
      const html = editor.getHTML()
      editor.destroy()
      return stripHtml(html)
    }
  }
}
