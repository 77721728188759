import { RenderedBubble } from '@/components/public/Visualisations/Bubbles'
import { VisNode } from '@/types'
import Konva from 'konva'

export class BubbleCircle extends Konva.Circle {
  private _renderedBubble: RenderedBubble
  private _node: VisNode
  static readonly shapeName = 'bubble'

  constructor(renderedBubble: RenderedBubble, node: VisNode) {
    super({
      id: renderedBubble.id,
      name: BubbleCircle.shapeName,
      perfectDrawEnabled: false, // "perfect draw" has a considerable impact on performance for little to no image quality improvement
    })

    this._renderedBubble = renderedBubble
    this._node = node
  }

  get renderedBubble() {
    return this._renderedBubble
  }

  get node() {
    return this._node
  }

  get path() {
    return this._renderedBubble.path.toString()
  }
}
