import { getGeomData, getGeometriesByIds, getGeometryIdsByPath } from '@/api'
import store from '@/store'
import typedStore from '@/store/typedStore'
import { GeomDataResponse, Path } from '@/types'
import { FeatureCollection } from 'geojson'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface IGeometriesState {
  features: Record<string, FeatureCollection['features']>
  geoIds: string[]
  loaded: boolean
  locationCounts: GeomDataResponse['counts']
  locationData: GeomDataResponse['results']
}

@Module({ dynamic: true, store, name: 'geometries', namespaced: true })
export default class Geometries extends VuexModule implements IGeometriesState {
  features: Record<string, FeatureCollection['features']> = {}
  geoIds: string[] = []
  loaded = false
  locationCounts: GeomDataResponse['counts'] = {}
  locationData: GeomDataResponse['results'] = {}

  @Mutation
  addFeature(payload: string) {
    const { features }: FeatureCollection = JSON.parse(payload)
    features.forEach((feature) => {
      Vue.set(this.features, feature.properties?.pk, Object.freeze(feature))
    })
  }

  @Mutation
  setGeoIds(payload: number[]) {
    this.geoIds = payload.map((id) => id.toString())
  }

  @Mutation
  setLoaded(payload: boolean) {
    this.loaded = payload
  }

  @Mutation
  setLocationCounts(payload: GeomDataResponse['counts']) {
    this.locationCounts = payload
  }

  @Mutation
  setLocationData(payload: GeomDataResponse['results']) {
    this.locationData = payload
  }

  get filteredFeatures() {
    return this.geoIds.map((id) => this.features[id])
  }

  get geojson() {
    return {
      type: 'FeatureCollection',
      features: this.filteredFeatures,
    }
  }

  get fetchedIds() {
    return Object.keys(this.features)
  }

  get missingIds() {
    return this.geoIds.filter((id) => {
      const alreadyDownloaded = this.fetchedIds.includes(id)
      return !alreadyDownloaded
    })
  }

  get geometryPath(): Path {
    const storyPath = typedStore.activeVisualisation.storyPath
    return storyPath.isSubroot ? Path.root : storyPath
  }

  get levelNum() {
    return Math.max(this.geometryPath.level, 1)
  }

  @Action({ rawError: true })
  async update() {
    this.setLoaded(false)
    await Promise.all([this.getLocationData(), this.getMissingGeometries()])
    this.setLoaded(true)
  }

  @Action({ rawError: true })
  async getMissingGeometries() {
    await this.getIdsForPath()
    const chunks = makeChunks(this.missingIds)
    await Promise.all(chunks.map((ids) => this.getGeometries(ids)))
  }

  @Action({ rawError: true })
  async getGeometries(payload: string[]) {
    this.addFeature(
      await getGeometriesByIds(payload, typedStore.activeVisualisation.visualisationAppRank),
    )
  }

  @Action({ rawError: true })
  async getLocationData() {
    const path = this.geometryPath.toString()
    let locationData: GeomDataResponse | null = {
      results: {},
      counts: {},
    }
    if (
      typedStore.public.search.filterRecords &&
      'lat' in typedStore.public.search.currentLocation &&
      'lon' in typedStore.public.search.currentLocation
    ) {
      const { lat, lon } = typedStore.public.search.currentLocation
      locationData = await getGeomData(
        path,
        {
          level_num: this.levelNum,
          lat,
          lon,
        },
        typedStore.activeVisualisation.visualisationAppRank,
      )
    } else {
      locationData = await getGeomData(
        path,
        {
          level_num: this.levelNum,
        },
        typedStore.activeVisualisation.visualisationAppRank,
      )
    }

    this.setLocationData(locationData?.results || [])
    this.setLocationCounts(locationData?.counts || [])
  }

  @Action({ rawError: true })
  async getIdsForPath() {
    const path = this.geometryPath.toString()
    if (
      typedStore.public.search.filterRecords &&
      'lat' in typedStore.public.search.currentLocation &&
      'lon' in typedStore.public.search.currentLocation
    ) {
      const { lat, lon } = typedStore.public.search.currentLocation
      this.setGeoIds(
        await getGeometryIdsByPath(
          path,
          {
            level_num: this.levelNum,
            lat,
            lon,
          },
          typedStore.activeVisualisation.visualisationAppRank,
        ),
      )
    } else {
      this.setGeoIds(
        await getGeometryIdsByPath(
          path,
          {
            level_num: this.levelNum,
          },
          typedStore.activeVisualisation.visualisationAppRank,
        ),
      )
    }
  }
}

export function makeChunks(ids: string[]) {
  const chuckSize = 5
  return new Array(Math.ceil(ids.length / chuckSize))
    .fill(null)
    .map((_, i) => ids.slice(i * chuckSize, i * chuckSize + chuckSize))
}

export const GeometriesModule = getModule(Geometries)
