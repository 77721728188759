<template>
  <TipTapPlugin
    :content="content"
    :editable="editable"
    :show-toolbar="!hideToolbar"
    :wrapper-class="wrapperClass"
    :additional-extensions="additionalExtensions"
    :extension-options="extensionOptions"
    :style="style"
    @input="$emit('update:content', $event)"
  >
    <template #menu-middle="{ editor }">
      <InsertInternalLinkButton v-if="internalLinkExtension" :editor="editor" />
      <ColourButton v-if="colourExtension" :editor="editor" />
      <InsertIframeButton v-if="iframeExtension" :editor="editor" />
      <InsertIframeDialogButton v-if="iframeExtension" :editor="editor" />
      <InsertImageButton v-if="imageExtension" :editor="editor" />
      <InsertBubblesLegends v-if="bubblesLegendsExtension" :editor="editor" />
      <InsertIndexTableButton v-if="indexTableExtension" :editor="editor" />
      <InsertRatesImpactTableButton v-if="ratesImpactTableExtension" :editor="editor" />
      <InsertFeedbackQuestionButton v-if="feedbackExtension" :editor="editor" />
      <InsertUserDetailsQuestionButton v-if="userDetailsExtension" :editor="editor" />
    </template>
  </TipTapPlugin>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { TipTap as TipTapPlugin, InsertImageButton, mentionListRender } from '@orbica/vue-modules'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import CharacterCount from '@tiptap/extension-character-count'
import IframeExtension from '@/components/TipTap/extensions/IframeExtension/IframeExtension'
import IframeDialogExtension from '@/components/TipTap/extensions/IframeDialogExtension/IframeDialogExtension'
import InsertIframeButton from '@/components/TipTap/extensions/IframeExtension/InsertIframeButton.vue'
import InsertIframeDialogButton from './extensions/IframeDialogExtension/InsertIframeDialogButton.vue'
import ImageExtension from '@/components/TipTap/extensions/ImageExtension'
import MentionExtension from '@/components/TipTap/extensions/MentionExtension'
import FeedbackExtension from '@/components/TipTap/extensions/Feedback/FeedbackExtension'
import MentionView from '@/components/TipTap/extensions/MentionView.vue'
import BubblesLegendsExtension from '@/components/TipTap/extensions/BubblesLegendsExtension'
import InsertBubblesLegends from '@/components/TipTap/extensions/InsertBubblesLegends.vue'
import IndexTableExtension from '@/components/TipTap/extensions/IndexTable/IndexTableExtension'
import InsertIndexTableButton from '@/components/TipTap/extensions/IndexTable/InsertIndexTableButton.vue'
import RatesImpactTableExtension from '@/components/TipTap/extensions/RatesImpactTable/RatesImpactTableExtension'
import InsertRatesImpactTableButton from '@/components/TipTap/extensions/RatesImpactTable/InsertRatesImpactTableButton.vue'
import InternalLinkExtension from '@/components/TipTap/extensions/InternalLink/InternalLinkExtension'
import InsertInternalLinkButton from '@/components/TipTap/extensions/InternalLink/InsertInternalLinkButton.vue'
import ColourButton from '@/components/TipTap/extensions/ColourButton.vue'
import InsertFeedbackQuestionButton from '@/components/TipTap/extensions/Feedback/InsertFeedbackQuestionButton.vue'
import { AUTO_TOTAL_FIELD_COLUMN, AUTO_TOTAL_FIELD_NAME, CLASS_TIPTAP_CONTAINER } from '@/constants'
import { AdditionalExtension } from '@orbica/vue-modules/dist/modules/TipTap/types'
import ImageTipTapView from '@/components/TipTap/extensions/ImageTipTapView'
import FeedbackTipTapView from '@/components/TipTap/extensions/Feedback/FeedbackTipTapView.vue'
import MentionList from '@/components/TipTap/extensions/MentionList.vue'
import CtrlEnterExtension from './extensions/CtrlEnterExtension'
import { ListItem } from '@/types'
import UserDetailsExtension from '@/components/TipTap/extensions/UserDetails/UserDetailsExtension'
import InsertUserDetailsQuestionButton from './extensions/UserDetails/InsertUserDetailsQuestionButton.vue'
import UserDetailsQuestionTipTapView from '@/components/TipTap/extensions/UserDetails/UserDetailsQuestionTipTapView.vue'

const components = {
  TipTapPlugin,
  InsertIframeButton,
  InsertIframeDialogButton,
  InsertImageButton,
  InsertBubblesLegends,
  InsertIndexTableButton,
  InsertInternalLinkButton,
  InsertRatesImpactTableButton,
  InsertFeedbackQuestionButton,
  ColourButton,
  InsertUserDetailsQuestionButton,
}
const props = {
  content: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
  styleSettings: {
    type: Object,
    default: () => ({}),
  },
  editorHeight: {
    type: String,
    default: null,
  },
  colourExtension: Boolean,
  iframeExtension: Boolean,
  internalLinkExtension: Boolean,
  mentionExtension: Boolean,
  imageExtension: Boolean,
  feedbackExtension: Boolean,
  bubblesLegendsExtension: Boolean,
  indexTableExtension: Boolean,
  ratesImpactTableExtension: Boolean,
  characterCountExtension: Boolean,
  userDetailsExtension: Boolean,
  hideToolbar: Boolean,
  characterLimit: {
    type: Number,
    default: 0,
  },
  extensionOptions: {
    type: Object,
    default: () => ({}),
  },
}

@Component({ components, name: 'TipTap' })
export default class TipTap extends Vue.extend({ props }) {
  get wrapperClass() {
    return [CLASS_TIPTAP_CONTAINER, ...(this.editable ? ['editable'] : [])].join(' ')
  }

  get style() {
    return {
      '--font-scale': (this.styleSettings.fontSize ?? 100) / 100,
      color: this.styleSettings.fontColor,
      'font-family': this.styleSettings.fontFamily,
      '--heading-color': this.styleSettings.headingColor,
      '--link-color': this.styleSettings.linkColor,
      '--table-border-color': this.styleSettings.tableBorderColor,
      ...(this.editable ? { '--editor-height': this.editorHeight || '30em' } : {}),
    }
  }

  get additionalExtensions(): AdditionalExtension[] {
    return [
      ...[
        {
          extension: CtrlEnterExtension,
          options: { onCtrlEnter: () => this.$emit('ctrl-enter') },
        },
      ],
      ...(this.characterLimit
        ? [
            {
              extension: CharacterCount,
              options: { limit: this.characterLimit },
            },
          ]
        : []),
      ...(this.iframeExtension
        ? [
            {
              extension: IframeExtension,
              options: {},
            },
            {
              extension: IframeDialogExtension,
              options: {},
            },
          ]
        : []),
      ...(this.mentionExtension
        ? [
            {
              extension: MentionExtension,
              options: {
                suggestion: {
                  items: this.mentionItems,
                  render: mentionListRender(MentionList),
                },
                renderComponent: MentionView,
              },
            },
          ]
        : []),
      ...(this.feedbackExtension
        ? [
            {
              extension: FeedbackExtension,
              options: {
                renderComponent: FeedbackTipTapView,
              },
            },
          ]
        : []),
      ...(this.imageExtension
        ? [
            {
              extension: ImageExtension,
              options: {
                renderComponent: ImageTipTapView,
              },
            },
          ]
        : []),
      ...(this.bubblesLegendsExtension
        ? [
            {
              extension: BubblesLegendsExtension,
              options: {},
            },
          ]
        : []),
      ...(this.indexTableExtension
        ? [
            {
              extension: IndexTableExtension,
              options: {},
            },
          ]
        : []),
      ...(this.ratesImpactTableExtension
        ? [
            {
              extension: RatesImpactTableExtension,
              options: {},
            },
          ]
        : []),
      ...(this.internalLinkExtension
        ? [
            {
              extension: InternalLinkExtension,
              options: {},
            },
          ]
        : []),
      ...(this.colourExtension
        ? [
            {
              extension: TextStyle,
              options: {},
            },
            {
              extension: Color,
              options: {},
            },
          ]
        : []),
      ...(this.userDetailsExtension
        ? [
            {
              extension: UserDetailsExtension,
              options: {
                renderComponent: UserDetailsQuestionTipTapView,
              },
            },
          ]
        : []),
    ]
  }

  get fieldMap() {
    return this.$typedStore.visualisationApp.fieldMap
  }

  mentionItems({ query }: { query: string }): ListItem[] {
    const autoTotalField = {
      name: AUTO_TOTAL_FIELD_NAME,
      column: AUTO_TOTAL_FIELD_COLUMN,
    }
    return [autoTotalField, ...this.fieldMap].filter(
      ({ column, name }) =>
        this.lowercaseIncludes(column, query) || this.lowercaseIncludes(name, query),
    )
  }

  lowercaseIncludes(item: string, query: string) {
    return item.toLowerCase().includes(query.toLowerCase())
  }
}
</script>

<style>
/*
  Sometimes the spaces are getting deleted when you try to type
  eg typing "Fred lives here" changes into "Fredliveshere"
  https://github.com/ueberdosis/tiptap/issues/873#issuecomment-730147217
*/
.ProseMirror {
  width: 100%;
}
.ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.tiptap-container {
  position: relative;
}

.editor__content h1,
.editor__content h2,
.editor__content h3,
.editor__content h4,
.editor__content h5,
.editor__content h6 {
  color: inherit !important;
  color: var(--heading-color) !important;
  font-family: inherit !important;
}

.editor__content a {
  color: var(--link-color) !important;
}

.editor__content h1 {
  font-size: calc(2.5rem * var(--font-scale));
}

.editor__content h2 {
  font-size: calc(2rem * var(--font-scale));
}

.editor__content h3 {
  font-size: calc(1.75rem * var(--font-scale));
}

.editor__content h4 {
  font-size: calc(1.5rem * var(--font-scale));
}

.editor__content h5 {
  font-size: calc(1.25rem * var(--font-scale));
}

.editor__content h6 {
  font-size: calc(1.15rem * var(--font-scale));
}

.editor__content p {
  font-size: calc(1rem * var(--font-scale));
  font-family: inherit;
}

.editor__content td {
  border-color: var(--table-border-color) !important;
}

.editable > .editor__content {
  resize: vertical;
  overflow-y: auto;
  height: var(--editor-height);
}
</style>
