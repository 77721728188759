// Polyfills
import App from '@/App.vue'
import '@/assets/css/custom.scss' // includes Bootstraps styling
import '@/plugins/authPlugin'
// Make sure to register before importing any components
import '@/plugins/class-component-hooks'
import FontAwesomeIcon from '@/plugins/FontAwesomeIcon'
import loggerOptions from '@/plugins/logging/loggerOptions'
import UIEventLogger from '@/plugins/logging/UIEventLogger'
import '@/plugins/registerAnalytics'
import registerBootstrapComponents from '@/plugins/registerBootstrapComponents'
import '@/plugins/sentryPlugin'
import router from '@/router'
import { settingsService } from '@/services'
import store from '@/store'
import typedStore from '@/store/typedStore'
import VueFormWizard from '@orbica/vue-form-wizard-2'
import '@orbica/vue-form-wizard-2/src/assets/wizard.scss'
import 'c3/c3.css'
import 'font-awesome-animation/dist/font-awesome-animation.min.css'
import 'leaflet-defaulticon-compatibility'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import 'leaflet/dist/leaflet.css'
import 'mutationobserver-shim'
import { createPinia, PiniaVuePlugin } from 'pinia'
import 'url-polyfill'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// App imports
import Vue from 'vue'
import VueLogger from 'vue-logger-plugin'
import VueMeta from 'vue-meta'
import VueRx from 'vue-rx'
import VueSanitize from 'vue-sanitize'
import Vue2TouchEvents from 'vue2-touch-events'

// Dependencies
import TipTap from '@/components/TipTap/TipTap.vue'
import Vuelidate from 'vuelidate'
import { sync } from 'vuex-router-sync'
import 'whatwg-fetch'

declare module 'vue/types/vue' {
  interface Vue {
    $typedStore: typeof typedStore
    $sanitize: (input: string) => string
  }
}
Vue.prototype.$typedStore = typedStore

console.log('Release:', process.env.VUE_APP_RELEASE || 'development')

// Register plugins and components
Vue.use(Vue2TouchEvents)
Vue.use(PiniaVuePlugin)
Vue.use(Vuelidate)
Vue.use(VueRx)
Vue.use(VueFormWizard)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('TipTap', TipTap)
registerBootstrapComponents(Vue)
Vue.use(VueMeta)
Vue.use(VueSanitize)
Vue.use(VueLogger, loggerOptions)
Vue.use(UIEventLogger, settingsService.logUIEvents)

sync(store, router)

Vue.config.productionTip = false

const app = new Vue({
  store,
  router,
  render: (h) => h(App),
  pinia: createPinia(),
}).$mount('#app')

if (!settingsService.isProd) {
  // Do not make available on production
  // @ts-ignore window.app
  window.app = app
}
