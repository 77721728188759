<template>
  <fieldset :disabled="imageUploading">
    <b-select
      :options="primaryAppOptions"
      :value="selectedPrimaryAppOptionPath"
      @change="updatePrimaryAppOptionPath"
    />
    <b-select
      v-if="secondaryAppTabOptions.length > 0"
      :options="secondaryAppTabOptions"
      :value="selectedSecondaryAppTabOptionPath"
      @change="updateSecondaryAppTabOptionPath"
    />

    <template v-if="selectedSecondaryAppId">
      <ChildStorySelect
        v-for="parentStoryPath in secondaryAppParentStoryPaths"
        :key="parentStoryPath"
        :selected-story-path="getChildStoryPath(parentStoryPath)"
        :app-id="selectedSecondaryAppId"
        :parent-story-path="parentStoryPath"
        :app-rank="AppRanks.SECONDARY"
        @input="setChildStoryPath($event)"
      />
    </template>
  </fieldset>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import ChildStorySelect from '@/components/builder/CustomVisualisationSettings/ChildStorySelect.vue'
import { getTabLabel } from '@/components/builder/helpers'
import { AppRanks, NodeTypes, SelectOption, Tab } from '@/types'
import { DEFAULT_SELECT_OPTION, PATH_SEPARATOR } from '@/constants'

const components = {
  ChildStorySelect,
}

const props = {
  selectedStoryPath: String as PropType<String | undefined>,
}

@Component({ components })
export default class ConsolidatedAppStoryPicker extends Vue.extend({ props }) {
  AppRanks = AppRanks

  get imageUploading() {
    return this.$typedStore.primary.entities.images.imageUploading
  }

  get selectedStoryPathArr(): string[] {
    return this.selectedStoryPath?.split(PATH_SEPARATOR) ?? []
  }

  get primaryAppTabs(): Tab[] {
    return this.$typedStore.primary.entities.tabs.consolidatedAppStoryPickerTabs
  }

  get primaryAppOptions(): SelectOption[] {
    return this.mapTabs(this.primaryAppTabs)
  }

  get selectedPrimaryAppOptionPath(): string | undefined {
    return this.selectedStoryPathArr[0]
  }

  updatePrimaryAppOptionPath(newValue: string | undefined) {
    this.updateSelectedStoryPath(newValue)
  }

  get selectedPrimaryAppTab(): Tab | undefined {
    return this.primaryAppTabs.find((x) => x.path === this.selectedPrimaryAppOptionPath)
  }

  get secondaryAppTabs(): Tab[] | undefined {
    return this.selectedPrimaryAppTab?.nodeType === NodeTypes.APP
      ? this.$typedStore.primary.entities.tabs.secondaryAppTabs(this.selectedPrimaryAppTab.path)
      : undefined
  }

  get secondaryAppTabOptions(): SelectOption[] {
    return this.mapTabs(this.secondaryAppTabs)
  }

  get selectedSecondaryAppTabOptionPath(): string | undefined {
    return this.selectedStoryPathArr[1]
  }

  updateSecondaryAppTabOptionPath(newValue: string | undefined) {
    const newSelectedStoryPath = newValue
      ? `${this.selectedPrimaryAppOptionPath}${PATH_SEPARATOR}${newValue}`
      : this.selectedPrimaryAppOptionPath
    this.updateSelectedStoryPath(newSelectedStoryPath)
  }

  get selectedSecondaryAppId(): number | undefined {
    return this.secondaryAppTabs?.find((tab) => tab.path === this.selectedSecondaryAppTabOptionPath)
      ?.secondaryAppId
  }

  get secondaryAppParentStoryPaths(): string[] {
    // remove the first story path as it does not exist in the secondary app
    const parentStoryPaths = this.selectedStoryPathArr.slice(1)
    // return the parent story paths in their full form e.g. ['Oceania', 'Oceania/Australia', 'Oceania/Australia/Sydney']
    return parentStoryPaths.map((_, index) =>
      parentStoryPaths.slice(0, index + 1).join(PATH_SEPARATOR),
    )
  }

  getChildStoryPath(parentStoryPath: string) {
    // find the index of the parent story path in the secondary app parent story paths then add one to find the index of it's child
    const childIndex = this.secondaryAppParentStoryPaths.indexOf(parentStoryPath) + 1
    return this.secondaryAppParentStoryPaths[childIndex]
  }

  setChildStoryPath(newValue: string) {
    // add the first story path
    this.updateSelectedStoryPath(`${this.selectedPrimaryAppOptionPath}${PATH_SEPARATOR}${newValue}`)
  }

  updateSelectedStoryPath(newValue: string | undefined) {
    this.$emit('update:selectedStoryPath', newValue)
  }

  private mapTabs(tabs?: Tab[]): SelectOption[] {
    if (!tabs || tabs.length === 0) {
      return []
    }

    const options = tabs.map((tab) => ({
      value: tab.path,
      text: getTabLabel(tab),
    }))

    return [DEFAULT_SELECT_OPTION, ...options]
  }
}
</script>
