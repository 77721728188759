class SettingsService {
  get isProd() {
    return process.env.NODE_ENV?.toLowerCase() === 'production'
  }

  get debugConsoleEnabled() {
    return this._isTrueSetting('VUE_APP_DEBUG_CONSOLE')
  }

  get logUIEvents() {
    return this._isTrueSetting('VUE_APP_LOG_UI_EVENTS')
  }

  get recaptchaV3SiteKey() {
    return process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY || ''
  }

  get bubblesCanvasDebugMode() {
    return this._isTrueSetting('VUE_APP_BUBBLES_CANVAS_DEBUG_MODE')
  }

  private _isTrueSetting(key: string) {
    return process.env[key]?.toLowerCase() === 'true'
  }
}

export const settingsService = new SettingsService()
