import { defineStore } from 'pinia'

export enum LogLevels {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  log = 'log',
}
export type LogLevel = keyof typeof LogLevels

interface LogEvent {
  level: LogLevel
  message: string
}

export interface ILoggingState {
  logEvents: LogEvent[]
}

const MAX_LOG_EVENTS = 50

export const useLoggingStore = defineStore({
  id: 'logging',
  state: (): ILoggingState => ({
    logEvents: [],
  }),
  getters: {
    allMessages(state: ILoggingState): string[] {
      return state.logEvents
        .slice()
        .reverse()
        .map((e) => e.message)
    },
  },
  actions: {
    addLogEvent(level: LogLevel, message: string) {
      this.logEvents.push({
        level,
        message,
      })

      // Limit logEvents array length
      while (this.logEvents.length > MAX_LOG_EVENTS) {
        this.logEvents.shift()
      }
    },
    messages(level: LogLevel) {
      return this.logEvents
        .filter((e) => LogLevels[e.level] <= LogLevels[level])
        .slice()
        .reverse()
        .map((e) => e.message)
    },
  },
})
