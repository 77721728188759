// https://medium.com/@nsisodiya/hacking-font-awesome-for-custom-svg-icons-ea762118fa7b
// In inkscape, do the following to get a single path:
// - Select objects
// - Path -> Object to path
// - Path -> Stroke to path
// - Path -> Combine (to make a single path item)
// - Save as plain svg

export default {
    prefix: 'custom',
    iconName: 'recenter-visualisation',
    icon: [
      512,
      512,
      [],
      'f0000',
      'M 95.529418,32 C 31.996338,32.0013 31.99777,32.000434 32.000006,95.529412 V 190.82353 C 32.000006,202.5169 41.483107,212 53.176477,212 h 21.176471 c 11.69337,0 21.17647,-9.4831 21.17647,-21.17647 V 95.529412 h 95.294122 c 11.69337,0 21.17647,-9.4831 21.17647,-21.176471 V 53.176471 C 212.00001,41.4831 202.51691,32 190.82354,32 Z m 225.647062,6e-6 c -11.69337,0 -21.17647,9.4831 -21.17647,21.17647 v 21.17647 c 0,11.69337 9.4831,21.17647 21.17647,21.17647 h 95.29411 v 95.294114 c 0,11.69337 9.4831,21.17647 21.17647,21.17647 h 21.17647 C 470.5169,212 480,202.5169 480,190.82353 V 95.529416 C 479.999,31.996338 479.99956,31.99777 416.47059,32.000006 Z M 53.17647,300 C 41.4831,300 32,309.4831 32,321.17647 v 95.29411 c 0.0013,63.53308 4.34e-4,63.53165 63.529409,63.52941 h 95.294111 c 11.69337,0 21.17647,-9.4831 21.17647,-21.17647 v -21.17647 c 0,-11.69336 -9.4831,-21.17647 -21.17647,-21.17647 H 95.529409 V 321.17647 C 95.529409,309.4831 86.04631,300 74.35294,300 Z m 384.47058,0 c -11.69337,0 -21.17647,9.4831 -21.17647,21.17647 v 95.29412 h -95.29412 c -11.69337,0 -21.17647,9.4831 -21.17647,21.17647 v 21.17647 c 0,11.69337 9.4831,21.17647 21.17647,21.17647 h 95.29412 c 63.53308,-10e-4 63.53165,-4.4e-4 63.52941,-63.52941 V 321.17647 C 479.99999,309.4831 470.51689,300 458.82352,300 Z',
    ],
  }
