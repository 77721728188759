import { Bubble } from '@/components/public/Visualisations/Bubbles'

export class BubblesTransitionOperations {
  private bubblesToAdd: Map<string, Bubble> = new Map()
  private bubblesToRemove: Map<string, Bubble> = new Map()

  appendBubbleToAdd(bubble: Bubble) {
    this.bubblesToAdd.set(bubble.path.toString(), bubble)
  }

  appendBubbleToRemove(bubble: Bubble) {
    this.bubblesToRemove.set(bubble.path.toString(), bubble)
  }

  private get bubblesToAddValues() {
    return Array.from(this.bubblesToAdd.values())
  }

  private get bubblesToRemoveValues() {
    return Array.from(this.bubblesToRemove.values())
  }

  get amalgamating() {
    return this.bubblesToAddValues.flatMap((ancestor) => {
      const descendantsToRemove = this.bubblesToRemoveValues.filter((bubble) =>
        bubble.path.isDescendantOf(ancestor.path),
      )
      return descendantsToRemove.length > 0 ? [{ add: ancestor, remove: descendantsToRemove }] : []
    })
  }

  get splitting() {
    return this.bubblesToRemoveValues.flatMap((ancestor) => {
      const descendantsToAdd = this.bubblesToAddValues.filter((bubble) =>
        bubble.path.isDescendantOf(ancestor.path),
      )
      return descendantsToAdd.length > 0 ? [{ remove: ancestor, add: descendantsToAdd }] : []
    })
  }

  get toAdd() {
    return this.bubblesToAddValues.filter(
      (bubbleToAdd) => !this.hasAncestorOrDescendantInList(bubbleToAdd, this.bubblesToRemoveValues),
    )
  }

  get toRemove() {
    return this.bubblesToRemoveValues.filter(
      (bubbleToRemove) =>
        !this.hasAncestorOrDescendantInList(bubbleToRemove, this.bubblesToAddValues),
    )
  }

  private hasAncestorOrDescendantInList(bubble: Bubble, list: Bubble[]) {
    return list.some(
      (otherBubble) =>
        otherBubble.path.isAncestorOf(bubble.path) || otherBubble.path.isDescendantOf(bubble.path),
    )
  }
}
