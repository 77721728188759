import { SimulationBubble } from '@/components/public/Visualisations/Bubbles'
import {
  forceCollide,
  forceSimulation,
  forceX,
  forceY,
  Simulation,
  SimulationNodeDatum,
} from 'd3-force'
import { EventEmitter } from 'events'

export interface BubblesSimulationEvents {
  tick: () => void
  end: () => void
}

export declare interface BubblesSimulation {
  on<U extends keyof BubblesSimulationEvents>(event: U, listener: BubblesSimulationEvents[U]): this
}

export class BubblesSimulation extends EventEmitter {
  private _simulation: Simulation<SimulationNodeDatum, undefined>
  private _isRoot: boolean

  constructor(isRoot: boolean) {
    super()

    this._simulation = forceSimulation()
    this._isRoot = isRoot

    this._simulation.on('tick', () => this.emit('tick'))
    this._simulation.on('end', () => this.emit('end'))
  }

  updateIsRoot(isRoot: boolean) {
    this._isRoot = isRoot
  }

  updateSimulation(bubbles: SimulationBubble[]) {
    const alphaDecay = this._isRoot ? 0.08 : 0.03
    // Fixed parameters
    const velocityDecay = 0.2
    const forceStrength = 0.04

    this._simulation.nodes(bubbles)

    this._simulation
      .velocityDecay(velocityDecay)
      .alphaDecay(alphaDecay)
      .force('x', null)
      .force(
        'x',
        forceX()
          .strength(forceStrength)
          .x((b) => (b as SimulationBubble).destinationPosition.x),
      )
      .force('y', null)
      .force(
        'y',
        forceY()
          .strength(forceStrength)
          .y((b) => (b as SimulationBubble).destinationPosition.y),
      )

    const collisionStrength = this._isRoot ? 1 : 0.2
    const collisionIterations = this._isRoot ? 10 : 2

    this._simulation.force(
      'collision',
      forceCollide()
        .radius((b) => (b as SimulationBubble).displacementRadius)
        .strength(collisionStrength)
        .iterations(collisionIterations),
    )

    this._simulation.alpha(1).restart()
  }
}

export default BubblesSimulation
