export function is<T>() {
  return (val: T | false | undefined | null): val is T => {
    return Boolean(val)
  }
}

export function isPlainObject(value: unknown): value is Record<string | number, unknown> {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Function)
  )
}
