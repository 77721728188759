import typedStore from '@/store/typedStore'
import { LeafNode, Path } from '@/types'
import { defineStore } from 'pinia'
import Vue from 'vue'

export interface FilterItem {
  value: string
  state: boolean
}
export const filterSorts = {
  alphaDown: (a: string, b: string) => a.localeCompare(b),
  alphaUp: (a: string, b: string) => b.localeCompare(a),
}

export type FilterSortKey = keyof typeof filterSorts;

const defaultSort: FilterSortKey = 'alphaDown';

const storageKey = 'filterSorts'

export interface IFilterState {
  activeColumns: string[]
  selection: Record<string, Set<string>>
  sorts: Record<string, FilterSortKey>
}

export const useFilterStore = defineStore({
  id: 'filter',

  state: (): IFilterState => ({
    activeColumns: [],
    selection: {},
    sorts: JSON.parse(sessionStorage.getItem(storageKey) || '{}'),
  }),

  getters: {
    isFiltering(state: IFilterState): boolean {
      return !!(this.activeColumns.length && Object.values(state.selection).some((set) => set.size))
    },

    columns(): string[] {
      const { filterByCategory } = typedStore.activeVisualisation.visualisationStore.app.config
      const fallback = filterByCategory?.category?.length ? [filterByCategory?.category] : []
      return filterByCategory?.columns ?? fallback
    },

    getSort(): (column: string) => FilterSortKey {
      return column => this.sorts[column] || defaultSort
    },

    leafNodeFilter(state: IFilterState): (node: LeafNode) => boolean {
      return this.createLeafNodeFilter(state.activeColumns)
    },

    createLeafNodeFilter(state: IFilterState): (columns: string[]) => (node: LeafNode) => boolean {
      return (columns: string[]) => (node: LeafNode) =>
        columns
          .map((column) => ({ column, items: state.selection[column] }))
          .filter(({ items }) => items && items.size)
          .every(({ column, items }) => items.has(node.data[column]))
    },

    filterPanels(state: IFilterState): { column: string; label: string; items: FilterItem[] }[] {
      return this.activeColumns.map((column, index) => ({
        column,
        label:
          typedStore.activeVisualisation.visualisationStore.app.config.fieldMap.find(
            (f) => f.column === column,
          )?.name || column,
        items: typedStore.visualisationData
          .filteredFieldLeafDataUnique(
            Path.root,
            column,
            this.createLeafNodeFilter(state.activeColumns.slice(0, index)),
          )
          .sort(filterSorts[this.sorts[column] || defaultSort])
          .map((value) => ({
            value,
            state: this.selection[column]?.has(value) ?? false,
          })),
      }))
    },
  },

  actions: {
    addActiveColumn(column: string) {
      this.activeColumns.push(column)
    },

    removeActiveColumn(column: string) {
      this.activeColumns = this.activeColumns.filter((c) => c !== column)
      delete this.selection[column]
    },

    clearAll() {
      for (const key in this.selection) {
        this.deselectAll(key)
      }
    },

    selectAll(column: string) {
      Vue.set(
        this.selection,
        column,
        new Set<string>(typedStore.visualisationData.fieldLeafDataUnique(Path.root, column)),
      )
    },

    deselectAll(column: string) {
      Vue.set(this.selection, column, new Set<string>())
    },

    setSort(column: string, sort: FilterSortKey) {
      Vue.set(this.sorts, column, sort)
      sessionStorage.setItem(storageKey, JSON.stringify(this.sorts))
    },

    update(column: string, value: string, state: boolean) {
      // Vue.set should not be required in vue 3
      if (state) {
        if (!this.selection[column]) {
          Vue.set(this.selection, column, new Set<string>([value]))
        } else {
          Vue.set(this.selection, column, new Set([...this.selection[column], value]))
        }
        this.filterRightColumnSelections(column)
      } else {
        this.selection[column].delete(value)
        Vue.set(this.selection, column, new Set([...this.selection[column]]))
        this.filterAfterDeselect(column)
      }
    },

    filterAfterDeselect(column: string) {
      const columnIndex = this.activeColumns.indexOf(column) + 1
      const left = this.activeColumns.slice(0, columnIndex)
      const right = this.activeColumns.slice(columnIndex)
      for (const affectedColumn of right.filter((c) => this.selection[c])) {
        const remainingOptions = new Set([
          ...typedStore.visualisationData.filteredFieldLeafDataUnique(
            Path.root,
            affectedColumn,
            this.createLeafNodeFilter(left),
          ),
        ])
        Vue.set(
          this.selection,
          affectedColumn,
          new Set([...[...this.selection[affectedColumn]].filter((s) => remainingOptions.has(s))]),
        )
      }
    },

    filterRightColumnSelections(column: string) {
      const columnIndex = this.activeColumns.indexOf(column) + 1
      const left = this.activeColumns.slice(0, columnIndex)
      const right = this.activeColumns.slice(columnIndex)

      for (const affectedColumn of right) {
        const validOptions = new Set([
          ...typedStore.visualisationData.filteredFieldLeafDataUnique(
            Path.root,
            affectedColumn,
            this.createLeafNodeFilter(left),
          ),
        ])

        const filteredSelection = [...(this.selection[affectedColumn] || [])].filter((s) =>
          validOptions.has(s),
        )

        Vue.set(this.selection, affectedColumn, new Set(filteredSelection))
      }
    },
  },
})
