<template>
  <div>
    <ButtonTemplate :disabled="noSelection" icon="palette" title="Set Colour" :on-click="open" />
    <div v-if="showColourPicker" class="colour-picker-dialog">
      <ColorPicker v-model="colour" @input="update" :debounce="500" />
      <b-button variant="outline" @click="clear">Clear</b-button>
      <b-button-close @click="showColourPicker = false" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { ColorPicker, ButtonTemplate } from '@orbica/vue-modules'

import { Editor } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ColourButton: {
      /**
       * Set span colour
       */
      setColor: (color: string) => ReturnType
      /**
       * Clear span colour
       */
      unsetColor: () => ReturnType
    }
  }
}

const components = {
  ButtonTemplate,
  ColorPicker,
}
const props = {
  editor: {
    type: Editor,
    default: null,
  },
}

@Component({ components })
export default class ColourButton extends Vue.extend({ props }) {
  colour = ''
  showColourPicker = false

  get noSelection() {
    return this.editor?.state.selection.empty
  }

  open() {
    if (this.editor) {
      this.colour = this.editor.getAttributes('textStyle').color
      this.showColourPicker = true
    }
  }

  clear() {
    this.colour = ''
    this.update(this.colour)
    this.showColourPicker = false
  }

  update(colour: string) {
    if (this.editor) {
      if (colour) {
        this.editor.chain().focus().setColor(colour).run()
      } else {
        this.editor.chain().focus().unsetColor().run()
      }
    }
  }
}
</script>

<style>
.colour-picker-dialog {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 310px;
  border-radius: var(--border-radius);
  z-index: 50000;
  box-shadow: 4px 3px 5px #444;
  display: flex;
  padding: 12px;
  gap: 4px;
  .close {
    margin-left: 7px;
  }
}
</style>
