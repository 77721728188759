import logger from '@/plugins/logging/logger'
import { settingsService } from '@/services/settingsService'
import { load, ReCaptchaInstance } from 'recaptcha-v3'

class RecaptchaService {
  private recaptchaInstance: ReCaptchaInstance | undefined

  async execute(action: string): Promise<string | undefined> {
    try {
      await this.initialize()
      return this.recaptchaInstance?.execute(action)
    } catch (e) {
      logger.error(`failed to retrieve reCAPTCHA token: ${e}`)
    }
    return undefined
  }

  private async initialize() {
    if (!this.recaptchaInstance) {
      this.recaptchaInstance = await load(settingsService.recaptchaV3SiteKey, {
        useRecaptchaNet: true,
        autoHideBadge: true,
      })
    }
  }
}

export const recaptchaService = new RecaptchaService()
