<template>
  <ButtonTemplate
    v-if="enabled"
    :on-click="onClick"
    icon="percent"
    title="Insert rates impact table"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'

import { ButtonTemplate } from '@orbica/vue-modules'
import { Editor } from '@tiptap/core'
import { AppRelationshipType } from '@/types'

const components = {
  ButtonTemplate,
}

const props = {
  editor: {
    type: Object as PropType<Editor>,
    default: null,
  },
}

@Component({ components })
export default class InsertRatesImpactTableButton extends Vue.extend({ props }) {
  get enabled() {
    return this.$typedStore.primary.app.relationships.some(
      (x) => x.type === AppRelationshipType.Report,
    )
  }

  onClick() {
    if (this.editor) {
      this.editor.chain().focus().setRatesImpactTable().run()
    }
  }
}
</script>
