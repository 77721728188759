import {
  BubbleCircle,
  handleBubbleTap,
  hidePopup,
  isBubbleCircle,
  showPopup,
} from '@/components/public/Visualisations/Bubbles'
import { rectCenter } from '@/helpers'
import { OrbVizAnalytics } from '@/plugins/analytics'
import typedStore from '@/store/typedStore'
import { Coordinate2D } from '@/types'
import { EventEmitter } from 'events'
import interact from 'interactjs'
import Konva from 'konva'

export interface BubblesInteractionsEvents {
  transformView: () => void
}

export declare interface BubblesInteractions {
  on<U extends keyof BubblesInteractionsEvents>(
    event: U,
    listener: BubblesInteractionsEvents[U],
  ): this
}

const POINTER_MOVE_TOLERANCE = 10 // the distance the pointer/finger(s) must be moved before an action sequence occurs
const DEFAULT_COORDINATE: Coordinate2D = { x: 0, y: 0 }

export class BubblesInteractions extends EventEmitter {
  private _stage: Konva.Stage
  private _analytics: OrbVizAnalytics
  private _pinchStartDragOffset: Coordinate2D = DEFAULT_COORDINATE
  private _pinchStartCenter: Coordinate2D = DEFAULT_COORDINATE
  private _pinchStartScale: number = 1
  private _holding: boolean = false

  constructor(stage: Konva.Stage, analytics: OrbVizAnalytics) {
    super()
    this._stage = stage
    this._analytics = analytics

    interact.pointerMoveTolerance(POINTER_MOVE_TOLERANCE)

    interact(this._bubblesContainer, {
      styleCursor: false,
    })
      .on('tap', (event) => this._onTap(event))
      .on('hold', (event) => this._onHold(event))
      .on('touchend', () => this._onHoldEnd())
      .draggable({
        listeners: {
          move: (event) => this._onDragMove(event),
        },
      })
      .gesturable({
        listeners: {
          start: (event) => this._onPinchStart(event),
          move: (event) => this._onPinchMove(event),
        },
      })
  }

  reset() {
    this._bubblesDragOffset = DEFAULT_COORDINATE
    this._bubblesZoomFactor = 1
  }

  private get _bubblesContainer(): HTMLDivElement {
    return this._stage.container()
  }

  private _onTap(event: Interact.PointerEvent) {
    if (this.isTouch(event) && this._holding) {
      this._onHoldEnd()
    } else {
      const bubbleCircle = this.getBubbleCircleFromPointerEvent(event)
      if (bubbleCircle) {
        handleBubbleTap(bubbleCircle, this._analytics)
      }
    }
  }

  private _onHold(event: Interact.PointerEvent) {
    if (this.isTouch(event)) {
      this._holding = true
      const bubbleCircle = this.getBubbleCircleFromPointerEvent(event)
      if (bubbleCircle) {
        showPopup(bubbleCircle)
      }
    }
  }

  private _onHoldEnd() {
    this._holding = false
    hidePopup()
  }

  private _onDragMove(event: Interact.DragEvent) {
    if (!this.isTouchDevice) return

    this._bubblesDragOffset = {
      x: this._bubblesDragOffset.x + event.dx,
      y: this._bubblesDragOffset.y + event.dy,
    }
    this.emit('transformView')
  }

  private _onPinchStart(event: Interact.GestureEvent) {
    this._pinchStartDragOffset = this._bubblesDragOffset
    this._pinchStartCenter = rectCenter(event.box)
    this._pinchStartScale = this._bubblesZoomFactor
  }

  private _onPinchMove(event: Interact.GestureEvent) {
    this._bubblesDragOffset = this._calculatePinchDragOffset(event.scale)
    this._bubblesZoomFactor = this._pinchStartScale * event.scale

    this.emit('transformView')
  }

  private _calculatePinchDragOffset(pinchScale: number): Coordinate2D {
    const coordinate = this.isLandscape ? this._pinchStartCenter.y : this._pinchStartCenter.x
    const delta = coordinate * pinchScale - coordinate

    return {
      x: -delta + this._pinchStartDragOffset.x * pinchScale,
      y: -delta + this._pinchStartDragOffset.y * pinchScale,
    }
  }

  private get isMobile() {
    return typedStore.public.display.isMobile
  }

  private get isTouchDevice() {
    return typedStore.public.display.isTouchDevice
  }

  private get isLandscape() {
    return typedStore.public.display.isLandscape
  }

  private get _bubblesZoomFactor() {
    return typedStore.public.size.bubblesZoomFactor
  }

  private set _bubblesZoomFactor(scale: number) {
    typedStore.public.size.setBubblesZoomFactor(scale)
  }

  private get _bubblesDragOffset() {
    return { ...typedStore.public.size.bubblesDragOffset }
  }

  private set _bubblesDragOffset(offset: Coordinate2D) {
    typedStore.public.size.setBubblesDragOffset(offset)
  }

  private getBubbleCircleFromPointerEvent(event: Interact.PointerEvent): BubbleCircle | null {
    const shape = this._stage.getIntersection({
      x: event.offsetX,
      y: event.offsetY,
    })

    return isBubbleCircle(shape) ? shape : null
  }

  private isTouch(event: Interact.PointerEvent) {
    return event.pointerType === 'touch'
  }
}
