import store from '@/store'
// @ts-ignore vuex-map-fields import
import { getField, updateField } from 'vuex-map-fields'
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface INavigationState {
  leavePage: Boolean
}

@Module({ dynamic: true, store, name: 'navigation', namespaced: true })
export default class Navigation extends VuexModule implements INavigationState {
  leavePage = false

  @Mutation
  resetState() {
    this.leavePage = false
  }

  @Mutation
  setLeavePage(payload: boolean) {
    this.leavePage = payload
  }

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }
}

export const NavigationModule = getModule(Navigation)
