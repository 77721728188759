import store from '@/store'
import { getModule, Module, VuexModule } from 'vuex-module-decorators'

// types based on vue-route state
@Module({ dynamic: true, store, name: 'currentRoute', namespaced: true })
export default class CurrentRoute extends VuexModule {
  get name(): string | null | undefined {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.name
  }

  get isBuilder(): boolean {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.meta?.isBuilder ?? false
  }

  get path(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.path
  }

  get hash(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.hash
  }

  get query(): Record<string, string | (string | null)[]> {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.query
  }

  get params(): Record<string, string> {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.params
  }

  get fullPath(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.fullPath
  }

  get meta(): any {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.meta
  }

  get from(): any {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.from
  }

  get orgSlug(): string | null {
    return this.params.orgSlug ?? null
  }

  get primaryAppSlug(): string | null {
    return this.params.primaryAppSlug ?? null
  }

  get primaryAppId(): number {
    return Number(this.params.primaryAppId)
  }

  get primaryVersionId(): number {
    return Number(this.params.primaryVersionId)
  }

  get mainpanel() {
    return this.params.mainpanel
  }

  get sidepanel() {
    return this.params.sidepanel
  }

  get storyPath() {
    return this.params.storyPath as unknown as string[]
  }

  get secondaryStoryPath() {
    return this.params.secondaryStoryPath as unknown as string[] | undefined
  }

  get template(): string | null | undefined {
    return this.params.template
  }
}

export const CurrentRouteModule = getModule(CurrentRoute)
