import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { convertYoutubeUrl } from './helpers'
import IframeNodeView from './IframeNodeView.vue'

export interface IframeOptions {
  src: string
  HTMLAttributes?: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Set an iframe node
       */
      setIframe: (options: IframeOptions) => ReturnType
    }
  }
}

export default Node.create({
  name: 'orbvizIframe',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe', // we need to continue parsing existing iframe tags from tiptap content created in previous versions
      },
      {
        tag: 'orbviz-iframe',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    // Do not change this from 'orbviz-iframe' to simply 'iframe' otherwise the iframe gets
    // rendered twice, once from this injected tag and then again from the Node View that
    // is added
    return ['orbviz-iframe', HTMLAttributes]
  },

  addCommands() {
    return {
      setIframe:
        ({ src }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create({ src: convertYoutubeUrl(src) })
          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }
          return true
        },
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(IframeNodeView)
  },
})
