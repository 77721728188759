import customBubbles from '@/assets/imgs/bubblesIcon'
import customMap from '@/assets/imgs/mapIcon'
import customNestedBubbles from '@/assets/imgs/nestedBubblesIcon'
import customRecenterVisualisation from '@/assets/imgs/recenterVisualisationIcon'
import customSunburst from '@/assets/imgs/sunburstIcon'
import customAddColAfter from '@/assets/imgs/tiptap icons/addColAfterIcon'
import customAddColBefore from '@/assets/imgs/tiptap icons/addColBeforeIcon'
import customAddRowAfter from '@/assets/imgs/tiptap icons/addRowAfterIcon'
import customAddRowBefore from '@/assets/imgs/tiptap icons/addRowBeforeIcon'
import customCombineCells from '@/assets/imgs/tiptap icons/combineCellsIcon'
import customDeleteCol from '@/assets/imgs/tiptap icons/deleteColIcon'
import customDeleteRow from '@/assets/imgs/tiptap icons/DeleteRowIcon'
import customDeleteTable from '@/assets/imgs/tiptap icons/deleteTableIcon'
import customTable from '@/assets/imgs/tiptap icons/tableIcon'
import customVisualisations from '@/assets/imgs/visualisationsIcon'
import customXYBubbles from '@/assets/imgs/xyBubblesIcon'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import {} from '@fortawesome/free-brands-svg-icons'
import { faImage, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleDoubleDown,
  faArrowAltCircleUp,
  faArrowLeft,
  faBars,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUploadAlt,
  faCogs,
  faDivide,
  faDotCircle,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileDownload,
  faFilter,
  faGripVertical,
  faHome,
  faMagic,
  faMapMarked,
  faMinus,
  faPaperPlane,
  faPenNib,
  faPlus,
  faQuestion,
  faRedo,
  faSave,
  faSearch,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// https://www.npmjs.com/package/@fortawesome/vue-fontawesome
library.add(
  customAddColAfter as IconDefinition,
  customAddColBefore as IconDefinition,
  customAddRowAfter as IconDefinition,
  customAddRowBefore as IconDefinition,
  customBubbles as IconDefinition,
  customMap as IconDefinition,
  customNestedBubbles as IconDefinition,
  customSunburst as IconDefinition,
  customXYBubbles as IconDefinition,
  customVisualisations as IconDefinition,
  customCombineCells as IconDefinition,
  customDeleteCol as IconDefinition,
  customDeleteRow as IconDefinition,
  customDeleteTable as IconDefinition,
  customTable as IconDefinition,
  customRecenterVisualisation as IconDefinition,
  faAngleDoubleDown,
  faArrowAltCircleUp,
  faArrowLeft,
  faBars,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUploadAlt,
  faCogs,
  faDivide,
  faDotCircle,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileDownload,
  faFilter,
  faGripVertical,
  faHome,
  faImage,
  faMagic,
  faMapMarked,
  faMinus,
  faPaperPlane,
  faPenNib,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSave,
  faSearch,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserEdit,
)

export default FontAwesomeIcon
