import { ColorTranslator } from 'colortranslator'

const makeDarkerCache: Record<string, string> = {}

export function makeDarker(hex: string, lightnessReduction: number = 10): string {
  const cacheKey = `${hex}_${lightnessReduction}`

  if (!makeDarkerCache[cacheKey]) {
    const hsl = new ColorTranslator(hex).HSLAObject
    hsl.L = Math.max(0, hsl.L - lightnessReduction)
    const darkerColor = new ColorTranslator(hsl).RGBA

    makeDarkerCache[cacheKey] = darkerColor
  }

  return makeDarkerCache[cacheKey]
}
