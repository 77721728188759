import { authGet, authPostImg, awaitRequestJson } from '@/api/helpers'
import { AppRank, ImagesSource, PostImageResponse } from '@/types'

export function getImage(imageId: string | number) {
  return awaitRequestJson<string | 'Image not found'>(authGet(`image/${imageId}`))
}

export function getAllImages(appRank: AppRank) {
  return awaitRequestJson<ImagesSource>(authGet('images', {}, appRank))
}

export function postImage(file: File) {
  return awaitRequestJson<PostImageResponse>(authPostImg(`image/${file.name}`, file))
}
