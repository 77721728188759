import { authGet, authPost, awaitRequestJson } from '@/api/helpers'
import { AppRank, SuccessResponse } from '@/types'
import { GeoJSON } from 'geojson'

export function getGeometries(params: { fake?: boolean }, appRank: AppRank) {
  return awaitRequestJson<string>(authGet('geometries', params, appRank))
}

export function getGeometriesByIds(ids: string[], appRank: AppRank) {
  return awaitRequestJson<string>(authGet(`geometries/by-ids/${ids}`, {}, appRank))
}

// eslint-disable-next-line camelcase
export function getGeometryIdsByPath(
  path: string,
  params: { level_num: number; lat?: number; lon?: number },
  appRank: AppRank,
) {
  return awaitRequestJson<number[]>(authGet(`geometry-ids/by-path/${path}`, params, appRank))
}

export function postGeom(payload: GeoJSON) {
  return awaitRequestJson<SuccessResponse>(authPost({ path: 'geometries', body: payload }))
}
